import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormItem, ItemType } from '../../models/props.models'

import ModalForm, { ModalFormProps } from '../common/ModalForm.common'

interface ModalDenyProps
  extends Omit<
    ModalFormProps,
    'value' | 'items' | 'steps' | 'setValue' | 'title' | 'submitLabel'
  > {}
const ModalDeny: React.FC<ModalDenyProps> = (props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<{ deniedFor: string }>({ deniedFor: '' })

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'deniedFor',
        required: true,
        props: {
          label: t('requests:attributes.deniedFor'),
          placeholder: t('requests:attributes.deniedFor'),
          minRows: 3,
          multiline: true,
        },
      },
    ],
    [t],
  )
  return (
    <ModalForm
      {...props}
      value={value}
      setValue={setValue}
      items={items}
      title={t('requests:actions.denied.label')}
      submitLabel={t('global:actions.confirm')}
    />
  )
}

export default ModalDeny
