import { cartsStore } from './carts.store'
import { selectIsRequestPending } from '@ngneat/elf-requests'
import { select } from '@ngneat/elf'

export class CartsQuery {
  store = cartsStore
  cart = this.store.pipe(select((state) => state.cart))
  loading = this.store.pipe(selectIsRequestPending('init'))
}

export const cartsQuery = new CartsQuery()
