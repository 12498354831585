import React, { useCallback, useMemo } from 'react'
import { alpha, Box, DialogActions, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Modal from '../layout/Modal.layout'
import Stack from '../common/Stack.common'
import ImageWithBackground from '../common/ImageWithBackground.common'
import Documents from '../common/input/Documents.input'

import Constants from '../../constants'
import {
  convert2Imperials,
  Material,
  MaterialType,
  TermsOfSale,
  Unit,
} from '../../models/materials.models'
import { Route } from '../../models/commons.models'
import { FileUtils } from '../../utils/files.utils'
import LinkButton from '../common/button/Link.button'
import useRoute from '../../hooks/useRoute.hooks'
import { StringUtils } from '../../utils/commons.utils'
import AddToCart from './AddToCart.material'

const MaterialPicture = styled(Box)({
  height: '200px',
  minHeight: '200px',
  position: 'relative',
  width: '100%',
})

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const PriceLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: 'white',
  borderRadius: '4px',
  backgroundColor: 'black',
  padding: '7px 9px',
})
const CatalogLabel = styled(OneLineTypography)({
  fontSize: '0.85rem',
  fontWeight: 300,
  marginTop: '8px',
})
const RefLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 300,
  marginTop: '4px',
})
const CategoriesLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: 1.25,
  marginTop: '2px',
}))
const StatusLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 400,
  marginTop: '9px',
})

const Block = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 500,
  width: '100%',
  padding: '12px 11px',
  marginTop: '14px',
})
const AvailableBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.availableQuantity,
  backgroundColor: alpha(theme.palette.availableQuantity, 0.1),
}))
const DesiredBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.desiredQuantity,
  backgroundColor: alpha(theme.palette.desiredQuantity, 0.1),
}))
const GreyBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.blockLabel,
  backgroundColor: alpha(theme.palette.blockLabel, 0.1),
}))

const RowsTitle = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 500,
  marginTop: '30px',
})
const RowDetail = styled(Stack)({
  width: '100%',
  padding: '14px 0',
  '&:not(:first-of-type)': {
    borderBottom: 'solid 1px #DFDFDF',
  },
})
const RowTextLeft = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))
const RowTextRight = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'right',
})
const RowTextSeveralLines = styled(Typography)({ fontSize: '0.875rem', fontWeight: 400 })
const Observations = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))

interface MaterialDetailsModalProps {
  material: Material
  isPublic?: boolean
  isCatalogPage?: boolean
  onClose: () => void
  addToCart?: (quantity: number) => void
  useImperials: boolean
}

const MaterialDetailsModal: React.FC<MaterialDetailsModalProps> = ({
  material,
  isPublic,
  isCatalogPage,
  onClose,
  useImperials,
  addToCart,
}): JSX.Element => {
  const { t } = useTranslation()
  const { matchPublic, goTo } = useRoute()
  const convertedMaterial = useMemo<Material>(
    () => (useImperials ? convert2Imperials(material) : material),
    [useImperials, material],
  )
  const addToCartConverted = useCallback(
    (quantity: number) => {
      const factor =
        material.unit === Unit.linearMeters
          ? 3.28084
          : material.unit === Unit.squareMeters
          ? 3.28084 * 3.28084
          : material.unit === Unit.cubicMeters
          ? 3.28084 * 3.28084 * 3.28084
          : 1
      addToCart?.(quantity / (useImperials ? factor : 1))
    },
    [material.unit, addToCart, useImperials],
  )

  if (!convertedMaterial) return <></>

  const filteredMaterial = convertedMaterial.technicalDetails?.filter((tech: any) => !!tech.value)

  return (
    <Modal whiteClose noContentPadding maxWidth="sm" onClose={onClose} hideAction>
      <MaterialPicture
        onClick={() =>
          convertedMaterial.mainImageFile && FileUtils.openFile(convertedMaterial.mainImageFile)
        }>
        <ImageWithBackground
          placeholder={Constants.resources.materialIcon}
          image={convertedMaterial.mainImageFile?.src || convertedMaterial.mainImageFile?.path}
        />
      </MaterialPicture>
      <Stack px="10px" mb="30px" overflow="hidden" width="100%">
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          px="20px"
          py="30px"
          spacing={6}
          sx={{
            maxHeight: `calc(90vh - 350px)`,
            overflow: 'hidden auto',
          }}
          className="scrollable">
          <Box display="flex" flexDirection="column" alignItems="stretch" width="100%">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <OneLineTypography variant="h2">{convertedMaterial.name}</OneLineTypography>
                <RefLabel>
                  {t('materials:details.reference', { reference: convertedMaterial.reference })}
                </RefLabel>

                <CategoriesLabel>
                  {t(`categories:name.${convertedMaterial.primaryCategory.name}` as any)} &#8594;{' '}
                  {t(`categories:name.${convertedMaterial.secondaryCategory.name}` as any)} &#8594;
                  {t(`categories:name.${convertedMaterial.tertiaryCategory.name}` as any)}
                </CategoriesLabel>
                {!isCatalogPage && (
                  <Box display="flex">
                    <CatalogLabel>{t('materials:attributes.catalog')}</CatalogLabel>
                    <LinkButton
                      onClick={() =>
                        goTo({
                          route: matchPublic ? Route.publicCatalog : Route.workspaceCatalog,
                          catalogId: convertedMaterial.catalog._id,
                        })
                      }>
                      {convertedMaterial.catalog.name}
                    </LinkButton>
                  </Box>
                )}
                {convertedMaterial.quality && (
                  <StatusLabel color="secondaryText">
                    {t(`materials:quality.${convertedMaterial.quality}`)}
                  </StatusLabel>
                )}
              </Box>
              {convertedMaterial.type === MaterialType.resource && (
                <PriceLabel>
                  {convertedMaterial.termsOfSale === TermsOfSale.sale &&
                    t('materials:details.price', {
                      price: convertedMaterial.price,
                      currency: t(`global:currency.${convertedMaterial.currency}`),
                      unit: t(`materials:unitSymbol.${convertedMaterial.unit}`),
                    })}
                  {convertedMaterial.termsOfSale === TermsOfSale.notDefined &&
                    t('materials:details.priceNotDefined')}
                  {convertedMaterial.termsOfSale === TermsOfSale.donation &&
                    t('materials:details.donation')}
                </PriceLabel>
              )}
            </Box>
            {convertedMaterial.type === MaterialType.resource && (
              <>
                <AvailableBlock>
                  {t('materials:details.quantityAvailable', {
                    count: convertedMaterial.currentQty || 0,
                    unit: t(`materials:unitSymbol.${convertedMaterial.unit}`),
                  })}
                </AvailableBlock>
                {!!convertedMaterial.unitWeight && (
                  <GreyBlock>
                    {t(
                      `materials:details.weight${convertedMaterial.unitWeight > 999 ? 'T' : 'Kg'}`,
                      {
                        weight:
                          convertedMaterial.unitWeight > 999
                            ? convertedMaterial.unitWeight / 1000
                            : convertedMaterial.unitWeight,
                        unit: t(`materials:unitSymbol.${convertedMaterial.unit}`),
                      },
                    )}
                  </GreyBlock>
                )}
              </>
            )}
            {convertedMaterial.type === MaterialType.need && (
              <>
                <DesiredBlock>
                  {t('materials:details.desiredQuantity', {
                    quantity: convertedMaterial.initialQty || 0,
                    unit: t(`materials:unitSymbol.${convertedMaterial.unit}`),
                  })}
                </DesiredBlock>
                {convertedMaterial?.retrieval?.endDate && (
                  <GreyBlock>
                    {`${t('materials:details.needsEndDate', {
                      date: new Date(convertedMaterial?.retrieval?.endDate || 'invalid'),
                    })}`}
                  </GreyBlock>
                )}
                {convertedMaterial.uniqueDeposit && (
                  <GreyBlock>{t('materials:details.uniqueDeposit')}</GreyBlock>
                )}
              </>
            )}
            {convertedMaterial.description && (
              <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                <RowTextLeft>{t('materials:attributes.observations')}</RowTextLeft>
                <Observations>{convertedMaterial.description}</Observations>
              </RowDetail>
            )}
            {convertedMaterial.privateDescription && !isPublic && (
              <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                <RowTextLeft>{t('materials:attributes.privateDescription')}</RowTextLeft>
                <Observations>{convertedMaterial.privateDescription}</Observations>
              </RowDetail>
            )}
            {convertedMaterial.type === MaterialType.resource && convertedMaterial.dimensions && (
              <>
                <RowsTitle>{t('materials:attributes.dimensions.title')}</RowsTitle>
                {!!convertedMaterial.dimensions?.width && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.width')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(convertedMaterial.dimensions.width)}
                      {convertedMaterial.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${convertedMaterial.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!convertedMaterial.dimensions?.height && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.height')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(convertedMaterial.dimensions.height)}
                      {convertedMaterial.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${convertedMaterial.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!convertedMaterial.dimensions?.length && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.length')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(convertedMaterial.dimensions.length)}
                      {convertedMaterial.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${convertedMaterial.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!convertedMaterial.dimensions?.diameter && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.diameter')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(convertedMaterial.dimensions.diameter)}
                      {convertedMaterial.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${convertedMaterial.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
              </>
            )}
            {convertedMaterial.type === MaterialType.resource && !!filteredMaterial?.length && (
              <>
                <RowsTitle>{t('materials:attributes.technicalDetails.title')}</RowsTitle>
                {filteredMaterial?.map((td, i) => (
                  <RowDetail
                    key={i}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{td.name}</RowTextLeft>
                    <RowTextSeveralLines>{td.value}</RowTextSeveralLines>
                  </RowDetail>
                ))}
              </>
            )}
            {convertedMaterial.type === MaterialType.resource && (
              <>
                {(convertedMaterial.productionCarbon ||
                  convertedMaterial.endOfLifeCarbon ||
                  convertedMaterial.tracksOfReuse) && (
                  <>
                    <RowsTitle>{t('materials:attributes.carbon.title')}</RowsTitle>
                    {convertedMaterial.productionCarbon && (
                      <RowDetail
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}>
                        <RowTextLeft>
                          {t('materials:attributes.carbon.productionCarbon')}
                        </RowTextLeft>
                        <RowTextRight>
                          {StringUtils.formatNumber(convertedMaterial.productionCarbon)} kgCO2eq/UR
                        </RowTextRight>
                      </RowDetail>
                    )}
                    {convertedMaterial.endOfLifeCarbon && (
                      <RowDetail
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}>
                        <RowTextLeft>
                          {t('materials:attributes.carbon.endOfLifeCarbon')}
                        </RowTextLeft>
                        <RowTextRight>
                          {StringUtils.formatNumber(convertedMaterial.endOfLifeCarbon)} kgCO2eq/UR
                        </RowTextRight>
                      </RowDetail>
                    )}
                    {convertedMaterial.productionCarbon && convertedMaterial.endOfLifeCarbon && (
                      <>
                        <RowDetail
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1.5}>
                          <RowTextLeft>
                            {t('materials:attributes.carbon.unitLoopCarbon')}
                          </RowTextLeft>
                          <RowTextRight>
                            {StringUtils.formatNumber(
                              convertedMaterial.productionCarbon +
                                convertedMaterial.endOfLifeCarbon,
                            )}{' '}
                            kgCO2eq/UR
                          </RowTextRight>
                        </RowDetail>
                        <RowDetail
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1.5}>
                          <RowTextLeft>{t('materials:attributes.carbon.loopCarbon')}</RowTextLeft>
                          <RowTextRight>
                            {StringUtils.formatNumber(
                              (convertedMaterial.productionCarbon &&
                                convertedMaterial.endOfLifeCarbon) * convertedMaterial.initialQty,
                            )}{' '}
                            kgCO2eq
                          </RowTextRight>
                        </RowDetail>
                      </>
                    )}
                    {convertedMaterial.tracksOfReuse && (
                      <RowDetail
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}>
                        <RowTextLeft>{t('materials:attributes.carbon.tracksOfReuse')}</RowTextLeft>
                        <RowTextRight>{convertedMaterial.tracksOfReuse}</RowTextRight>
                      </RowDetail>
                    )}
                  </>
                )}
              </>
            )}
            {convertedMaterial.type === MaterialType.resource &&
              (convertedMaterial?.retrieval?.retrievalModality ||
                convertedMaterial.state ||
                convertedMaterial.conditioning ||
                convertedMaterial?.retrieval?.startDate ||
                convertedMaterial?.retrieval?.endDate) && (
                <>
                  <RowsTitle>{t('materials:attributes.retrieval.title')}</RowsTitle>
                  {convertedMaterial?.retrieval?.retrievalModality && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.retrieval.modality')}</RowTextLeft>
                      <RowTextRight>
                        {t(
                          `global:retrievalModality.${convertedMaterial.retrieval.retrievalModality}`,
                        )}
                      </RowTextRight>
                    </RowDetail>
                  )}
                  {convertedMaterial.state && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.state')}</RowTextLeft>
                      <RowTextRight>{t(`materials:state.${convertedMaterial.state}`)}</RowTextRight>
                    </RowDetail>
                  )}
                  {convertedMaterial.conditioning && (
                    <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.conditioning')}</RowTextLeft>
                      <RowTextSeveralLines>{convertedMaterial.conditioning}</RowTextSeveralLines>
                    </RowDetail>
                  )}
                  {(convertedMaterial?.retrieval.startDate ||
                    convertedMaterial?.retrieval.endDate) && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.retrieval.startDate')}</RowTextLeft>
                      <RowTextRight>
                        {t('global:format.date', {
                          date: new Date(convertedMaterial?.retrieval.startDate || 'invalid date'),
                        })}{' '}
                        -{' '}
                        {t('global:format.date', {
                          date: new Date(convertedMaterial?.retrieval.endDate || 'invalid date'),
                        })}
                      </RowTextRight>
                    </RowDetail>
                  )}
                </>
              )}
            {!!convertedMaterial.files?.length && !isPublic && (
              <Box mt="20px">
                <Documents
                  label={t('materials:attributes.files')}
                  readOnly={true}
                  documents={convertedMaterial.files}
                />
              </Box>
            )}
            {!!convertedMaterial.imageFiles?.length && (
              <Box mt="20px">
                <Documents
                  label={t('materials:attributes.imageFiles')}
                  readOnly={true}
                  documents={convertedMaterial.imageFiles}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
      {!isPublic ? (
        <></>
      ) : (
        <DialogActions sx={{ marginBottom: '30px', padding: '0 10px' }}>
          <AddToCart
            addToCart={addToCartConverted}
            minQuantity={convertedMaterial.minQuantity}
            sellByQuantityOf={convertedMaterial.sellByQuantityOf}
            currentQty={convertedMaterial.currentQty}
            unit={convertedMaterial.unit}
          />
        </DialogActions>
      )}
    </Modal>
  )
}

export default MaterialDetailsModal
