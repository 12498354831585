import { createStore, withProps } from '@ngneat/elf'
import { ReccursiveCategory } from '../../models/categories.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const categoriesStore = createStore(
  { name: 'categories' },
  withProps<{ list: ReccursiveCategory[] }>({
    list: [],
  }),
  withRequestsStatus<'init'>(),
)
