import { createRef } from 'react'
import Constants from '../../../constants'
import { SearchProps } from '../../../models/props.models'
import { TextField } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'

const SearchComponent: React.FC<SearchProps> = (props): JSX.Element => {
  const { defaultValue, onChange, onKeyPress, placeholder, value } = props

  const inputRef = createRef<HTMLInputElement>()

  return (
    <TextField
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={value}
      variant="outlined"
      aria-label="search"
      fullWidth
      inputRef={inputRef}
      onKeyPress={onKeyPress}
      InputProps={{
        startAdornment: (
          <SearchIcon style={{ marginRight: '5px', color: Constants.colors.secondaryText }} />
        ),
      }}
      onChange={(evt) => onChange?.(evt.target.value)}
    />
  )
}

export default SearchComponent
