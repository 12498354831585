import { alpha } from '@mui/material'
export const createInputs = (colors: any) => ({
  // @ts-ignore
  MuiAutocomplete: {
    root: {
      '& .MuiOutlinedInput-root': {
        padding: '0 9px !important',
      },
    },
    input: {
      marginRight: '30px',
    },
    paper: {
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: `0 0 10px 0 ${alpha(colors.black, 0.1)}`,
      padding: 0,
      margin: 0,
    },
    loading: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.75rem',
      fontWeight: 500,
      height: 45,
      padding: '0 15px',
    },
    noOptions: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.75rem',
      fontWeight: 500,
      height: 45,
      padding: '0 15px',
    },
    listbox: {
      maxHeight: '40vh',
      maxWidth: '100%',
      padding: 0,
    },
    option: {
      alignItems: 'center',
      borderBottom: `1px solid ${colors.menuBorder}`,
      display: 'flex',
      flex: 1,
      fontSize: '0.75rem',
      fontWeight: 500,
      height: 'fit-content',
      padding: '8px 15px !important',
      '&:last-of-type': {
        borderColor: 'transparent',
      },
      '&.Mui-selected': {
        fontWeight: 600,
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: colors.black,
      fontSize: '0.75rem',
      fontWeight: 500,
      marginBottom: '7px',
      whiteSpace: 'normal',
    },
  },
  MuiSelect: {
    select: {
      alignItems: 'center',
      // backgroundColor: alpha(colors.primary, 0.1),
      borderRadius: '4px 0 0 4px',
      color: colors.primary,
      display: 'flex',
      fontSize: '0.75rem',
      fontWeight: 500,
      height: '45px !important',
      marginRight: '30px',
      padding: '0 18px',
      '&:focus': {
        backgroundColor: 'none',
        borderRadius: 4,
      },
      '&[aria-label=select-large]': {
        height: '50px !important',
      },
    },
    icon: {
      // backgroundColor: alpha(colors.primary, 0.1),
      borderRadius: '0 4px 4px 0',
      bottom: 0,
      // color: colors.primary,
      height: '100%',
      top: 0,
      padding: '0 5px',
      width: '30px',
    },
  },
  MuiOutlinedInput: {
    root: {
      height: 45,
    },
    multiline: {
      height: 'fit-content',
      minHeight: 45,
      paddingBottom: '10px',
      paddingTop: '10px',
    },

    input: {
      fontSize: '0.875rem',
      fontWeight: 500,
      '&.Mui-disabled': {
        color: colors.secondaryText,
      },
      '&::placeholder': {
        fontWeight: 400,
        opacity: 0.4,
      },
    },
  },
  MuiTextField: {
    root: {
      backgroundColor: colors.textFieldBackground,
      borderRadius: 4,
      '& fieldset': { border: 'none' },
      '&[aria-label=search]': {
        backgroundColor: colors.searchBackground,
        '& .MuiOutlinedInput-root': {
          height: 50,
        },
        '& .MuiOutlinedInput-adornedStart': {
          paddingLeft: '18px',
        },
        '& .MuiOutlinedInput-input': {
          fontSize: '0.75rem',
        },
      },
      '&[aria-label=search-map]': {
        backgroundColor: colors.white,
        maxWidth: '350px',
        '& .MuiOutlinedInput-root': {
          height: 40,
        },
        '& .MuiOutlinedInput-adornedStart': {
          marginLeft: '5px',
        },
        '& .MuiOutlinedInput-inputAdornedStart': {
          marginLeft: '5px',
        },
        '& .MuiOutlinedInput-input': {
          fontSize: '0.75rem',
        },
      },
      '&[aria-label=select-file]': {
        '& .MuiOutlinedInput-input': {
          cursor: 'pointer',
          '&::placeholder': {
            color: colors.primary,
            fontWeight: 500,
            opacity: 1,
          },
        },
        '& .MuiOutlinedInput-inputAdornedStart': {
          marginLeft: '8px',
        },
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: colors.white,
      height: '15px',
      width: '15px',
    },
    colorPrimary: {
      color: colors.primary,
      '&.Mui-disabled': {
        color: colors.primary,
      },
    },
  },
  MuiSwitch: {
    root: {
      display: 'flex',
      height: 20,
      padding: 0,
      width: 38,
    },
    switchBase: {
      color: colors.white + ' !important',
      padding: 4,
      '&.Mui-checked': {
        color: colors.white,
        transform: 'translateX(18px)',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.success + ' !important',
          borderColor: colors.success,
          opacity: 1,
        },
      },
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          backgroundColor: alpha(colors.error, 0.5) + ' !important',
          borderColor: 'transparent',
          opacity: 1,
        },
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: alpha(colors.success, 0.5) + ' !important',
            borderColor: 'transparent',
            opacity: 1,
          },
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${colors.error}`,
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: colors.error,
    },
    checked: {},
  },
  MuiSlider: {
    root: {
      color: '#EEEEEE',
      height: '6px',
    },
    thumb: {
      backgroundColor: colors.primary,
      border: 'none',
      height: 16,
      width: 16,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    track: {
      backgroundColor: colors.primary,
      borderRadius: 3,
      height: 6,
    },
    rail: {
      borderRadius: 3,
      height: 6,
      opacity: 1,
    },
  },
})
