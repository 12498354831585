import { Point } from '../../../models/commons.models'
import Edge from './Edge.plan'
import PointComponent from './Point.plan'

type SegmentProps = {
  line: [Point, Point]
  id?: string
  cornerSize: number
  wallSize: number
}
const Segment: React.FC<SegmentProps> = ({ line, id, cornerSize, wallSize }): JSX.Element => {
  return (
    <>
      <Edge id={id ? `${id}.edge` : ''} line={line} size={wallSize} />
      <PointComponent id={id ? `${id}.start` : ''} position={line[0]} size={cornerSize} />
      <PointComponent id={id ? `${id}.end` : ''} position={line[1]} size={cornerSize} />
    </>
  )
}
export default Segment
