import { useOutletContext } from 'react-router-dom'
import { Catalog } from '../../../models/catalogs.models'
import PageReport from '../../../components/page/Report.page'
import { Mode } from '../../../models/commons.models'
import Constants from '../../../constants'

const CatalogReportTab = () => {
  const { catalog } = useOutletContext<{
    catalog: Catalog
  }>()

  return <PageReport catalog={catalog._id} owned={Constants.mode !== Mode.admin} />
}
export default CatalogReportTab
