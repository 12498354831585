import { createStore, withProps } from '@ngneat/elf'
import { withRequestsStatus } from '@ngneat/elf-requests'
import { Report } from '../../models/reports.model'

export const reportsStore = createStore(
  { name: 'reports' },
  withProps<{ list: Report[] }>({
    list: [],
  }),
  withRequestsStatus<'init'>(),
)
