import { useMemo, useCallback, forwardRef } from 'react'
import List, { ListProps, ListRef } from '../common/List.common'
import { useTranslation } from 'react-i18next'
import {
  Catalog,
  InterventionType,
  getCatalogStatusColor,
  CatalogType,
  isNeeds,
} from '../../models/catalogs.models'
import { ItemType, ColumnItem } from '../../models/props.models'
import { Location, Route } from '../../models/commons.models'
import { Organization } from '../../models/organizations.models'

import CatalogCard from './Card.catalog'
import { sessionService } from '../../store/session'
import useRoute from '../../hooks/useRoute.hooks'

export interface CatalogListProps extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {
  types: CatalogType[]
  isAdmin?: boolean
  isPublic?: boolean
  organization?: string
}

const CatalogList = forwardRef<ListRef, CatalogListProps>((props, ref) => {
  const { organization, isAdmin, types, isPublic, ...listProps } = props
  const { t } = useTranslation()
  const { goTo } = useRoute()

  const showStatus = useMemo(() => {
    return types.reduce((acc: boolean, type: CatalogType) => {
      return acc || sessionService.showStatus({ type, isPublic: !!isPublic })
    }, false)
  }, [types, isPublic])
  const showConstructionSiteColumns = useMemo<boolean>(
    () =>
      !!types.find(
        (type: CatalogType) =>
          type === CatalogType.construction || type === CatalogType.deconstruction,
      ),
    [types],
  )

  const columns = useMemo<ColumnItem[]>(
    () => [
      ...(showStatus
        ? [
            (catalog: Catalog | undefined) => ({
              label: t('catalogs:attributes.status'),
              key: 'status',
              type: ItemType.chip,
              props: {
                color: catalog ? getCatalogStatusColor(catalog.status) : '',
                label: catalog ? t(`catalogs:status.${catalog.status}`) : '',
              },
            }),
          ]
        : []),
      {
        label: t('catalogs:attributes.name'),
        key: 'name',
      },
      ...(organization
        ? []
        : [
            (catalog: Catalog | undefined) => ({
              label: t('catalogs:attributes.organization'),
              key: 'organization.name',
              type: isAdmin ? ItemType.link : ItemType.typo,
              props: {
                onClick: (e: any) => {
                  if (catalog) {
                    goTo({
                      route: Route.organization,
                      organizationId: catalog.organization._id,
                    })
                  }
                  e.stopPropagation()
                },
                children: catalog?.organization.name,
              },
            }),
          ]),
      {
        label: t('catalogs:attributes.location'),
        key: 'location',
        formatValue: (location: Location) => `${location?.city}, ${location?.postalCode}`,
      },
      (catalog: Catalog | undefined) => ({
        label: t('catalogs:components.list.dates'),
        key: 'date',
        formatValue: (_: any) =>
          catalog?.retrieval?.startDate || catalog?.retrieval?.endDate
            ? `${
                catalog.retrieval?.startDate
                  ? t('global:format.date', {
                      date: new Date(catalog.retrieval?.startDate),
                    })
                  : '-'
              } - ${
                catalog.retrieval?.endDate
                  ? t('global:format.date', {
                      date: new Date(catalog.retrieval?.endDate),
                    })
                  : '-'
              }`
            : '-',
      }),
      ...(showConstructionSiteColumns
        ? [
            (catalog: Catalog | undefined) => ({
              label: t('catalogs:attributes.projectOwner'),
              key: 'projectOwner',
              formatValue: (projectOwner: Organization) =>
                projectOwner?.name || catalog?.customProjectOwner || '',
            }),
            {
              label: t('catalogs:attributes.interventionTypes'),
              key: 'interventionTypes',
              formatValue: (interventionTypes: InterventionType[]) =>
                interventionTypes.length > 0
                  ? interventionTypes
                      .map((interventionType) => t(`catalogs:interventionType.${interventionType}`))
                      .join(', ')
                  : '',
            },
          ]
        : []),
    ],
    [organization, t, showStatus, showConstructionSiteColumns, goTo, isAdmin],
  )
  const card = useCallback(
    (props: any) => <CatalogCard {...props} isAdmin={isAdmin} isPublic={isPublic} />,
    [isAdmin, isPublic],
  )

  const getCoordinates = useCallback((catalog: Catalog) => {
    return catalog.retrieval.location.position
  }, [])

  return (
    <List
      {...listProps}
      ref={ref}
      getCoordinates={isPublic ? getCoordinates : undefined}
      isSecondary={(catalog: Catalog) => isNeeds(catalog.type)}
      canChooseCountry
      columns={isAdmin ? columns : undefined}
      card={card}
    />
  )
})
export default CatalogList
