import useCheckRoute from '../hooks/useCheckRoute.hooks'
import { Mode } from '../models/commons.models'
import CGUContent from '../components/session/cgu.session'
import { Box } from '@mui/material'

const Cgu = () => {
  useCheckRoute('Cgu', [Mode.front, Mode.storeFront])

  return (
    <Box paddingX="20px">
      <CGUContent />
    </Box>
  )
}

export default Cgu
