import { NeedsCatalogTypes } from '../../../models/catalogs.models'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import PageCatalogList from '../../../components/page/CatalogList.page'

const ListPublicCatalogNeedsPage = () => {
  useCheckRoute('PublicCatalogList', [Mode.front, Mode.storeFront])

  return <PageCatalogList isPublic types={NeedsCatalogTypes} />
}
export default ListPublicCatalogNeedsPage
