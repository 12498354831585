import Card from '../common/Card.common'
import { Box, styled, Typography, alpha } from '@mui/material'
import { Business as BusinessIcon } from '@mui/icons-material'
import { Organization } from '../../models/organizations.models'
import { Actions } from '../../models/props.models'

const AvatarBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  borderRadius: '50%',
  display: 'flex',
  height: '70px',
  justifyContent: 'center',
  marginTop: '10px',
  width: '70px',
}))

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const NameLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 600,
})
const OtherInfoLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 400,
})
const CityLabel = styled(OneLineTypography)({ fontSize: '0.563rem', fontWeight: 400 })

interface OrganizationCardProps {
  value: Organization
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
}

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  value,
  onClick,
  actions,
  onSelect,
  selected,
}): JSX.Element => {
  return (
    <Card
      ariaLabel="organization-card"
      onClick={onClick}
      actions={actions}
      onSelect={onSelect}
      selected={selected}
      imagePlaceholder={
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <AvatarBox>
            <BusinessIcon />
          </AvatarBox>
        </Box>
      }>
      <>
        <Box display="flex" alignItems="center" justifyContent="center" mt="10px" width="100%">
          <NameLabel>{value.name}</NameLabel>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt="5px" width="100%">
          <OtherInfoLabel color="secondary">{value.siret}</OtherInfoLabel>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt="15px" maxWidth="100%">
          <CityLabel>{value?.location?.city}</CityLabel>
        </Box>
      </>
    </Card>
  )
}

export default OrganizationCard
