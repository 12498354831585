import { alpha, styled, TextField } from '@mui/material'

const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'required',
})<{ disabled?: boolean; required?: boolean }>(({ theme, disabled, required }) => {
  const baseStyle: any = {
    '& .MuiAutocomplete-endAdornment': {
      display: disabled ? 'none' : 'block',
    },
  }
  if (required) {
    baseStyle.backgroundColor = alpha(theme.palette.primary.main, 0.1)
    baseStyle['& .MuiSvgIcon-root'] = {
      fill: theme.palette.primary.main,
    }
  }
  return baseStyle
}) as typeof TextField

export default StyledInput
