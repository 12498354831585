import { useCallback, useState, useEffect, useReducer, useMemo, useRef } from 'react'
import { Card, styled } from '@mui/material'
import useGesture from './useGesture.hooks'
import { Point } from '../models/commons.models'
import { FileDetails } from '../models/files.models'
import { PlanUtils } from '../utils/plan.utils'
import { FileUtils } from '../utils/files.utils'
import Constants from '../constants'

interface View {
  toInit: boolean
  elementSize: number
  elementOrigin: Point
  viewAngle: number
  viewScale: number
  viewCenter: Point
  viewSize: number
  viewbox: string
}
type ViewAction =
  | { type: 'rotateView'; angle: number }
  | { type: 'setView'; center?: Point; scale?: number; angle?: number }
  | { type: 'pinchView'; scale: number; htmlPosition: Point; position: Point }
  | { type: 'dragView'; delta: Point }
  | { type: 'resize' }
  | { type: 'toInit' }
export type ViewProps = {
  image?: File | FileDetails
  viewbox: string
  viewAngle: number
  children?: React.ReactNode | React.ReactNode[]
  toolbar?: React.ReactNode | React.ReactNode[]
}
const SvgContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})
const ToolbarContainer = styled(Card)(({ theme }) => ({
  position: 'absolute',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
  },
  top: 0,
  left: 0,
  width: 'calc(100% - 40px)',
  margin: '0 20px',
  padding: '16px',
  zIndex: 2,
}))
const StyledSvg = styled('svg')(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.menuBorder}`,
  height: '100%',
  width: '100%',
  borderRadius: '4px',
  maxHeight: 'inherit',
  touchAction: 'none',
}))
const ImageLoader: React.FC<{ image: File | FileDetails }> = ({ image }) => {
  const [src, setSrc] = useState('')
  useEffect(() => {
    FileUtils.loadImage(image, true)
      .then((imageData: string) => {
        setSrc(imageData)
      })
      .catch(() => {})
  }, [image])

  return (
    <>
      {src && (
        <image
          xlinkHref={src}
          x={-Constants.ui.maxViewBox / 2}
          y={-Constants.ui.maxViewBox / 2}
          height={Constants.ui.maxViewBox}
          width={Constants.ui.maxViewBox}
        />
      )}
    </>
  )
}

type UseViewParams = {
  capture?: boolean
  doubleClickLatency?: number
  pinchBound?: { min: number; max: number }
  onSingleClick?: (position: Point, targetId?: string) => void
  onDoubleClick?: (position: Point, targetId?: string) => void
  onDrag?: (delta: Point, position: Point, targetId?: string) => boolean
  onPinch?: (scale: number, position: Point) => boolean
  onDragEnd?: () => void
  onKey?: (key: string, ctrl: boolean) => void
  defaultAngle?: number
}
type UseView = {
  View: React.FC<ViewProps>
  cornerSize: number
  wallSize: number
  locatedMaterialSize: number
  viewbox: string
  viewCenter: Point
  viewAngle: number
  initView: () => void
  setView: (viewParam: { center?: Point; scale?: number; angle?: number }) => void
  htmlPointToSvg: (position: Point) => Point
  svgPointToHtml: (position: Point) => Point
  htmlDistanceToSvg: (distance: number) => number
}

const useView = ({
  doubleClickLatency = Constants.ui.doubleClickLatency,
  pinchBound = Constants.ui.pinchBound,
  capture = false,
  onSingleClick,
  onDoubleClick,
  onDrag,
  onPinch,
  onDragEnd,
  onKey,
  defaultAngle = 0,
}: UseViewParams): UseView => {
  const ref = useRef<SVGSVGElement>(null)

  const [view, dispatchView] = useReducer(
    (view: View, action: ViewAction): View => {
      const newView = {
        ...view,
      }
      if (
        view.elementSize > 0 ||
        action.type === 'resize' ||
        action.type === 'setView' ||
        action.type === 'toInit'
      ) {
        switch (action.type) {
          case 'setView':
            newView.viewAngle = action.angle || action.angle === 0 ? action.angle : view.viewAngle
            newView.viewScale = action.scale || view.viewScale
            newView.viewCenter = action.center || view.viewCenter
            newView.viewSize = action.scale ? Constants.ui.maxViewBox / action.scale : view.viewSize
            break
          case 'pinchView':
            // compute translation so the point center of the transformtation does not move in the viewport
            let viewSize = Constants.ui.maxViewBox / action.scale
            const after = PlanUtils.rotatePoint(
              [
                ((action.htmlPosition[0] - view.elementOrigin[0]) / view.elementSize - 0.5) *
                  viewSize +
                  view.viewCenter[0],
                ((action.htmlPosition[1] - view.elementOrigin[1]) / view.elementSize - 0.5) *
                  viewSize +
                  view.viewCenter[1],
              ],
              -view.viewAngle,
            )
            const delta = PlanUtils.rotatePoint(
              [action.position[0] - after[0], action.position[1] - after[1]],
              view.viewAngle,
            )
            newView.viewScale = action.scale
            newView.viewCenter = [
              view.viewCenter[0] + delta[0],
              view.viewCenter[1] + delta[1],
            ] as Point
            newView.viewSize = viewSize
            break
          case 'dragView':
            newView.viewCenter = [
              view.viewCenter[0] - action.delta[0],
              view.viewCenter[1] - action.delta[1],
            ] as Point
            break
          case 'toInit':
            newView.toInit = !newView.toInit
            return newView
          case 'resize':
            if (ref.current) {
              const rect = ref.current.getBoundingClientRect()
              if (rect) {
                const elementSize = Math.min(rect.bottom - rect.top, rect.right - rect.left)
                if (elementSize > 0) {
                  newView.elementSize = elementSize
                  newView.elementOrigin = [
                    (ref.current.clientWidth - elementSize) / 2 + rect.left,
                    (ref.current.clientHeight - elementSize) / 2 + rect.top,
                  ] as Point
                }
              }
            }
            break
          default:
            break
        }
      }

      return {
        ...newView,
        viewbox: `${-newView.viewSize / 2 + newView.viewCenter[0]} ${
          -newView.viewSize / 2 + newView.viewCenter[1]
        } ${newView.viewSize} ${newView.viewSize}`,
      }
    },
    {
      toInit: true,
      elementSize: 0,
      elementOrigin: [0, 0] as Point,
      viewScale: Constants.ui.defaultScale,
      viewAngle: defaultAngle,
      viewCenter: [0, 0] as Point,
      viewSize: Constants.ui.maxViewBox / Constants.ui.defaultScale,
      viewbox: `${-Constants.ui.maxViewBox / 2} ${-Constants.ui.maxViewBox / 2} ${
        Constants.ui.maxViewBox
      } ${Constants.ui.maxViewBox}`,
    },
  )

  useEffect(() => {
    function handleResize() {
      // setTimeout to make sur aside is at the right state (fixed when onlyXs, normal when bigger than xs)
      setTimeout(() => {
        dispatchView({ type: 'resize' })
      })
    }
    const element = ref.current
    if (element) {
      element.addEventListener('resize', handleResize)
    } else {
      setTimeout(() => {
        dispatchView({ type: 'toInit' })
      }, 100)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleResize, { capture: true })
    return () => {
      if (element) {
        element.addEventListener('resize', handleResize)
      }
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleResize, { capture: true })
    }
  }, [view.toInit])

  const View = useMemo(() => {
    const component: React.FC<ViewProps> = ({ toolbar, image, children, viewbox, viewAngle }) => {
      return (
        <SvgContainer>
          {toolbar && <ToolbarContainer variant="outlined">{toolbar}</ToolbarContainer>}
          <StyledSvg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            ref={ref}
            viewBox={viewbox}>
            <g transform={`rotate(${viewAngle})`}>
              {image && <ImageLoader image={image} />}
              {children}
            </g>
          </StyledSvg>
        </SvgContainer>
      )
    }
    return component
  }, [ref])

  const setView = useCallback(
    ({ center, scale, angle }: { center?: Point; scale?: number; angle?: number }) =>
      dispatchView({ type: 'setView', center, scale, angle }),
    [],
  )
  const initView = useCallback(() => {
    dispatchView({ type: 'toInit' })
  }, [])

  const htmlPointToSvg = useCallback(
    ([x, y]: Point): Point => {
      return PlanUtils.rotatePoint(
        [
          ((x - view.elementOrigin[0]) / view.elementSize - 0.5) * view.viewSize +
            view.viewCenter[0],
          ((y - view.elementOrigin[1]) / view.elementSize - 0.5) * view.viewSize +
            view.viewCenter[1],
        ],
        -view.viewAngle,
      )
    },
    [view],
  )
  const svgPointToHtml = useCallback(
    ([x, y]: Point): Point => {
      const rotatedPoint = PlanUtils.rotatePoint([x, y], view.viewAngle)
      return [
        ((rotatedPoint[0] - view.viewCenter[0]) / view.viewSize + 0.5) * view.elementSize +
          view.elementOrigin[0],
        ((rotatedPoint[1] - view.viewCenter[1]) / view.viewSize + 0.5) * view.elementSize +
          view.elementOrigin[1],
      ]
    },
    [view],
  )
  const htmlDistanceToSvg = useCallback(
    (distance: number): number => {
      if (view.elementSize === 0) {
        return 0
      }
      return (distance * view.viewSize) / view.elementSize
    },
    [view],
  )

  // const getToolbarStyle = useCallback(
  //   (svgPoints: Point | Point[]): Record<string, string> => {
  //     let toolbarPosition
  //     if (typeof svgPoints[0] === 'object') {
  //       const firstPoint = svgPointToHtml(svgPoints[0])
  //       const { minX, maxX, minY } = (svgPoints as Point[]).reduce(
  //         ({ minY, maxX, minX }, point: Point) => {
  //           let htmlPoint = svgPointToHtml(point)
  //           return {
  //             minX: Math.min(minX, htmlPoint[0]),
  //             maxX: Math.max(maxX, htmlPoint[0]),
  //             minY: Math.min(minY, htmlPoint[1]),
  //           }
  //         },
  //         {
  //           minX: firstPoint[0],
  //           maxX: firstPoint[0],
  //           minY: firstPoint[1],
  //         },
  //       )
  //       toolbarPosition = [minX + (maxX - minX) / 2, minY]
  //     } else {
  //       toolbarPosition = svgPointToHtml(svgPoints as Point)
  //     }

  //     if (ref.current) {
  //       const origin = ref.current
  //         ? [
  //             view.elementOrigin[0] - (ref.current.clientWidth - view.elementSize) / 2,
  //             view.elementOrigin[1] - (ref.current.clientHeight - view.elementSize) / 2,
  //           ]
  //         : view.elementOrigin
  //       toolbarPosition = [
  //         Math.min(
  //           Math.max(toolbarPosition[0], origin[0] + 25),
  //           origin[0] + ref.current.clientWidth - 25,
  //         ),
  //         Math.min(
  //           Math.max(toolbarPosition[1] - 20, origin[1] + 25),
  //           origin[1] + ref.current.clientHeight - 25,
  //         ),
  //       ]
  //     }

  //     return {
  //       position: 'fixed',
  //       transform: 'translate(-50%, -100%)',
  //       left: toolbarPosition[0] + 'px',
  //       top: toolbarPosition[1] + 'px',
  //     }
  //   },
  //   [svgPointToHtml, view.elementOrigin, view.elementSize, ref],
  // )

  useGesture({
    ref: ref as unknown as React.MutableRefObject<HTMLElement>,
    doubleClickLatency,
    pinchBound,
    capture,
    onPinch: (scale, position) => {
      const viewScale =
        ((scale - Constants.ui.pinchBound.min) / Constants.ui.pinchBound.max) *
          Constants.ui.zoomBound.max +
        Constants.ui.zoomBound.min
      const viewPosition = htmlPointToSvg(position)
      if (!onPinch || onPinch(viewScale, viewPosition)) {
        dispatchView({
          type: 'pinchView',
          position: viewPosition,
          htmlPosition: position,
          scale: viewScale,
        })
      }
    },
    onDrag: (delta, position, target) => {
      const viewDelta = [htmlDistanceToSvg(delta[0]), htmlDistanceToSvg(delta[1])] as Point
      const viewPosition = htmlPointToSvg(position)

      if (!onDrag || onDrag(viewDelta, viewPosition, target?.id)) {
        dispatchView({ type: 'dragView', delta: viewDelta })
      }
    },
    onSingleClick: onSingleClick
      ? (position: Point, target) => onSingleClick(htmlPointToSvg(position), target?.id)
      : undefined,
    onDoubleClick: onDoubleClick
      ? (position: Point, target) => onDoubleClick(htmlPointToSvg(position), target?.id)
      : undefined,
    onDragEnd,
    onKey,
  })

  const cornerSize = useMemo<number>(() => {
    return htmlDistanceToSvg(Constants.ui.pixelCorner) || Constants.ui.pixelCorner
  }, [htmlDistanceToSvg])
  const wallSize = useMemo<number>(() => {
    return htmlDistanceToSvg(Constants.ui.pixelWall) || Constants.ui.pixelWall
  }, [htmlDistanceToSvg])
  const locatedMaterialSize = useMemo<number>(() => {
    return htmlDistanceToSvg(Constants.ui.pixelLocatedMaterial) || Constants.ui.pixelLocatedMaterial
  }, [htmlDistanceToSvg])

  return {
    View,
    cornerSize,
    wallSize,
    locatedMaterialSize,
    viewbox: view.viewbox,
    viewCenter: view.viewCenter,
    viewAngle: view.viewAngle,
    initView,
    setView,
    htmlPointToSvg,
    svgPointToHtml,
    htmlDistanceToSvg,
    // getToolbarStyle,
  }
}

export default useView
