import { Box, styled } from '@mui/material'
import { InfosProps } from '../../../models/props.models'

const StyledBlock = styled(Box)(({ theme }) => ({
  '& > div:first-of-type': {
    border: `1px solid ${theme.palette.blockBorder}`,
    borderRadius: '4px',
    padding: '20px 25px',
    '& > div:nth-of-type(n)': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      '&:not(:last-of-type)': {
        marginBottom: '10px',
      },
      '& > .MuiTypography-root:first-of-type': {
        color: theme.palette.blockLabel,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.25,
      },
      '& > .MuiTypography-root:last-of-type': {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.25,
        textAlign: 'right',
        whiteSpace: 'pre-line',
      },
    },
  },
}))
const Info: React.FC<InfosProps> = ({ children }) => {
  return (
    <StyledBlock>
      <Box>{children}</Box>
    </StyledBlock>
  )
}
export default Info
