import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useObservable } from '@ngneat/react-rxjs'
import Constants from '../../constants'
import { catalogsQuery, catalogsService } from '../../store/catalogs'
import { sessionService } from '../../store/session'
import { organizationsService } from '../../store/organizations'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import useModal from '../../hooks/useModal.hooks'
import useRoute from '../../hooks/useRoute.hooks'
import { Mail as MailIcon } from '@mui/icons-material'
import {
  Catalog,
  NetworkStatus,
  isNeeds,
  CatalogIcon,
  CatalogLight,
  CatalogStatus,
  BuildingType,
  CatalogType,
} from '../../models/catalogs.models'
import { Filter, ItemType, FilterItem } from '../../models/props.models'
import { Route, Mode } from '../../models/commons.models'
import { alpha, styled, Typography } from '@mui/material'
import dayjs from '../../utils/dayjs.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import CatalogList, { CatalogListProps } from '../../components/catalog/List.catalog'
import Modal from '../../components/layout/Modal.layout'
import Stack from '../common/Stack.common'

const StyledMaterialCountContainer = styled(Stack, {
  shouldForwardProp: (propName: string) => propName !== 'secondary',
})<{ secondary: boolean }>(({ theme, secondary }) => ({
  backgroundColor: alpha(
    secondary ? theme.palette.secondary.main : theme.palette.primary.main,
    0.1,
  ),
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  color: secondary ? theme.palette.secondary.main : theme.palette.primary.main,
  padding: '12px 20px 12px 15px',
}))

interface PageCatalogListprops
  extends Omit<
    CatalogListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
    | 'isAdmin'
  > {}
const PageCatalogList: React.FC<PageCatalogListprops> = (props) => {
  const show = useSnackbar()
  const { goTo } = useRoute()
  const [modal, setModal] = useModal<string>()

  const { t } = useTranslation()

  const { organization, types, isPublic, ...listProps } = props
  const isAdmin = useMemo(() => Constants.mode === Mode.admin, [])
  const catalogIcon = useMemo(() => (isAdmin ? sessionService.catalogIcon() : undefined), [isAdmin])

  const uniqueType = useMemo<false | CatalogType>(
    () => (types.length === 1 ? types[0] : false),
    [types],
  )
  const showBuildingTypes = useMemo<boolean>(
    () =>
      types.reduce((acc: boolean, type: CatalogType) => {
        return acc || type === CatalogType.construction || type === CatalogType.deconstruction
      }, false),
    [types],
  )
  const showStatus = useMemo(() => {
    return types.reduce((acc: boolean, type: CatalogType) => {
      return acc || sessionService.showStatus({ type, isPublic: !!isPublic })
    }, false)
  }, [types, isPublic])

  const [disableBuildingTypes, setDisableBuildingTypes] = useState<boolean>(false)
  const [catalogs] = useObservable(catalogsQuery.catalogs)
  const [catalogLoading] = useObservable(catalogsQuery.loading)

  const onClick = useCallback(
    (catalog: Catalog) => {
      if (isPublic) {
        const catalogFinished =
          catalog.retrieval.endDate &&
          new Date().getTime() > new Date(catalog.retrieval.endDate).getTime()
        goTo({
          route: catalogFinished ? Route.publicCatalogInformations : Route.publicCatalog,
          catalogId: catalog._id,
        })
      } else if (!Constants.getIsLocal() || catalog.networkStatus !== NetworkStatus.remote) {
        goTo({ route: Route.workspaceCatalog, catalogId: catalog._id })
      } else {
        setModal(catalog._id)
      }
    },
    [isPublic, setModal, goTo],
  )

  const getCatalogs = useCallback(
    async (filters: any, ended = false) => {
      const filterTypes = (
        filters?.type ? [filters?.type] : filters?.types || types
      ) as CatalogType[]

      let periodStart: Date | undefined, periodEnd: Date | undefined
      if (filters?.periodStart) {
        periodStart = dayjs(filters?.periodStart).startOf('month').toDate()
      }

      if (filters?.periodEnd) {
        periodEnd = dayjs(filters?.periodEnd).endOf('month').toDate()
      }
      if (isAdmin || isPublic) {
        let filterCatalogIcon = undefined
        if (catalogIcon) {
          filterCatalogIcon = filters.catalogIcon
            ? catalogIcon
            : filters.catalogIcon === false
            ? CatalogIcon.none
            : undefined
        }

        return await catalogsService.getCatalogs({
          ...filters,
          types: filterTypes,
          type: null,
          organization: organization ?? filters.organization?.value,
          public: isPublic,
          periodStart,
          periodEnd,
          catalogIcon: filterCatalogIcon,
          ended,
          upToDate: !!filters.unReadMessage ? false : undefined,
          ...(filters.location?.position && filters.location?.city
            ? {
                radius: 50,
                coordinates: filters.location.position,
              }
            : filters.location?.countryCode && !filters.location?.city
            ? { countryCode: filters.location?.countryCode }
            : {}),
        })
      }

      const filtered = catalogs.filter((catalog: CatalogLight) => {
        let match = !!filterTypes.find((type) => type === catalog.type)
        try {
          if (!!filters.unReadMessage) {
            match = match && !!catalog.sellerChatToReadCount
          }

          if (periodStart) {
            match =
              match &&
              (!catalog.retrieval.endDate ||
                new Date(catalog.retrieval.endDate).getTime() > periodStart.getTime())
          }
          if (periodEnd) {
            match =
              match &&
              (!catalog.retrieval.startDate ||
                new Date(catalog.retrieval.startDate).getTime() < periodEnd.getTime())
          }

          if (filters?.status?.length > 0) {
            match =
              match && !!filters.status.find((status: CatalogStatus) => status === catalog.status)
          }

          if (filters.search) {
            const regexp = new RegExp(filters.search, 'gi')
            match =
              match &&
              (regexp.test(catalog.name) ||
                regexp.test(catalog.retrieval.location?.city || '') ||
                regexp.test(catalog.retrieval.location?.postalCode || ''))
          }
        } catch (err: any) {
          show(err)
        }
        return match
      })
      return {
        data: filtered,
        count: filtered.length,
        total: filtered.length,
      }
    },
    [isAdmin, show, types, catalogs, isPublic, catalogIcon, organization],
  )
  const getEndedCatalog = useCallback(
    async (filters: any) => {
      return getCatalogs(filters, true)
    },
    [getCatalogs],
  )

  const filter = useMemo<Filter>(() => {
    const position = isPublic ? 'aside' : 'modal'
    const publicFilters = [
      ...(!uniqueType
        ? [
            {
              type: isAdmin ? ItemType.multiSelect : ItemType.radio,
              key: isAdmin ? 'types' : 'type',
              grid: isPublic ? undefined : { sm: 2, xs: 12 },
              position: isPublic ? 'aside' : undefined,
              props: {
                placeholder: t('catalogs:components.list.interventionTypesFilter'),
                column: true,
                items: types.map((type) => ({
                  value: type,
                  label: t(`catalogs:type.${type}`),
                })),
                label: isAdmin ? '' : t('catalogs:components.list.interventionTypesFilter'),
              },
            },
          ]
        : []),
      ...(isPublic || (isAdmin && !organization)
        ? [
            ...(showBuildingTypes
              ? [
                  {
                    type: ItemType.multiSelect,
                    key: 'buildingTypes',
                    position,
                    props: {
                      disabled: disableBuildingTypes,
                      multiple: true,
                      label: t('catalogs:components.list.buildingTypesFilter'),
                      items: createOptionsFromEnum(BuildingType, 'catalogs:buildingType'),
                    },
                  },
                ]
              : []),
            {
              type: ItemType.date,
              key: 'periodStart',
              position,
              props: {
                placeholder: t('catalogs:components.list.from'),
                label: t('catalogs:components.list.datesFilters'),
                format: 'MM/YYYY',
                blurredFormat: true,
              },
            },
            {
              type: ItemType.date,
              key: 'periodEnd',
              position,
              props: {
                placeholder: t('catalogs:components.list.until'),
                format: 'MM/YYYY',
                blurredFormat: true,
              },
            },
          ]
        : []),
      ...(catalogIcon
        ? [
            {
              type: ItemType.checkbox,
              key: 'catalogIcon',
              position,
              props: {
                label: t('catalogs:attributes.catalogIcon'),
                falseValue: true,
              },
            },
          ]
        : []),
    ]
    const privateFilters = [
      ...(showStatus
        ? [
            {
              type: ItemType.multiSelect,
              key: 'status',
              grid: { sm: 2, xs: 12 },
              props: {
                placeholder: t('catalogs:components.list.statusFilter'),
                multiSelectedLabel: (count: number) =>
                  t('global:inputs.selectedOptions', { count }),
                items: createOptionsFromEnum(CatalogStatus, 'catalogs:status'),
              },
            },
          ]
        : []),
      ...(isAdmin && !organization
        ? [
            {
              type: ItemType.asyncSelect,
              key: 'organization',
              grid: { sm: 4, xs: 12 },
              props: {
                placeholder: t('catalogs:components.list.organizationFilter'),
                minimumSearchLength: 3,
                getOptions: (search?: string) =>
                  organizationsService.getOrganizationOptions(search),
              },
            },
          ]
        : [
            {
              type: ItemType.date,
              key: 'periodStart',
              grid: { sm: 2, xs: 6 },
              props: {
                placeholder: t('catalogs:components.list.from'),
                format: 'MM/YYYY',
                blurredFormat: true,
              },
            },
            {
              type: ItemType.date,
              key: 'periodEnd',
              grid: { sm: 2, xs: 6 },
              props: {
                placeholder: t('catalogs:components.list.until'),
                format: 'MM/YYYY',
                blurredFormat: true,
              },
            },
          ]),
      ...(Constants.mode !== Mode.app
        ? [
            {
              type: ItemType.checkbox,
              key: 'unReadMessage',
              grid: { md: 2, xs: 6 },
              props: {
                label: t('orders:components.list.unreadFilter'),
              },
            },
          ]
        : []),
      {
        type: ItemType.search,
        key: 'search',
        grid: { sm: 4, xs: 12 },
        props: {
          placeholder: t('catalogs:components.list.searchFilter'),
        },
      },
    ]
    if (isPublic) {
      return {
        info: (_: any, count: number) => {
          return (
            <StyledMaterialCountContainer
              secondary={types.every((type: CatalogType) => isNeeds(type))}
              direction="row"
              alignItems="center"
              spacing="10px">
              <Typography variant="h2">{t('catalogs:components.list.count', { count })}</Typography>
            </StyledMaterialCountContainer>
          )
        },
        items: publicFilters as FilterItem[],
      }
    }
    return {
      items: [...publicFilters, ...privateFilters] as FilterItem[],
    }
  }, [
    t,
    organization,
    catalogIcon,
    isAdmin,
    isPublic,
    showStatus,
    types,
    disableBuildingTypes,
    uniqueType,
    showBuildingTypes,
  ])

  const onFilterChange = useCallback(
    (
      filter: Record<string, any>,
      setFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ) => {
      if (
        (filter.type === CatalogType.needs || filter.type === CatalogType.storage) &&
        !disableBuildingTypes
      ) {
        setFilter((filters: any) => ({ ...filters, buildingTypes: undefined }))
        setDisableBuildingTypes(true)
      } else if (
        disableBuildingTypes &&
        filter.type !== CatalogType.needs &&
        filter.type !== CatalogType.storage
      ) {
        setDisableBuildingTypes(false)
      }
    },
    [disableBuildingTypes],
  )

  return (
    <>
      <CatalogList
        {...listProps}
        loading={!isPublic && !isAdmin && catalogLoading}
        valueIcon={
          Constants.mode !== Mode.app && !isPublic
            ? (catalog?: Catalog) => {
                if (
                  catalog &&
                  ((isAdmin && (catalog?.clientChatToReadCount ?? 0) > 0) ||
                    (catalog?.sellerChatToReadCount ?? 0) > 0)
                ) {
                  return (
                    <MailIcon
                      color="primary"
                      onClick={() =>
                        goTo({ route: Route.workspaceCatalogChat, catalogId: catalog._id })
                      }
                    />
                  )
                }
                return undefined
              }
            : undefined
        }
        types={types}
        organization={organization}
        isPublic={isPublic}
        isAdmin={isAdmin}
        disabledPagination={!isAdmin && !isPublic}
        getValues={getCatalogs}
        getSecondListValues={isPublic ? getEndedCatalog : undefined}
        secondInfo={
          isPublic
            ? (count: number) => (
                <Typography
                  variant="h2"
                  paddingTop="48px"
                  marginTop="32px"
                  borderTop={`1px solid ${Constants.colors.menuBorder}`}>
                  {t('catalogs:components.list.endedCount', { count })}
                </Typography>
              )
            : undefined
        }
        onValueClick={onClick}
        onSecondValueClick={onClick}
        filter={filter}
        onFilterChange={onFilterChange}
      />

      {modal && modal !== 'add' && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={catalogsService.importCatalog.bind(null, modal)}
          // go to does not work is the modal is closed
          keepOpen
          onSuccess={() => {
            show(t('catalogs:actions.import.success'))
            goTo({ route: Route.workspaceCatalog, catalogId: modal })
          }}
          title={t('catalogs:actions.import.label')}
          description={t('catalogs:actions.import.description')}
        />
      )}
    </>
  )
}
export default PageCatalogList
