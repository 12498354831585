import { PlanRoom } from '../../../models/catalogs.models'
import Edge from './Edge.plan'
import Point from './Point.plan'
import Constants from '../../../constants'

const Room = (props: PlanRoom & { cornerSize: number; wallSize: number }): JSX.Element => {
  if (!props.points || props.points.length === 0) {
    return <></>
  }
  const seleted = props.isTmp || props.isSelected
  const isClosed = !props.isTmp || props.closed
  const drawPath = isClosed && props.isSelected

  const cornerRadius = !seleted
    ? props.cornerSize
    : props.cornerSize * Constants.ui.selectedCornerRation
  const wallWidth = !seleted ? props.wallSize : props.wallSize * Constants.ui.selectedWallRation

  const points = []
  const lines = []
  const path = drawPath ? (
    <path
      d={`M ${props.points.reduce((path, point) => `${path} ${point[0]} ${point[1]}`, '')} Z`}
      fill="#4f472d"
      fillOpacity={0.5}
    />
  ) : null

  for (let i = 0; i < props.points.length; i++) {
    const startIndex = i
    const endIndex = (i + 1) % props.points.length
    points.push(
      <Point
        id={`room.${props._id}.point.${startIndex}`}
        key={`point.${startIndex}`}
        position={props.points[startIndex]}
        size={cornerRadius}
      />,
    )
    if (isClosed || endIndex !== 0) {
      lines.push(
        <Edge
          id={`room.${props._id}.line.${startIndex}`}
          key={`line.${i}`}
          line={[props.points[startIndex], props.points[endIndex]]}
          size={wallWidth}
        />,
      )
    }
  }

  return (
    <g>
      {path}
      {lines}
      {points}
    </g>
  )
}

export default Room
