import { MaterialType } from '../../../models/materials.models'
import { useOutletContext } from 'react-router-dom'
import PageMaterialList from '../../../components/page/MaterialList.page'
import { FilterableRoute } from '../../../models/commons.models'

const ListMaterialResourcePage = () => {
  const { useImperials } = useOutletContext<{
    useImperials: boolean
  }>()

  return (
    <PageMaterialList
      useImperials={useImperials}
      withTabs
      canUpdate
      type={MaterialType.resource}
      filterKey={FilterableRoute.materialsResources}
    />
  )
}
export default ListMaterialResourcePage
