import { Pagination } from './commons.models'
import { Organization } from './organizations.models'
import { User } from './users.models'

export enum ChatStatus {
  onGoing = 'onGoing',
  deleted = 'deleted',
}

export enum MessageFrom {
  seller = 'seller',
  client = 'client',
}

export interface Message {
  from: MessageFrom
  content: string
  createdAt: Date
}

export interface Chat {
  _id: string
  lastMessageAt: Date
  status: ChatStatus
  client: User
  seller: Organization
  catalog: string
  catalogName: string
  order?: string
  orderNumber?: number
  clientUpToDate: boolean
  sellerUpToDate: boolean
  messages: Message[]
}
export interface ChatsPagination extends Pagination {
  data: Chat[]
}

export interface CreateChat {
  from: MessageFrom
  content: string
  catalog?: string
  order?: string
}

export interface UpdateChat {
  from: MessageFrom
  content?: string
  markAsRead?: boolean
}
