import { alpha } from '@mui/material'

export const createAppBar = (colors: any, ui: any) => ({
  MuiAppBar: {
    root: {
      backgroundColor: `${colors.white} !important`,
      boxShadow: `0 2px 20px 0 ${alpha(colors.black, 0.08)}`,
      height: ui.appBarSize,
    },
  },
  MuiToolbar: {
    root: {
      alignItems: 'center',
      backgroundColor: 'transparent !important',
      color: colors.black,
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
  },
})
