import { useMemo, useRef, useState } from 'react'
import {
  alpha,
  AppBar,
  Box,
  Button,
  Dialog,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'

import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'

import {
  ShoppingBasket as ShoppingBasketIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Apartment as ApartmentIcon,
  SwapHoriz as SwapHorizIcon,
  Sell as SellIcon,
  Handshake as HandshakeIcon,
  Mail as MailIcon,
  AccountBox as AccountBoxIcon,
  Logout as LogoutIcon,
  Favorite as FavoriteIcon,
  PersonAdd as PersonAddIcon,
  Login as LoginIcon,
  LineStyle as LineStyleIcon,
} from '@mui/icons-material'

import { sessionQuery, sessionService } from '../../store/session'
import { cartsQuery } from '../../store/carts'
import { Route } from '../../models/commons.models'
import { getPlatform } from '../../models/platforms.models'
import Constants from '../../constants'
import useRoute from '../../hooks/useRoute.hooks'
import useModal from '../../hooks/useModal.hooks'
import OutlinedButton from '../common/button/Outlined.button'
import FormLogin from '../session/FormLogin.session'
import FormSignup from '../session/FormSignup.session'
import LayoutLangs from './Langs.layout'
import Environment from './Environment.layout'

const StyleOutlinedButton = styled(OutlinedButton, {
  shouldForwardProp: (prop) => prop !== 'transparent',
})<{ transparent?: boolean }>(({ transparent, theme }) => ({
  color: transparent ? theme.palette.white : theme.palette.black,
}))
const NavButtonsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'transparent',
})<{ transparent?: boolean }>(({ transparent, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  '& > :not(:first-of-type)': {
    marginLeft: '2vw',
  },
  '& a': {
    color: transparent ? theme.palette.white : theme.palette.black,
  },
  '& .MuiButton-root': {
    color: transparent ? theme.palette.white : theme.palette.black,
    fontSize: '0.875rem',
    fontWeight: 700,
    padding: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    '&[aria-label=active]': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.black, 0.15),
    },
  },
}))

const MaterialsLabel = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 900,
  marginLeft: '4px',
  marginRight: '8px',
  paddingTop: '1px',
})
type FrontHeader = {
  additionals?: { path: string; key: string }[]
}

const FrontHeaderComponent = ({ additionals }: FrontHeader) => {
  const { t } = useTranslation()
  const { getPath, goTo, isAtRoute, matchNeeds } = useRoute()
  const navigate = useNavigate()
  const [modal, setModal] = useModal<'login' | 'signup'>()
  const transparentHeader = isAtRoute(Route.home) && Constants.ui.transparentHeader
  const platform = getPlatform(Constants.platform)

  const showPublicNeeds = useRef(sessionService.showPublicNeeds())
  const publicMaterialsRoute = useMemo(() => {
    return showPublicNeeds.current
      ? matchNeeds
        ? Route.publicNeeds
        : Route.publicResources
      : Route.publicMaterials
  }, [matchNeeds])
  const publicCatalogsRoute = useMemo(() => {
    return showPublicNeeds.current
      ? matchNeeds
        ? Route.publicCatalogsNeeds
        : Route.publicCatalogsResources
      : Route.publicCatalogs
  }, [matchNeeds])

  const [user] = useObservable(sessionQuery.user)
  const [isAdmin] = useObservable(sessionQuery.isAdmin)
  const [cart] = useObservable(cartsQuery.cart)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onMenuClose = () => setAnchorEl(null)

  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))

  const MenuComponent = ({ children }: { children: any }) => {
    return Boolean(anchorEl) ? (
      !onlyXs ? (
        <Menu
          open
          anchorEl={anchorEl}
          keepMounted
          onClose={onMenuClose}
          TransitionComponent={Fade}
          style={{ marginTop: '50px' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          {children}
        </Menu>
      ) : (
        <Dialog open onClose={onMenuClose} fullScreen TransitionComponent={Fade}>
          <Box
            height={Constants.ui.appBarSize + 'px'}
            padding="20px"
            display="flex"
            justifyContent="center"
            alignContent="flex-end">
            <IconButton sx={{ marginLeft: 'auto' }} onClick={() => setAnchorEl(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>{children}</Box>
        </Dialog>
      )
    ) : (
      <></>
    )
  }
  return (
    <>
      <AppBar
        position="static"
        sx={{
          zIndex: 2,
          boxShadow: transparentHeader ? 'none !important' : undefined,
          backgroundColor: transparentHeader ? 'transparent !important' : undefined,
        }}>
        <Toolbar sx={{ color: 'white' }}>
          <Environment />
          <Box mr="20px">
            <NavLink to={getPath({ route: Route.home })}>
              <img
                alt=""
                src={platform.logo}
                height={!onlyXs ? '68px' : '54px'}
                style={{
                  maxWidth: onlyXs ? 'calc(100vw - 130px)' : '',
                  filter: transparentHeader ? 'contrast(300%)' : '',
                }}
              />
            </NavLink>
          </Box>
          {!onlyXs && (
            <>
              <NavButtonsContainer transparent={transparentHeader} flex={1} ml="10px">
                {additionals &&
                  additionals.map(({ path, key }) => (
                    <NavLink to={path} key={key}>
                      {({ isActive }: { isActive: boolean }) => (
                        <Button aria-label={isActive ? 'active' : ''} variant="text">
                          {t(key as any)}
                        </Button>
                      )}
                    </NavLink>
                  ))}

                <NavLink to={getPath({ route: publicMaterialsRoute })}>
                  {({ isActive }: { isActive: boolean }) => (
                    <Button aria-label={isActive ? 'active' : ''} variant="text">
                      {t('global:routes.publicMaterials')}
                    </Button>
                  )}
                </NavLink>

                {!downMd && (
                  <NavLink to={getPath({ route: publicCatalogsRoute })}>
                    {({ isActive }: { isActive: boolean }) => (
                      <Button aria-label={isActive ? 'active' : ''} variant="text">
                        {t('global:routes.publicCatalogs')}
                      </Button>
                    )}
                  </NavLink>
                )}
              </NavButtonsContainer>
              {((!downLg && !user) || (!downMd && user)) && (
                <Box mr="15px">
                  <StyleOutlinedButton
                    transparent={transparentHeader}
                    variant="outlined"
                    color={transparentHeader ? 'inherit' : 'primary'}
                    startIcon={
                      <Box display="flex" alignItems="center">
                        <ShoppingBasketIcon
                          color={transparentHeader ? 'inherit' : 'primary'}
                          fontSize="small"
                        />
                        <MaterialsLabel color={transparentHeader ? 'inherit' : 'primary'}>
                          {cart.length}
                        </MaterialsLabel>
                      </Box>
                    }
                    onClick={() => goTo({ route: Route.cart })}>
                    {t('global:routes.cart')}
                  </StyleOutlinedButton>
                </Box>
              )}
              {!onlyXs && !!user && (
                <Box>
                  <StyleOutlinedButton
                    transparent={transparentHeader}
                    variant="outlined"
                    endIcon={
                      <KeyboardArrowDownIcon color={transparentHeader ? 'inherit' : 'primary'} />
                    }
                    onClick={(evt: React.MouseEvent<HTMLElement>) =>
                      setAnchorEl(evt.currentTarget)
                    }>
                    {t('global:routes.routeMenu')}
                  </StyleOutlinedButton>
                </Box>
              )}
              {!downLg && !user && (
                <Box mr="15px">
                  <StyleOutlinedButton
                    transparent={transparentHeader}
                    variant="outlined"
                    color="primary"
                    onClick={() => setModal('signup')}>
                    {t('global:routes.signup')}
                  </StyleOutlinedButton>
                </Box>
              )}
              {!downMd && !user && (
                <Box>
                  <StyleOutlinedButton
                    transparent={transparentHeader}
                    variant="outlined"
                    color="primary"
                    onClick={() => setModal('login')}>
                    {t('global:routes.login')}
                  </StyleOutlinedButton>
                </Box>
              )}
            </>
          )}
          {((downLg && !user) || onlyXs) && (
            <IconButton
              sx={{ marginLeft: 'auto', color: transparentHeader ? 'white' : undefined }}
              onClick={(evt: React.MouseEvent<HTMLElement>) => setAnchorEl(evt.currentTarget)}>
              <MenuIcon />
            </IconButton>
          )}
          <LayoutLangs color={!!transparentHeader ? 'white' : undefined} />
        </Toolbar>
        <MenuComponent>
          {onlyXs && (
            <>
              {additionals &&
                additionals.map(({ path, key }) => (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      onMenuClose()
                      navigate(path)
                    }}>
                    {t(key as any)}
                  </MenuItem>
                ))}
              <MenuItem
                onClick={() => {
                  onMenuClose()
                  goTo({ route: publicMaterialsRoute })
                }}>
                <LineStyleIcon sx={{ marginRight: '5px' }} />
                {t('global:routes.publicMaterials')}
              </MenuItem>
            </>
          )}
          {downMd && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: publicCatalogsRoute })
              }}>
              <ApartmentIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.publicCatalogs')}
            </MenuItem>
          )}
          {((!user && downLg) || (downMd && !!user)) && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.cart })
              }}>
              <ShoppingBasketIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.cart')}
            </MenuItem>
          )}
          {!user && downLg && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                setModal('signup')
              }}>
              <PersonAddIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.signup')}
            </MenuItem>
          )}
          {!user && downMd && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                setModal('login')
              }}>
              <LoginIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.login')}
            </MenuItem>
          )}

          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.favorites })
              }}>
              <FavoriteIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.favorites')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.workspace })
              }}>
              <ApartmentIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.workspace')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.myOrders })
              }}>
              <SellIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.myOrders')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.requests })
              }}>
              <HandshakeIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.requests')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.chats })
              }}>
              <MailIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.chats')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              onClick={() => {
                onMenuClose()
                goTo({ route: Route.account })
              }}>
              <AccountBoxIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.account')}
            </MenuItem>
          )}
          {isAdmin && !!process.env.REACT_APP_ADMIN_HOST && (
            <MenuItem
              aria-label="primary"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_ADMIN_HOST}`
                onMenuClose()
              }}>
              <SwapHorizIcon sx={{ marginRight: '5px' }} />
              {t('global:routes.admin')}
            </MenuItem>
          )}
          {!!user && (
            <MenuItem
              aria-label="secondary"
              onClick={() => {
                onMenuClose()
                return sessionService.logout()
              }}>
              <LogoutIcon sx={{ marginRight: '5px' }} />
              {t('sessions:actions.logout')}
            </MenuItem>
          )}
        </MenuComponent>
      </AppBar>
      {modal === 'login' && <FormLogin onClose={() => setModal('')} />}
      {modal === 'signup' && <FormSignup onClose={() => setModal('')} />}
    </>
  )
}

export default FrontHeaderComponent
