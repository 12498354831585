import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import PageRequestList from '../../../components/page/RequestList.page'

const RequestSentList = () => {
  useCheckRoute('RequestSentList', [Mode.front])

  return <PageRequestList sent withTabs />
}
export default RequestSentList
