import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import { Mode, News, Route } from '../models/commons.models'

import { getPlatform } from '../models/platforms.models'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import Stack from '../components/common/Stack.common'
import { useTheme } from '@mui/material/styles/index.js'
import { Navigate, useParams } from 'react-router-dom'

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Constants from '../constants'

const NewsHeader = styled(Stack)((props) => ({
  height: '530px',
  width: '100%',
  alignItems: 'center',
  position: 'relative',
  [props.theme.breakpoints.down('sm')]: {
    height: 'fit-content',
  },
}))

const ImgContainer = styled('div')((props) => ({
  height: '100%',
  width: '60%',
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  [props.theme.breakpoints.down('sm')]: {
    height: '425px',
    width: '100%',
  },
  [props.theme.breakpoints.down('xs')]: {
    height: '220px',
  },
}))

const Titles = styled(Stack)((props) => ({
  width: '40%',
  padding: '50px 42px',
  marginLeft: '-120px',
  backgroundColor: Constants.colors.white,
  '& > div[aria-label=news-type]': {
    backgroundColor: Constants.colors.home.newsType,
    clipPath: 'polygon(0 0, 100% 0, 100% 75%, 12.5px 74%, 0 100%, 0% 75%)',
    height: '40px',
    padding: '0 14px',
    '& > .MuiTypography-root': {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.25,
      paddingTop: '5px',
    },
  },
  [props.theme.breakpoints.down('sm')]: {
    margin: '-70px 15px 0px',
    padding: '25px',
    width: 'calc(100% - 30px)',
  },
}))

const BackButton = styled(Stack)((props) => ({
  padding: '8px 11px',
  position: 'fixed',
  top: `calc(20px + ${Constants.ui.appBarSize}px)`,
  left: '70px',
  backgroundColor: Constants.colors.black,
  cursor: 'pointer',
  zIndex: 1,
  '&:hover': {
    '& .MuiTypography-root': {
      color: Constants.colors.primary,
    },
    '& .MuiSvgIcon-root': {
      color: Constants.colors.primary,
    },
  },
  '& .MuiTypography-root': {
    color: Constants.colors.white,
    fontWeight: 600,
    fontSize: '.875rem',
  },
  '& .MuiSvgIcon-root': {
    color: Constants.colors.white,
  },
  [props.theme.breakpoints.down('sm')]: {
    left: '15px',
  },
}))

const Title = styled(Typography)({
  fontSize: '1.875rem',
  fontWeight: 800,
  lineHeight: 1.25,
})

const PublicationDate = styled(Typography)({
  fontSize: '.75rem',
  color: Constants.colors.blockLabel,
})

const NewsMainText = styled('div')((props) => ({
  fontSize: '0.9375rem',
  fontWeight: 500,
  padding: '62px 20px',
  maxWidth: '880px',
  [props.theme.breakpoints.down('sm')]: {
    margin: '0 15px',
    padding: '0 10px 20px',
  },
}))

const ContentImagesContainer = styled(Stack)({
  width: '100%',
  maxWidth: '1200px',
})

const Cgu = () => {
  useCheckRoute('Cgu', [Mode.front, Mode.storeFront])
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const { t } = useTranslation()
  const { goTo } = useRoute()

  const { newsId } = useParams()

  const thisNews = newsId
    ? (getPlatform(Constants.platform).news as News[])[parseInt(newsId)]
    : null

  if (!thisNews) return <Navigate to="/" />

  return (
    <Stack alignItems="center" minHeight={`calc(100vh - ${Constants.ui.appBarSize}px)`} pb="30px">
      <NewsHeader direction={onlyXs ? 'column' : 'row'}>
        <BackButton
          direction="row"
          spacing="7px"
          alignItems="center"
          onClick={() => goTo({ route: Route.home })}>
          <ArrowBackIcon />
          <Typography>{t('sessions:pages.news.backToHome')}</Typography>
        </BackButton>
        <ImgContainer>
          <img width="100%" src={thisNews.picture} alt="news" />
        </ImgContainer>
        <Titles alignItems="flex-start" spacing="15px">
          <Box aria-label="news-type">
            <Typography>{thisNews.type}</Typography>
          </Box>
          <Title>{thisNews.title}</Title>
          <PublicationDate>
            {t('sessions:pages.news.publishedAt', { date: new Date(thisNews.publishedAt) })}
          </PublicationDate>
        </Titles>
      </NewsHeader>
      <Stack alignItems="center">
        <NewsMainText dangerouslySetInnerHTML={{ __html: thisNews.content }} />
      </Stack>
      {!!thisNews.contentImgs?.length && (
        <ContentImagesContainer direction="row" justifyContent="center" flexWrap="wrap" pb="30px">
          {thisNews.contentImgs.map((img, index) => (
            <img key={`content img ${index}`} style={{ maxWidth: '100%' }} src={img} alt="" />
          ))}
        </ContentImagesContainer>
      )}
    </Stack>
  )
}

export default Cgu
