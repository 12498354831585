import { MaterialModel } from '../../models/materialModels.models'
import { Actions } from '../../models/props.models'
import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'

interface MaterialModelCardProps {
  value: MaterialModel
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
}
const MaterialModelCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
}: MaterialModelCardProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card
      ariaLabel="material-model-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <DetailsComponent
        noElevation
        title={value.name}
        value={value}
        items={[
          {
            label: t('materialModels:attributes.organization'),
            key: 'organization.name',
          },
          {
            label: t('materialModels:attributes.primaryCategory'),
            key: 'primaryCategory.name',
            formatValue: (name: string) => t(`categories:name.${name}` as any),
          },
          {
            label: t('materialModels:attributes.secondaryCategory'),
            key: 'secondaryCategory.name',
            formatValue: (name: string) => t(`categories:name.${name}` as any),
          },
          {
            label: t('materialModels:attributes.tertiaryCategory'),
            key: 'tertiaryCategory.name',
            formatValue: (name: string) => t(`categories:name.${name}` as any),
          },
        ]}
      />
    </Card>
  )
}

export default MaterialModelCard
