import PageOrderList from '../../components/page/OrderList.page'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { Mode } from '../../models/commons.models'
import { sessionQuery } from '../../store/session'
import { useObservable } from '@ngneat/react-rxjs'

const MyOrdersPage = () => {
  useCheckRoute('MyOrder', [Mode.front, Mode.storeFront])
  const [useImperials] = useObservable(sessionQuery.useImperials)

  return <PageOrderList isClient useImperials={useImperials} />
}
export default MyOrdersPage
