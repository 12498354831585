import { Point } from '../../../models/commons.models'
import Constants from '../../../constants'

type EdgeProps = {
  line: [Point, Point]
  size: number
  id?: string
}
const Edge: React.FC<EdgeProps> = ({ line, size, id }): JSX.Element => {
  return (
    <line
      id={id}
      x1={line[0][0]}
      y1={line[0][1]}
      x2={line[1][0]}
      y2={line[1][1]}
      stroke={Constants.colors.primary}
      strokeWidth={size}
    />
  )
}
export default Edge
