export const DEFAULT_COLORS = {
  primary: '#EB6619',
  secondary: '#C73D58',
  success: '#6bb356',
  error: '#dd2a2a',
  info: '#3d7bc7',
  warning: '#EB6619',
  mainText: '#000000',
  secondaryText: '#939393',
  black: '#000000',
  white: '#ffffff',
  purple: '#675cdd',
  textFieldBackground: '#f2f2f2',
  menuBorder: '#dfdfdf',
  blockBorder: '#f2f2f2',
  blockLabel: '#848484',
  availableQuantity: '#70C277',
  desiredQuantity: '#808082',
  tabsBorder: '#E3E3E3',
  lightBorder: '#f0f0f1',
  tableHeader: '#b2b2b2',
  searchBackground: '#f8f8f8',
  gradient1: '#E2366F',
  gradient2: '#EA7734',
  card: '#f8dbdf',
  card1: '#E7794A',
  card2: '#E24E71',
  stepper: {
    active: '#d3325a',
    background: '#f4f4f4',
  },
  home: {
    primary: '#d48353',
    secondary: '#ca436f',
    title: '#c85b38',
    newsType: '#f2a98f',
    cycleBackground: '#fef9f8',
    arrow: '#ee804c',
    constructionCycle: {
      title: '#C73D58',
      step1: '#c73d6e',
      step2: '#d3325a',
      step3: '#d33232',
    },
    deconstructionCycle: {
      title: '#dc652b',
      step1: '#dc842b',
      step2: '#EB6619',
      step3: '#dc512b',
    },
  },
  roles: {
    admin: '#EB6619',
    platformAdmin: '#C73D58',
    user: '#6bb356',
  },
}
export type Colors = typeof DEFAULT_COLORS
