import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Discount,
  Order,
  Product,
  computeOrderTotal,
  computeOrderBeforeDiscount,
  DiscountType,
} from '../../models/orders.models'
import { Mode } from '../../models/commons.models'
import { FormItem, ItemType } from '../../models/props.models'
import {
  Material,
  TermsOfSale,
  MaterialType,
  convert2Imperials,
} from '../../models/materials.models'
import OrderListProduct from './ListProduct.order'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import Modal from '../layout/Modal.layout'
import MaterialList from '../material/List.material'
import { styled, FormHelperText, Button, Box, InputLabel } from '@mui/material'
import { materialsService } from '../../store/materials'
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material'
import ModalDiscount from './ModalDiscount.order'
import constants from '../../constants'
import LoaderOverlay from '../layout/LoaderOverlay.layout'

const StyledInputContainer = styled('div')({
  background: constants.colors.textFieldBackground,
  borderRadius: 4,
  fontSize: '0.875rem',
  height: '45px',
  padding: '12px 14px',
})

const EndAdornmentLabel = styled('div')({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFinishProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'title' | 'submitLabel'> {
  order: Order
  useImperials: boolean
}

const ModalFinish: React.FC<ModalFinishProps> = (props) => {
  const { order, useImperials, ...modalFormProps } = props
  const { t } = useTranslation()
  const [modal, setModal] = useState<boolean>(false)

  const [value, setValue] = useState<{
    products: Product[]
    discount?: Discount
    finishedComment?: string
    removalOrder?: File
  }>({
    products: order.products.map((product) => ({
      ...product,
      material: {
        ...product.material,
        // remove ordered quantity
        currentQty: product.material.currentQty + product.quantity,
      },
    })),
    discount: undefined,
    finishedComment: '',
    removalOrder: undefined,
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [publicMaterials, setPublicMaterials] = useState<Material[]>([])
  const [addMaterials, setAddMaterials] = useState<Material[]>([])
  const [materialModal, setMaterialModal] = useState<boolean>(false)
  useEffect(() => {
    const getMaterials = async () => {
      setLoading(true)
      try {
        const { data } = await materialsService.getMaterials({
          owned: constants.mode !== Mode.admin,
          type: MaterialType.resource,
          catalog: order.catalog._id,
        })
        setPublicMaterials(data)
      } catch (err) {}
      setLoading(false)
    }
    getMaterials()
  }, [order.catalog._id])

  useEffect(() => {
    setAddMaterials(
      publicMaterials
        .map((material) => {
          const quantity = material.quantities.find((quantity) => quantity.order === order._id)
          if (quantity) {
            return {
              ...material,
              currentQty: material.currentQty - quantity.quantity,
            }
          }
          return material
        })
        .filter(
          (material: Material) =>
            material.currentQty > 0 &&
            material.visible &&
            !value.products.find((product: Product) => product.material._id === material._id),
        ),
    )
  }, [publicMaterials, value.products, order._id])

  const errorQuantity = useMemo(
    () => value.products.reduce((acc: number, p: Product) => acc + p.quantity, 0) === 0,
    [value.products],
  )
  const hasWrongValue = useMemo(
    () =>
      value.products.reduce((hasWrongValue: boolean, product: Product) => {
        return (
          hasWrongValue ||
          (product.quantity !== 0 &&
            (product.termsOfSale === TermsOfSale.notDefined ||
              (product.termsOfSale === TermsOfSale.sale && (product.price ?? 0) <= 0) ||
              product.quantity < product.material.minQuantity ||
              product.quantity > product.material.currentQty))
        )
      }, false),
    [value.products],
  )
  const updateProduct = useCallback((product: Product) => {
    setValue((val: any) => ({
      ...val,
      products: val.products
        .map((prod: Product) => (product.material._id === prod.material._id ? product : prod))
        .filter((prod: Product) => prod.quantity > 0),
    }))
  }, [])

  const total = computeOrderTotal(value as Order)
  const totalBeforeDiscount = computeOrderBeforeDiscount(value as Order)
  const totalHT = `${total.toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
  const totalDiscountHT = `${totalBeforeDiscount.toFixed(2)}${t(
    `global:currency.${order.catalog.currency}`,
  )}`
  const totalTTC = order.tax
    ? `${(total * (1 + order.tax)).toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
    : ''
  const totalDiscountTTC = order.tax
    ? `${(totalBeforeDiscount * (1 + order.tax)).toFixed(2)}${t(
        `global:currency.${order.catalog.currency}`,
      )}`
    : ''

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.custom,
        key: 'products',
        rules: [(_: any) => (errorQuantity || hasWrongValue ? 'error' : undefined)],
        custom: (
          <>
            <OrderListProduct
              values={value.products}
              canUpdate
              finishStep
              onProductChange={updateProduct}
            />
            {errorQuantity && (
              <FormHelperText error>
                {t('orders:components.modalFinish.errorQuantity')}
              </FormHelperText>
            )}
            {addMaterials.length > 0 && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => setMaterialModal(true)}>
                {t('orders:components.modalFinish.addProduct')}
              </Button>
            )}
          </>
        ),
      },
      {
        type: ItemType.group,
        key: 'totalPricing',
        props: {
          title: t('orders:components.modalFinish.paymentTitle'),
        },
        items: [
          {
            key: 'discount',
            type: ItemType.number,
            formatValue: (discount: Discount) => {
              return discount?.value
            },
            grid: { xs: 6, md: 3 },
            props: {
              label: t('orders:attributes.discount.label'),
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {value?.discount?.type === DiscountType.amount && (
                    <Box display="flex">
                      {t(`global:currency.${order.catalog.currency}`)}
                      <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                    </Box>
                  )}
                  {value?.discount?.type === DiscountType.percentage && (
                    <Box display="flex">
                      {t('global:format.percent', { value: '' })}
                      <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                    </Box>
                  )}
                  {!value?.discount?.type && <AddIcon fontSize="small" />}
                </EndAdornmentLabel>
              ),
              onClick: (e: any) => {
                setModal(true)
                e.stopPropagation()
              },
            },
          },
          {
            type: ItemType.custom,
            key: 'totalHT',
            grid: { xs: 6, md: 3 },
            custom: (
              <>
                <InputLabel>{t('orders:attributes.totalHT')}</InputLabel>
                <StyledInputContainer>
                  {value.discount ? (
                    <>
                      <b>{totalHT}</b>
                      <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                        {totalDiscountHT}
                      </span>
                    </>
                  ) : (
                    totalHT
                  )}
                </StyledInputContainer>
              </>
            ),
          },
          ...(order.tax
            ? [
                {
                  type: ItemType.text,
                  key: 'tax',
                  grid: { xs: 6, md: 3 },
                  formatValue: () =>
                    t('global:format.percent', { value: ((order.tax ?? 0) * 100).toFixed(2) }),
                  props: {
                    label: t('orders:attributes.tax'),
                    readOnly: true,
                  },
                },
                {
                  type: ItemType.custom,
                  key: 'totalTTC',
                  grid: { xs: 6, md: 3 },
                  custom: (
                    <>
                      <InputLabel>{t('orders:attributes.totalTTC')}</InputLabel>
                      <StyledInputContainer>
                        {value.discount ? (
                          <>
                            <b>{totalTTC}</b>
                            <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                              {totalDiscountTTC}
                            </span>
                          </>
                        ) : (
                          totalTTC
                        )}
                      </StyledInputContainer>
                    </>
                  ),
                },
              ]
            : []),
        ],
      },
      {
        type: ItemType.group,
        key: 'finishedCommentGroup',
        props: {
          title: t('orders:attributes.finishedComment'),
        },
        items: [
          {
            type: ItemType.text,
            key: 'finishedComment',
            props: {
              placeholder: t('orders:attributes.finishedCommentPlaceholder'),
              minRows: 3,
              multiline: true,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'signedRemovalOrderGroup',
        props: {
          title: t('orders:attributes.signedRemovalOrder'),
        },
        items: [
          {
            type: ItemType.file,
            key: 'removalOrder',
            required: true,
            props: {
              placeholder: t('orders:attributes.signedRemovalOrder'),
            },
          },
        ],
      },
    ],
    [
      t,
      value,
      errorQuantity,
      hasWrongValue,
      addMaterials,
      totalHT,
      totalTTC,
      totalDiscountTTC,
      totalDiscountHT,
      order.catalog.currency,
      order.tax,
      updateProduct,
    ],
  )

  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <>
      <ModalForm
        maxWidth="lg"
        {...modalFormProps}
        value={value}
        formatValue={(data: any) => {
          return {
            ...data,
            products: data.products.filter((product: Product) => product.quantity > 0),
          }
        }}
        setValue={setValue}
        items={items}
        title={t('orders:actions.finish.label')}
        submitLabel={t('global:actions.confirm')}
      />
      {materialModal && (
        <Modal maxWidth="lg" onClose={() => setMaterialModal(false)}>
          <MaterialList
            showCerfa={false}
            useImperials={useImperials}
            type={MaterialType.resource}
            onValueClick={(material: Material) => {
              const convertedMaterial = useImperials ? convert2Imperials(material) : material
              setValue((val: any) => ({
                ...val,
                products: [
                  ...val.products,
                  {
                    quantity: 1,
                    material: convertedMaterial,
                    price: convertedMaterial.price,
                    termsOfSale: convertedMaterial.termsOfSale,
                  },
                ],
              }))
              setMaterialModal(false)
            }}
            getValues={() => ({
              data: addMaterials,
              count: addMaterials.length,
              total: addMaterials.length,
            })}
          />
        </Modal>
      )}
      {modal && (
        <ModalDiscount
          discount={order?.discount}
          onSubmit={(discount: any) => {
            setValue((value: any) => ({
              ...value,
              discount,
            }))
          }}
          onSuccess={() => {}}
          onClose={() => setModal(false)}
        />
      )}
    </>
  )
}

export default ModalFinish
