import { alpha } from '@mui/material'

export const createGlobalCss = (colors: any) => ({
  html: {
    height: '100%',
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
  },
  input: {
    '&:-webkit-autofill': {
      transition: 'background-color 600000s 0s, color 600000s 0s;',
    },
    '&:-webkit-autofill:focus': {
      transition: 'background-color 600000s 0s, color 600000s 0s;',
    },
  },
  body: {
    height: '100%',
    scrollbarWidth: 'thin',
  },
  // '*': {
  // webkitTouchCallout: 'none;' /* iOS Safari */,
  // webkitUserSelect: 'none;' /* Safari */,
  // khtmlUserSelect: 'none;' /* Konqueror HTML */,
  // mozUserSelect: 'none;' /* Old versions of Firefox */,
  // msUserSelect: 'none;' /* Internet Explorer/Edge */,
  // userSelect: 'none;',
  // },
  '.scrollable': {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  'div#root': {
    height: '100%',
  },
  'a:any-link': {
    textDecoration: 'none',
  },
  '*::-webkit-scrollbar': {
    height: '5px',
    width: '5px',
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: `${alpha(colors.secondary, 0.1)}`,
    borderRadius: '4px',
    margin: '15px',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: `${colors.secondary}`,
    borderRadius: '4px',
  },
})
