import { Box, CircularProgress, alpha } from '@mui/material'
const Loader: React.FC<{ size?: number }> = ({ size = 20 }) => {
  return (
    <Box position="relative" width={`${size}px`} height={`${size}px`}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            alpha(theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800], 0.5),
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        size={size}
        sx={{ position: 'absolute', left: 0, top: 0 }}
      />
    </Box>
  )
}
export default Loader
