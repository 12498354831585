import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { FormItem, ItemType } from '../../models/props.models'

interface ModalFormReportProps
  extends Omit<
    ModalFormProps,
    'value' | 'items' | 'steps' | 'setValue' | 'title' | 'submitLabel'
  > {}
const ModalFormReport: React.FC<ModalFormReportProps> = (props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<{ name: string }>({ name: '' })

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'name',
        required: true,
        props: {
          label: t('reports:attributes.name'),
          placeholder: t('reports:attributes.name'),
        },
      },
    ],
    [t],
  )
  return (
    <ModalForm
      {...props}
      value={value}
      setValue={setValue}
      items={items}
      title={t('reports:messages.reportConfirmTitle')}
      submitLabel={t('global:actions.confirm')}
    />
  )
}

export default ModalFormReport
