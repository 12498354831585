import constants from '../../constants'
import { Mode } from '../../models/commons.models'
import { NetworkUtils } from '../../utils/networks.utils'
import { sessionQuery, sessionService } from '../session'
import { ReportsApi } from '../../api/reports.api'
import { Report, ReportQueryParams, ReportResult } from '../../models/reports.model'
import { FileUtils } from '../../utils/files.utils'
import { reportsStore } from './reports.store'
import { updateRequestStatus } from '@ngneat/elf-requests'

export class ReportsService {
  store = reportsStore

  init = async (): Promise<void> => {
    let userId = sessionQuery.getUserId()
    if (!userId) {
      this.store.update((state) => ({
        ...state,
        list: [],
      }))
      return
    }
    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      await NetworkUtils.checkConnected()
      const list = await ReportsApi.getList()
      this.store.update(
        (state) => ({
          ...state,
          list: list,
        }),
        updateRequestStatus('init', 'success'),
      )
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  createReport = async (createReport: Report): Promise<Report> => {
    await NetworkUtils.checkConnected()
    const createdReport = await ReportsApi.create(createReport)
    this.store.update((state) => ({
      ...state,
      list: [createdReport, ...state.list],
    }))
    return createdReport
  }

  updateReport = async (reportId: string, updateReport: Partial<Report>): Promise<Report> => {
    await NetworkUtils.checkConnected()
    const updatedReport = await ReportsApi.update(reportId, updateReport)

    this.store.update((state) => ({
      ...state,
      list: state.list.map((report: Report) =>
        report._id === updatedReport._id ? updatedReport : report,
      ),
    }))
    return updatedReport
  }

  deleteReport = async (reportId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    await ReportsApi.deleteById(reportId)
    this.store.update((state) => ({
      ...state,
      list: state.list.filter((c) => c._id !== reportId),
    }))
  }

  computeReport = async (reportQuery?: ReportQueryParams): Promise<ReportResult> => {
    await NetworkUtils.checkConnected()
    return await ReportsApi.computeReport({
      ...reportQuery,
      ...(constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  exportXlS = async (reportQuery?: ReportQueryParams): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await ReportsApi.exportXlS({
      ...reportQuery,
      ...(constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
    await FileUtils.downloadFile(`reports.xlsx`, data)
  }
}

export const reportsService = new ReportsService()
