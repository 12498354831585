import {
  Order,
  OrderStatus,
  computeOrderTotal,
  getOrderStatusColor,
} from '../../models/orders.models'

import { Actions, ItemType } from '../../models/props.models'
import { PlatformReference, getPlatform } from '../../models/platforms.models'
import { User } from '../../models/users.models'

import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

interface OrderCardProps {
  value: Order
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
  isClient?: boolean
  isCatalogPage?: boolean
  adminPlatform?: PlatformReference
  icon?: JSX.Element
}
const OrderCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
  isClient,
  isCatalogPage,
  adminPlatform,
  icon,
}: OrderCardProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card
      ariaLabel="order-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <Box position="absolute" top="5px" left="5px">
        {icon}
      </Box>
      <DetailsComponent
        noElevation
        title={t('orders:components.card.title', { orderNumber: value.orderNumber })}
        value={value}
        items={[
          {
            label: t('orders:attributes.status'),
            key: 'status',
            type: ItemType.chip,
            props: {
              full: true,
              color: getOrderStatusColor(value?.status),
              label: t(`orders:status.${value?.status}`),
            },
          },
          ...(isClient
            ? []
            : [
                {
                  label: t('orders:attributes.client'),
                  key: 'client',
                  props: {
                    icon:
                      adminPlatform && value.client.organization.platform !== adminPlatform
                        ? getPlatform(value.client.organization.platform).icon
                        : undefined,
                  },
                  formatValue: (client: User) =>
                    `${value?.client.organization.name.toUpperCase()} : ${client.lastname} ${
                      client.firstname
                    }`,
                },
              ]),
          {
            label: t('orders:attributes.seller'),
            key: 'catalog.organization.name',
            formatValue: () =>
              value.catalog.projectOwner?.name ??
              value.catalog.customProjectOwner ??
              value.catalog.organization.name,
          },
          ...(isCatalogPage
            ? []
            : [
                {
                  label: t('orders:attributes.catalog'),
                  key: 'catalog.name',
                  props: {
                    icon:
                      value &&
                      adminPlatform &&
                      value.catalog.organization.platform !== adminPlatform
                        ? getPlatform(value.catalog.organization.platform).icon
                        : undefined,
                  },
                },
              ]),
          {
            label: t('orders:attributes.products'),
            key: 'products',
            formatValue: (products: any[]) => products?.length ?? 0,
          },
          {
            label: t('orders:attributes.createdAt'),
            key: 'createdAt',
            formatValue: (createdAt: string) =>
              t('global:format.date', {
                date: new Date(createdAt),
              }),
          },
          {
            label: t('orders:attributes.totalHT'),
            key: 'totalHT',
            formatValue: () => {
              if (value?.status === OrderStatus.pricePending) {
                return t('orders:status.pricePending')
              }
              const total = computeOrderTotal(value)
              return `${(total ?? 0).toFixed(2)}${t(`global:currency.${value.catalog.currency}`)}`
            },
          },
        ]}
      />
    </Card>
  )
}

export default OrderCard
