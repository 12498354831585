import { Catalog, isNeeds } from '../../../models/catalogs.models'
import { MaterialType } from '../../../models/materials.models'
import { useOutletContext } from 'react-router-dom'
import PageMaterialList from '../../../components/page/MaterialList.page'

const CatalogMaterialTab = () => {
  const { catalog, canUpdate, useImperials } = useOutletContext<{
    catalog: Catalog
    canUpdate: boolean
    useImperials: boolean
  }>()

  return (
    <PageMaterialList
      type={isNeeds(catalog.type) ? MaterialType.need : MaterialType.resource}
      useImperials={useImperials}
      canUpdate={canUpdate}
      catalog={catalog}
    />
  )
}
export default CatalogMaterialTab
