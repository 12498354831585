import Stack from './Stack.common'
import { styled, Typography } from '@mui/material'

const Title = styled(Typography)({ fontSize: '1.875rem', fontWeight: 800, marginBottom: '20px' })

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.375rem',
  fontWeight: 800,
}))

const SectionTextContainer = styled(Typography)({
  fontSize: '0.938rem',
  fontWeight: 500,
  whiteSpace: 'pre-line',
})

type ContentsProps = {
  title: string
  intro?: string
  paragraphs?: { title?: string; content: string }[]
}
const Contents: React.FC<ContentsProps> = ({ title, intro, paragraphs }) => {
  return (
    <Stack alignItems="center" py="70px" position="relative">
      <Title>{title}</Title>
      <Stack spacing="50px" width="100%" maxWidth="900px">
        {intro && (
          <Stack spacing="15px">
            <SectionTextContainer>{intro}</SectionTextContainer>
          </Stack>
        )}
        {paragraphs &&
          paragraphs.map((paragrah, paragraphIndex: number) => {
            return (
              <Stack spacing="15px" key={paragraphIndex}>
                {paragrah.title && (
                  <SectionTitle>{`${paragraphIndex + 1} ${paragrah.title}`}</SectionTitle>
                )}
                <SectionTextContainer>{paragrah.content}</SectionTextContainer>
              </Stack>
            )
          })}
      </Stack>
    </Stack>
  )
}
export default Contents
