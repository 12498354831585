import { useTranslation } from 'react-i18next'
import Stack from '../common/Stack.common'
import { Box, Button, Typography, styled } from '@mui/material'
import useModal from '../../hooks/useModal.hooks'
import FormLogin from '../session/FormLogin.session'
import FormSignup from '../session/FormSignup.session'

const DescriptionLabel = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.25,
  marginBottom: '30px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
})

const OrLabel = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  marginTop: '25px',
  textAlign: 'center',
})

const SignupButton = styled(Button)({ textDecoration: 'underline' })

interface MaterialNeedLoginProps {
  detail: boolean
}
const MaterialNeedLogin: React.FC<MaterialNeedLoginProps> = ({ detail }) => {
  const { t } = useTranslation()

  const [modal, setModal] = useModal<'login' | 'signup'>()

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <DescriptionLabel>
        {t(`materials:components.needLogin.${detail ? 'detail' : 'list'}`)}
      </DescriptionLabel>
      <Box width="190px">
        <Button variant="contained" color="primary" fullWidth onClick={() => setModal('login')}>
          {t('sessions:actions.login')}
        </Button>
      </Box>
      <OrLabel> {t('materials:components.needLogin.or')}</OrLabel>
      <SignupButton variant="text" color="primary" onClick={() => setModal('signup')}>
        {t('sessions:actions.signup')}
      </SignupButton>
      {modal === 'login' && <FormLogin onClose={() => setModal('')} />}
      {modal === 'signup' && <FormSignup onClose={() => setModal('')} />}
    </Stack>
  )
}

export default MaterialNeedLogin
