import { reportsStore } from './reports.store'
import { selectIsRequestPending } from '@ngneat/elf-requests'
import { select } from '@ngneat/elf'

export class ReportQuery {
  store = reportsStore
  list = this.store.pipe(select((state) => state.list))
  loading = this.store.pipe(selectIsRequestPending('init'))
}

export const reportQuery = new ReportQuery()
