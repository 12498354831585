import { useOutletContext } from 'react-router-dom'
import { Organization } from '../../../models/organizations.models'
import PageOrderList from '../../../components/page/OrderList.page'

const OrganizationSoldOrdersTab = () => {
  const { organization, useImperials } = useOutletContext<{
    organization: Organization
    useImperials: boolean
  }>()

  return <PageOrderList useImperials={useImperials} withTabs seller={organization._id} />
}
export default OrganizationSoldOrdersTab
