import React, { useEffect, useMemo, useState } from 'react'
import {
  useNavigate,
  useLocation,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'
import { useTranslation } from 'react-i18next'
import { App } from '@capacitor/app'

import { styled } from '@mui/material'
import { Mode, Route as RouteEnum } from '../../models/commons.models'
import { getPlatform } from '../../models/platforms.models'
import Constants from '../../constants'

import useRoute from '../../hooks/useRoute.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'

import { sessionQuery, sessionService } from '../../store/session'
import { materialModelsService } from '../../store/materialModels'
import { categoriesQuery, categoriesService } from '../../store/categories'
import { catalogsService } from '../../store/catalogs'
import { cartsService } from '../../store/carts'

import LoaderOverlay from './LoaderOverlay.layout'
import AppHeader from './AppHeader.layout'
import AdminHeader from './AdminHeader.layout'
import FrontHeader from './FrontHeader.layout'
import StoreFrontHeader from './StoreFrontHeader.layout'
import Footer from './Footer.layout'

import WorkspacePage from '../../pages/workspace'
import WorkspaceOrdersPage from '../../pages/workspace/Orders.workspace'
import WorkspaceDeconstructionsPage from '../../pages/workspace/Deconstructions.workspace'
import WorkspaceConstructionsPage from '../../pages/workspace/Constructions.workspace'
import WorkspaceStoragesPage from '../../pages/workspace/Storages.workspace'
import WorkspaceNeedsPage from '../../pages/workspace/Needs.workspace'
import WorkspaceReportPage from '../../pages/workspace/Report.workspace'
import WorkspaceCatalogPage from '../../pages/workspace/catalog'
import WorkspaceCatalogPlanPage from '../../pages/workspace/catalog/Plan.catalog'
import WorkspaceCatalogInformationsPage from '../../pages/workspace/catalog/Informations.catalog'
import WorkspaceCatalogPlansPage from '../../pages/workspace/catalog/Plans.catalog'
import WorkspaceCatalogMaterialsPage from '../../pages/workspace/catalog/Materials.catalog'
import WorkspaceCatalogOrdersPage from '../../pages/workspace/catalog/Orders.catalog'
import WorkspaceCatalogMatchingPage from '../../pages/workspace/catalog/Matching.catalog'
import WorkspaceCatalogChatPage from '../../pages/workspace/catalog/Chat.catalog'
import WorkspaceCatalogReportPage from '../../pages/workspace/catalog/Report.catalog'

import OrganizationListPage from '../../pages/organization/List.organization'
import OrganizationDetailsPage from '../../pages/organization/details'
import DetailsOrganizationInformationsPage from '../../pages/organization/details/OrganizationInformations.details'
import DetailsOrganizationUsersPage from '../../pages/organization/details/OrganizationUsers.details'
import DetailsOrganizationSoldOrdersPage from '../../pages/organization/details/OrganizationSoldOrders.details'
import DetailsOrganizationBoughtOrdersPage from '../../pages/organization/details/OrganizationBoughtOrders.details'
import DetailsOrganizationResourcesPage from '../../pages/organization/details/OrganizationResources.details'
import DetailsOrganizationNeedsPage from '../../pages/organization/details/OrganizationNeeds.details'
import DetailsOrganizationCatalogsPage from '../../pages/organization/details/OrganizationCatalogs.details'
import DetailsOrganizationReportPage from '../../pages/organization/details/OrganizationReport.details'

import UserListPage from '../../pages/user/List.user'
import UserDetailsPage from '../../pages/user/Details.user'

import RequestListPage from '../../pages/request/list'
import ReceivedRequestListPage from '../../pages/request/list/RequestReceived.list'
import SentRequestListPage from '../../pages/request/list/RequestSent.list'

import ChatListPage from '../../pages/chat/list'
import ReceivedChatListPage from '../../pages/chat/list/ChatReceived.list'
import SentChatListPage from '../../pages/chat/list/ChatSent.list'

import MaterialListPage from '../../pages/material/list'
import ListMaterialNeedPage from '../../pages/material/list/MaterialNeed.list'
import ListMaterialResourcePage from '../../pages/material/list/MaterialResource.list'

import MaterialModelListPage from '../../pages/materialModel/List.materialModel'

import AdminOrdersPage from '../../pages/order/AdminOrders.order'
import MyOrdersPage from '../../pages/order/MyOrders.order'

import PublicMaterialListPage from '../../pages/publicMaterial/list'
import PublicMaterialNeedsListPage from '../../pages/publicMaterial/list/MaterialNeeds.list'
import PublicMaterialResourcesListPage from '../../pages/publicMaterial/list/MaterialResources.list'

import PublicCatalogListPage from '../../pages/publicCatalog/list'
import PublicCatalogResourcesListPage from '../../pages/publicCatalog/list/CatalogResources.list'
import PublicCatalogNeedsListPage from '../../pages/publicCatalog/list/CatalogNeeds.list'

import PublicCatalogDetailsPage from '../../pages/publicCatalog/details'
import DetailsPublicCatalogInformationsPage from '../../pages/publicCatalog/details/PublicCatalogInformations.details'
import DetailsPublicCatalogMaterialsPage from '../../pages/publicCatalog/details/PublicCatalogMaterials.details'

import ReportPage from '../../pages/Report.page'
import LoginPage from '../../pages/Login.page'
import SignupPage from '../../pages/Signup.page'
import AccountPage from '../../pages/Account.page'
import CartPage from '../../pages/Cart.page'
import CguPage from '../../pages/Cgu.page'
import NewsPage from '../../pages/News.page'
import HomePage from '../../pages/Home.page'
import FavoritePage from '../../pages/Favorite.page'
import RgpdPage from '../../pages/Rgpd.page'
import { reportsService } from '../../store/reports/reports.service'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

const Main = styled('main')({
  marginBottom: 'auto',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
})

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

const RoutesLayout = ({ children, additionals }: any): JSX.Element => {
  const show = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { getRoutePath, isAtRoute, goTo } = useRoute()

  const fullPage = isAtRoute(RouteEnum.workspaceCatalogPlan)

  const [user] = useObservable(sessionQuery.user)

  const [checkAppVersion, setCheckAppVersion] = useState<boolean>(Constants.mode === Mode.app)
  const [sessionLoading] = useObservable(sessionQuery.loading)
  const [categoriesLoading] = useObservable(categoriesQuery.loading)
  const isLogged = !!user
  const loading = sessionLoading || categoriesLoading || checkAppVersion

  const platform = useMemo(() => {
    if (user && Constants.mode === Mode.admin) {
      return getPlatform(sessionService.getAdminPlatform())
    }
    return null
  }, [user])

  const showPublicNeeds = useMemo(() => sessionService.showPublicNeeds(), [])

  const location = useLocation()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    if (platform && platform.redirect) {
      const route = platform.redirect(location.pathname)
      if (route) {
        goTo(route)
      }
    }
  }, [platform, location, goTo])

  useEffect(() => {
    let handleBackButton = () => {
      if (searchParams.get('modal')) {
        navigate(location.pathname, { replace: true })
      } else if (isAtRoute(RouteEnum.workspace) || isAtRoute(RouteEnum.login)) {
        App.exitApp()
      } else {
        navigate(-1)
      }
    }
    if (Constants.mode === Mode.app) {
      document.addEventListener('backbutton', handleBackButton)
    }
    return () => {
      if (Constants.mode === Mode.app) {
        document.removeEventListener('backbutton', handleBackButton)
      }
    }
  }, [navigate, isAtRoute, location.pathname, searchParams])
  useEffect(() => {
    if (Constants.mode === Mode.app) {
      const check = async () => {
        try {
          await sessionService.checkAppVersion()
        } catch (err: any) {
          show(err)
        }
        setCheckAppVersion(false)
      }
      check()
    }
  }, [show])

  useEffect(() => {
    let description: any = document.querySelector('meta[name="description"]')
    let icon: any = document.querySelector("link[rel~='icon']")
    let appleTouchIcon: any = document.querySelector("link[rel~='apple-touch-icon']")
    let manifest: any = document.querySelector("link[rel~='manifest']")

    if (!description) {
      description = document.createElement('meta')
      description.name = 'description'
      document.getElementsByTagName('head')[0].appendChild(description)
    }
    if (!icon) {
      icon = document.createElement('link')
      icon.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(icon)
    }
    if (!appleTouchIcon) {
      appleTouchIcon = document.createElement('link')
      appleTouchIcon.rel = 'apple-touch-icon'
      document.getElementsByTagName('head')[0].appendChild(appleTouchIcon)
    }
    if (!manifest) {
      manifest = document.createElement('link')
      manifest.rel = 'manifest'
      document.getElementsByTagName('head')[0].appendChild(manifest)
    }
    document.title = t('global:meta.title')
    description.content = t('global:meta.description')
    icon.href = Constants.meta.icon
    appleTouchIcon.href = Constants.meta.appleTouchIcon
    manifest.href = Constants.meta.manifest

    const init = async () => {
      try {
        await sessionService.init()
        await categoriesService.init()
        if (Constants.mode === Mode.front || Constants.mode === Mode.storeFront) {
          await cartsService.init()
        }
      } catch (err: any) {
        show(err)
      }
    }
    init()
  }, [show, t])

  useEffect(() => {
    const initLogged = async () => {
      try {
        if (Constants.mode !== Mode.storeFront) {
          await materialModelsService.init()
          await reportsService.init()
          if (Constants.mode !== Mode.admin) {
            await catalogsService.init()
          }
        }
      } catch (err: any) {
        show(err)
      }
    }
    initLogged()
  }, [isLogged, show])

  if (loading) {
    return <LoaderOverlay />
  }

  const unloggedRedirect =
    Constants.mode !== Mode.front && Constants.mode !== Mode.storeFront
      ? RouteEnum.login
      : RouteEnum.home
  const loggedRedirect =
    Constants.mode !== Mode.front && Constants.mode !== Mode.storeFront
      ? RouteEnum.workspace
      : RouteEnum.home

  const pages: PartialRecord<RouteEnum, false | React.FC<{ children?: JSX.Element }>> =
    Constants.mode === Mode.app
      ? {
          [RouteEnum.adminOrders]: false,
          [RouteEnum.myOrders]: false,
          [RouteEnum.users]: false,
          [RouteEnum.user]: false,
          [RouteEnum.cgu]: false,
          [RouteEnum.news]: false,
          [RouteEnum.rgpd]: false,
          [RouteEnum.organizations]: false,
          [RouteEnum.organization]: false,
          [RouteEnum.materialModels]: false,
          [RouteEnum.materials]: false,
          [RouteEnum.home]: false,
          [RouteEnum.favorites]: false,
          [RouteEnum.cart]: false,
          [RouteEnum.publicMaterials]: false,
          [RouteEnum.publicCatalogs]: false,
          [RouteEnum.requests]: false,
          [RouteEnum.report]: false,
        }
      : Constants.mode === Mode.admin
      ? {
          [RouteEnum.myOrders]: false,
          [RouteEnum.home]: false,
          [RouteEnum.account]: false,
          [RouteEnum.cgu]: false,
          [RouteEnum.news]: false,
          [RouteEnum.rgpd]: false,
          [RouteEnum.favorites]: false,
          [RouteEnum.cart]: false,
          [RouteEnum.signup]: false,
          [RouteEnum.publicMaterials]: false,
          [RouteEnum.publicCatalogs]: false,
          [RouteEnum.workspaceReport]: false,
        }
      : Constants.mode === Mode.storeFront
      ? {
          [RouteEnum.adminOrders]: false,
          [RouteEnum.users]: false,
          [RouteEnum.user]: false,
          [RouteEnum.organizations]: false,
          [RouteEnum.organization]: false,
          [RouteEnum.materialModels]: false,
          [RouteEnum.materials]: false,
          [RouteEnum.workspace]: false,
          [RouteEnum.login]: false,
          [RouteEnum.signup]: false,
          [RouteEnum.requests]: false,
          [RouteEnum.report]: false,
          [RouteEnum.workspaceReport]: false,
          [RouteEnum.workspaceCatalogReport]: false,
        }
      : {
          [RouteEnum.adminOrders]: false,
          [RouteEnum.users]: false,
          [RouteEnum.user]: false,
          [RouteEnum.organizations]: false,
          [RouteEnum.organization]: false,
          [RouteEnum.materialModels]: false,
          [RouteEnum.materials]: false,
          [RouteEnum.login]: false,
          [RouteEnum.signup]: false,
          [RouteEnum.report]: false,
        }

  type getRouteProps = {
    route: RouteEnum
    default?: React.FC<{ children?: JSX.Element }>
    index?: boolean
    redirectTo?: RouteEnum
    children?: JSX.Element | JSX.Element[]
  }
  const getRoute: (props: getRouteProps) => JSX.Element = ({
    route,
    default: DefaultRoute,
    children,
    redirectTo,
    index,
  }): JSX.Element => {
    if (!!pages && pages[route] === false) {
      return <></>
    }
    let RouteElement =
      !!pages && !!pages[route]
        ? (pages[route] as React.FC<{ children?: JSX.Element }>)
        : DefaultRoute

    return (
      <Route
        path={getRoutePath(route)}
        element={!index && RouteElement ? <RouteElement /> : undefined}>
        {children}
        {index && !redirectTo && RouteElement && <Route index element={<RouteElement />} />}
        {redirectTo && <Route index element={<Navigate to={getRoutePath(redirectTo)} replace />} />}
      </Route>
    )
  }

  return (
    <Container>
      {!fullPage && Constants.mode === Mode.app && <AppHeader additionals={additionals} />}
      {!fullPage && Constants.mode === Mode.admin && <AdminHeader additionals={additionals} />}
      {!fullPage && Constants.mode === Mode.front && <FrontHeader additionals={additionals} />}
      {!fullPage && Constants.mode === Mode.storeFront && (
        <StoreFrontHeader additionals={additionals} />
      )}
      <Main
        sx={{
          minHeight:
            !fullPage && (Constants.mode === Mode.front || Constants.mode === Mode.storeFront)
              ? 'calc(100vh - 80px)'
              : 'none',
        }}>
        <Routes>
          {children ?? <></>}
          {getRoute({ route: RouteEnum.home, default: HomePage })}
          {getRoute({ route: RouteEnum.news, default: NewsPage })}
          {getRoute({ route: RouteEnum.cgu, default: CguPage })}
          {getRoute({ route: RouteEnum.rgpd, default: RgpdPage })}
          {getRoute({ route: RouteEnum.cart, default: CartPage })}
          {!isLogged && (
            <>
              {getRoute({ route: RouteEnum.login, default: LoginPage })}
              {getRoute({ route: RouteEnum.signup, default: SignupPage })}
            </>
          )}

          {getRoute({
            index: !showPublicNeeds,
            route: RouteEnum.publicMaterials,
            default: PublicMaterialListPage,
            redirectTo: showPublicNeeds ? RouteEnum.publicResources : undefined,
            children: showPublicNeeds ? (
              <>
                {getRoute({
                  route: RouteEnum.publicResources,
                  default: PublicMaterialResourcesListPage,
                })}
                {getRoute({
                  route: RouteEnum.publicNeeds,
                  default: PublicMaterialNeedsListPage,
                })}
              </>
            ) : (
              <Route
                path="*"
                element={<Navigate to={getRoutePath(RouteEnum.publicMaterials)} replace />}
              />
            ),
          })}

          {getRoute({
            index: !showPublicNeeds,
            route: RouteEnum.publicCatalogs,
            default: PublicCatalogListPage,
            redirectTo: showPublicNeeds ? RouteEnum.publicCatalogsResources : undefined,
            children: showPublicNeeds ? (
              <>
                {getRoute({
                  route: RouteEnum.publicCatalogsResources,
                  default: PublicCatalogResourcesListPage,
                })}
                {getRoute({
                  route: RouteEnum.publicCatalogsNeeds,
                  default: PublicCatalogNeedsListPage,
                })}
              </>
            ) : (
              <Route
                path="*"
                element={<Navigate to={getRoutePath(RouteEnum.publicCatalogs)} replace />}
              />
            ),
          })}
          {getRoute({
            index: true,
            route: RouteEnum.publicCatalogs,
            children: getRoute({
              route: RouteEnum.publicCatalog,
              default: PublicCatalogDetailsPage,
              redirectTo: RouteEnum.publicCatalogMaterials,
              children: (
                <>
                  {getRoute({
                    route: RouteEnum.publicCatalogInformations,
                    default: DetailsPublicCatalogInformationsPage,
                  })}
                  {getRoute({
                    route: RouteEnum.publicCatalogMaterials,
                    default: DetailsPublicCatalogMaterialsPage,
                  })}
                </>
              ),
            }),
          })}

          {isLogged && (
            <>
              {getRoute({ route: RouteEnum.report, default: ReportPage })}

              {getRoute({ route: RouteEnum.favorites, default: FavoritePage })}
              {getRoute({
                index: true,
                route: RouteEnum.organizations,
                default: OrganizationListPage,
                children: getRoute({
                  route: RouteEnum.organization,
                  default: OrganizationDetailsPage,
                  redirectTo: RouteEnum.organizationInformations,
                  children: (
                    <>
                      {getRoute({
                        route: RouteEnum.organizationInformations,
                        default: DetailsOrganizationInformationsPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationUsers,
                        default: DetailsOrganizationUsersPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationSoldOrders,
                        default: DetailsOrganizationSoldOrdersPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationBoughtOrders,
                        default: DetailsOrganizationBoughtOrdersPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationResources,
                        default: DetailsOrganizationResourcesPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationNeeds,
                        default: DetailsOrganizationNeedsPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationCatalogs,
                        default: DetailsOrganizationCatalogsPage,
                      })}
                      {getRoute({
                        route: RouteEnum.organizationReport,
                        default: DetailsOrganizationReportPage,
                      })}
                    </>
                  ),
                }),
              })}
              {getRoute({
                index: true,
                route: RouteEnum.users,
                default: UserListPage,
                children: getRoute({ route: RouteEnum.user, default: UserDetailsPage }),
              })}
              {getRoute({ route: RouteEnum.materialModels, default: MaterialModelListPage })}
              {getRoute({ route: RouteEnum.account, default: AccountPage })}

              {getRoute({
                index: Constants.mode === Mode.admin,
                route: RouteEnum.requests,
                default: RequestListPage,
                redirectTo: Constants.mode !== Mode.admin ? RouteEnum.receivedRequests : undefined,
                children:
                  Constants.mode !== Mode.admin ? (
                    <>
                      {getRoute({
                        route: RouteEnum.receivedRequests,
                        default: ReceivedRequestListPage,
                      })}
                      {getRoute({
                        route: RouteEnum.sentRequests,
                        default: SentRequestListPage,
                      })}
                    </>
                  ) : (
                    <Route
                      path="*"
                      element={<Navigate to={getRoutePath(RouteEnum.requests)} replace />}
                    />
                  ),
              })}

              {getRoute({
                index: Constants.mode === Mode.admin,
                route: RouteEnum.chats,
                default: ChatListPage,
                redirectTo: Constants.mode !== Mode.admin ? RouteEnum.receivedChats : undefined,
                children:
                  Constants.mode !== Mode.admin ? (
                    <>
                      {getRoute({
                        route: RouteEnum.receivedChats,
                        default: ReceivedChatListPage,
                      })}
                      {getRoute({
                        route: RouteEnum.sentChats,
                        default: SentChatListPage,
                      })}
                    </>
                  ) : (
                    <Route
                      path="*"
                      element={<Navigate to={getRoutePath(RouteEnum.chats)} replace />}
                    />
                  ),
              })}

              {getRoute({
                route: RouteEnum.materials,
                default: MaterialListPage,
                redirectTo: RouteEnum.materialsResources,
                children: (
                  <>
                    {getRoute({
                      route: RouteEnum.materialsResources,
                      default: ListMaterialResourcePage,
                    })}
                    {getRoute({
                      route: RouteEnum.materialsNeeds,
                      default: ListMaterialNeedPage,
                    })}
                    <Route
                      path="*"
                      element={<Navigate to={getRoutePath(RouteEnum.materialsResources)} />}
                    />
                  </>
                ),
              })}

              {getRoute({ route: RouteEnum.adminOrders, default: AdminOrdersPage })}
              {getRoute({ route: RouteEnum.myOrders, default: MyOrdersPage })}

              {getRoute({
                index: true,
                route: RouteEnum.workspace,
                children: (
                  <>
                    {getRoute({
                      route: RouteEnum.workspaceCatalogPlan,
                      default: WorkspaceCatalogPlanPage,
                    })}

                    <Route element={<WorkspacePage />}>
                      <Route
                        path={getRoutePath(RouteEnum.workspaceDeconstructions)}
                        element={<WorkspaceDeconstructionsPage />}
                      />
                      <Route
                        path={getRoutePath(RouteEnum.workspaceStorages)}
                        element={<WorkspaceStoragesPage />}
                      />
                      {Constants.mode !== Mode.admin && (
                        <>
                          <Route
                            path={getRoutePath(RouteEnum.workspaceOrders)}
                            element={<WorkspaceOrdersPage />}
                          />
                          <Route
                            path={getRoutePath(RouteEnum.workspaceReport)}
                            element={<WorkspaceReportPage />}
                          />
                        </>
                      )}
                      {Constants.mode !== Mode.app && (
                        <>
                          <Route
                            path={getRoutePath(RouteEnum.workspaceConstructions)}
                            element={<WorkspaceConstructionsPage />}
                          />
                          <Route
                            path={getRoutePath(RouteEnum.workspaceNeeds)}
                            element={<WorkspaceNeedsPage />}
                          />
                        </>
                      )}
                      <Route
                        index
                        element={<Navigate to={getRoutePath(RouteEnum.workspaceDeconstructions)} />}
                      />
                    </Route>

                    {getRoute({
                      route: RouteEnum.workspaceCatalog,
                      default: WorkspaceCatalogPage,
                      redirectTo: RouteEnum.workspaceCatalogInformations,
                      children: (
                        <>
                          {getRoute({
                            route: RouteEnum.workspaceCatalogInformations,
                            default: WorkspaceCatalogInformationsPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogPlans,
                            default: WorkspaceCatalogPlansPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogMaterials,
                            default: WorkspaceCatalogMaterialsPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogOrders,
                            default: WorkspaceCatalogOrdersPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogMatching,
                            default: WorkspaceCatalogMatchingPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogChat,
                            default: WorkspaceCatalogChatPage,
                          })}
                          {getRoute({
                            route: RouteEnum.workspaceCatalogReport,
                            default: WorkspaceCatalogReportPage,
                          })}
                        </>
                      ),
                    })}
                  </>
                ),
              })}
            </>
          )}

          <Route
            path="*"
            element={
              <Navigate to={getRoutePath(isLogged ? loggedRedirect : unloggedRedirect)} replace />
            }
          />
        </Routes>
      </Main>
      {!fullPage && (Constants.mode === Mode.front || Constants.mode === Mode.storeFront) && (
        <Footer />
      )}
    </Container>
  )
}
export default RoutesLayout
