import { CatalogType } from '../../models/catalogs.models'

import PageCatalogList from '../../components/page/CatalogList.page'
import { FilterableRoute } from '../../models/commons.models'
const WorkspaceStoragesPage = () => {
  return (
    <PageCatalogList
      withTabs
      withGroups
      types={[CatalogType.storage]}
      filterKey={FilterableRoute.workspaceStorages}
    />
  )
}
export default WorkspaceStoragesPage
