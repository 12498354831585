import { Mode, Route } from '../../../models/commons.models'
import PageChatList from '../../../components/page/ChatList.page'

import TabsComponent from '../../../components/common/Tabs.common'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import constants from '../../../constants'
import { AdminHeaderTitle } from '../../../components/layout/AdminHeader.layout'
import { FilterableRoute } from '../../../models/commons.models'

const ChatList = () => {
  useCheckRoute('ChatList', [Mode.admin, Mode.front])
  const { getPath } = useRoute()
  const { t } = useTranslation()

  if (constants.mode !== Mode.admin) {
    return (
      <>
        <TabsComponent
          items={[
            {
              label: t(`global:routes.receivedChats`),
              path: getPath({
                route: Route.receivedChats,
              }),
            },
            {
              label: t(`global:routes.sentChats`),
              path: getPath({
                route: Route.sentChats,
              }),
            },
          ]}
        />
        <Outlet />
      </>
    )
  }

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.chats')} />
      <PageChatList filterKey={FilterableRoute.chats} from="admin" />
    </>
  )
}
export default ChatList
