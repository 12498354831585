import { Mode, Route } from '../../../models/commons.models'
import { MaterialType } from '../../../models/materials.models'
import PageMaterialList from '../../../components/page/MaterialList.page'
import TabsComponent from '../../../components/common/Tabs.common'
import { sessionQuery, sessionService } from '../../../store/session'
import { useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import { useObservable } from '@ngneat/react-rxjs'

const PublicMaterialList = () => {
  useCheckRoute('PublicMaterialList', [Mode.front, Mode.storeFront])
  const { getPath } = useRoute()
  const { t } = useTranslation()
  const showPublicNeeds = useRef(sessionService.showPublicNeeds())
  const [useImperials] = useObservable(sessionQuery.useImperials)

  if (showPublicNeeds.current) {
    return (
      <>
        <TabsComponent
          items={[
            {
              label: t(`global:routes.publicResources`),
              path: getPath({
                route: Route.publicResources,
              }),
            },
            {
              label: t(`global:routes.publicNeeds`),
              secondary: true,
              path: getPath({
                route: Route.publicNeeds,
              }),
            },
          ]}
        />
        <Outlet context={{ useImperials }} />
      </>
    )
  }

  return <PageMaterialList useImperials={useImperials} isPublic type={MaterialType.resource} />
}
export default PublicMaterialList
