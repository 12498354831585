import { Network } from '@capacitor/network'

export namespace NetworkUtils {
  export const checkConnected = <T>(callback?: () => any) => {
    return new Promise<T>(async (resolve, reject) => {
      try {
        let connected = false
        try {
          const network = await Network.getStatus()
          connected = network.connected
        } catch (err) {}

        if (connected) {
          if (callback) {
            resolve(await callback())
          } else {
            resolve(true as any)
          }
        } else {
          const error = new Error('NOT_CONNECTED')
          ;(error as any).response = {
            data: { message: 'NOT_CONNECTED' },
          }
          throw error
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  export const isConnected = async (): Promise<boolean> => {
    let connected = false
    try {
      const network = await Network.getStatus()
      connected = network.connected
    } catch (err) {}
    return connected
  }
}
