import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import PageChatList from '../../../components/page/ChatList.page'
import { MessageFrom } from '../../../models/chats.models'

const ChatReceivedList = () => {
  useCheckRoute('ChatReceivedList', [Mode.front])

  return <PageChatList from={MessageFrom.seller} withTabs />
}
export default ChatReceivedList
