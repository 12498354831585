import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import PageChatList from '../../../components/page/ChatList.page'
import { MessageFrom } from '../../../models/chats.models'

const ChatSentList = () => {
  useCheckRoute('ChatSentList', [Mode.front])

  return <PageChatList from={MessageFrom.client} withTabs />
}
export default ChatSentList
