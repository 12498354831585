import { AxiosResponse } from 'axios'
import { axiosApi } from './base.api'
import { ApiUser, User } from '../models/users.models'
import {
  SignupInterfaceApi,
  LoginInterface,
  AuthenticationResponse,
  ContactUsData,
  CountryParams,
} from '../models/sessions.models'
import { PlatformReference } from '../models/platforms.models'

import { logIfDev } from '../utils/commons.utils'
import i18n from '../utils/i18n.utils'
import Constants from '../constants'

export namespace SessionsApi {
  export const login = async (login: LoginInterface): Promise<AuthenticationResponse> => {
    const response: AxiosResponse<AuthenticationResponse> = await axiosApi.post(
      `/auth/login`,
      login,
    )
    return response.data
  }
  export const getMe = async (): Promise<User> => {
    const response: AxiosResponse<User> = await axiosApi.get(`/users/me`)
    return response.data
  }
  export const updateMe = async (userId: string, user: Partial<ApiUser>): Promise<User> => {
    const response: AxiosResponse<User> = await axiosApi.patch(`/users/${userId}`, user)
    return response.data
  }
  export const updateFilter = async (userId: string, key: string, filter: any): Promise<any> => {
    const response: AxiosResponse<User> = await axiosApi.patch(`/users/${userId}/filter`, {
      key,
      filter,
    })
    return response.data
  }
  export const signup = async (user: SignupInterfaceApi): Promise<void> => {
    await axiosApi.post(`/auth/register`, user)
  }
  export const forgotPassword = async (
    email: string,
    platform?: PlatformReference,
  ): Promise<void> => {
    await axiosApi.get(
      `/auth/forgotten-password/${email}?lang=${i18n.language}${
        platform ? `&platform=${platform}` : ''
      }`,
    )
  }
  export const removeMe = async (userId: string): Promise<void> => {
    await axiosApi.delete(`/users/${userId}`)
  }

  export const contactUs = async (contactUsData: ContactUsData): Promise<void> => {
    await axiosApi.post(`/contact-us`, contactUsData)
  }

  export const getCountry = async (countryCode: string): Promise<CountryParams> => {
    const { data } = await axiosApi.get(`/countries/${countryCode}`)
    return data
  }

  export const getCurrentCountry = async (user?: User): Promise<CountryParams> => {
    if (Constants.specificCountry) {
      return await getCountry(Constants.specificCountry)
    }

    try {
      if (!!user) {
        const countryData = await getCountry(user.organization.location.countryCode)
        return { ...countryData, coordinates: user.organization.location.position }
      }
    } catch (err) {
      logIfDev(err)
    }
    try {
      const { data } = await axiosApi.post(
        `https://ipapi.co/json/`,
        {},
        {
          transformRequest: (data: any, headers: any) => {
            if (headers.common) {
              delete headers.common['Authorization']
              delete headers.common['platform']
            }
            delete headers['Authorization']
            delete headers['platform']
            return JSON.stringify(data)
          },
        },
      )
      const countryData = await getCountry(data.country_code)
      return { ...countryData, coordinates: [data.longitude, data.latitude] }
    } catch (err) {
      logIfDev(err)
    }
    return await axiosApi.get(`/countries/fr`)
  }
}
