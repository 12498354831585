import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'

import {
  ApiRequest,
  Request,
  RequestsPagination,
  RequestStatus,
  RequestType,
} from '../models/requests.models'
import { PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

export namespace RequestsApi {
  export interface GetListParams extends PaginationParams {
    types?: RequestType[]
    status?: RequestStatus[]
    platform?: PlatformReference
    received?: boolean
    sent?: boolean
    catalog?: string
    user?: string
  }
  export const getList = async (params?: GetListParams): Promise<RequestsPagination> => {
    const response: AxiosResponse<RequestsPagination> = await axiosApi.get(`/requests`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        status: params?.status?.length ? params.status : undefined,
        types: params?.types?.length ? params.types : undefined,
      },
    })
    return response.data
  }
  export const getById = async (requestId: string): Promise<Request> => {
    const response: AxiosResponse<Request> = await axiosApi.get(`/requests/${requestId}`)
    return response.data
  }
  export const create = async (createdRequest: ApiRequest): Promise<Request> => {
    const response: AxiosResponse<Request> = await axiosApi.post(`/requests`, createdRequest)
    return response.data
  }
  export const update = async (
    requestId: string,
    updatedRequest: { status: RequestStatus; deniedFor?: string },
  ): Promise<Request> => {
    const response: AxiosResponse<Request> = await axiosApi.patch(
      `/requests/${requestId}`,
      updatedRequest,
    )
    return response.data
  }
}
