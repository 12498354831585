import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Catalog } from '../../../models/catalogs.models'
import Double from '../../../components/layout/Double.layout'
import InformationCatalog from '../../../components/catalog/Information.catalog'
import InformationDocumentCatalog from '../../../components/catalog/InformationDocument.catalog'

const PublicCatalogInformationsTabs: React.FC = () => {
  const { catalog, useImperials } = useOutletContext<{
    catalog: Catalog
    useImperials: boolean
  }>()
  const { t } = useTranslation()

  return (
    <Double
      first={
        <InformationCatalog
          isPublic
          title={t('catalogs:pages.publicInformations.informations')}
          catalog={catalog}
          useImperials={useImperials}
        />
      }
      second={<InformationDocumentCatalog catalog={catalog} isPublic />}
    />
  )
}
export default PublicCatalogInformationsTabs
