import { Box, Button, Typography, alpha, styled, useMediaQuery, useTheme } from '@mui/material'
import {
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Rectangle,
} from 'recharts'
import Stack from './Stack.common'
import { useCallback, useMemo } from 'react'
import { DEFAULT_COLORS } from '../../constants/colors.constants'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { Info as InfoIcon } from '@mui/icons-material'
import OwnTooltip from './Tooltip.common'

const StatStyled = styled(Stack, { shouldForwardProp: (props) => props !== 'numberStatOnly' })<{
  numberStatOnly: boolean
}>(({ theme, numberStatOnly }) => ({
  backgroundColor: theme.palette.white,
  border: `1px solid ${theme.palette.blockBorder}`,
  boxShadow: `0px 2px 20px ${alpha(theme.palette.black, 0.05)}`,
  height: '100%',
  padding: '20px',
  paddingBottom: numberStatOnly ? '40px' : undefined,
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  '& .recharts-tooltip-wrapper ': {
    zIndex: '100 !important',
  },
  '& .recharts-legend-item': {
    fontSize: '14px',
    '& .recharts-surface': {
      width: '6px',
    },
  },
}))

const NumberStyled = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '40px',
  fontWeight: 900,
  lineHeight: '1.10',
}))

const NumberSubtitleStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.blockLabel,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 400,
}))

const TitleIconStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: '15px',
    height: '15px',
  },
})

const MoreInfoStyled = styled(Stack)(({ theme }) => ({
  padding: '17px 22px',
  backgroundColor: theme.palette.grey[100],
  position: 'relative',
  overflow: 'hidden',
}))

const MoreInfoIconContainerStyled = styled('div')({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  margin: 'auto',
  left: '-10px',
  opacity: 0.09,
  '& svg': {
    width: '50px',
    height: '50px',
  },
})

const ToolTipContainerStyled = styled(Stack)({
  padding: '17px',
  backgroundColor: 'rgba(54, 54, 54, 0.74)',
  backdropFilter: 'blur(7px)',
  borderRadius: '5px',
  color: 'white',
})

const COLORS = [
  '#D06516',
  '#C4006A',
  '#00987B',
  '#00342D',
  '#FFCC00',
  '#FF007A',
  '#00BFFF',
  '#FF0033',
]

interface ChartData {
  name: string
  value: number
  metaData?: any
}

interface StatProps {
  title: string
  titleIcon: JSX.Element
  tooltip?: string
  number?: number
  numberSubtitle?: string
  moreInfoText?: string
  moreInfoIcon?: JSX.Element
  chartType?: 'half-pie' | 'full-pie' | 'bar'
  chartData?: ChartData[]
  hideLegend?: boolean
  barChartYAxisWidth?: number
  toolTipContent?: (chartData: ChartData) => JSX.Element
  unit?: string
  setOpenModalConfirm?: (value: boolean) => void
}

const Stat = (props: StatProps) => {
  const {
    title,
    titleIcon,
    tooltip,
    number,
    numberSubtitle,
    moreInfoText,
    moreInfoIcon,
    chartType,
    chartData,
    hideLegend,
    barChartYAxisWidth = 120,
    toolTipContent,
    unit,
    setOpenModalConfirm,
  } = props

  const { t } = useTranslation()

  const theme = useTheme()

  const downLg = useMediaQuery(theme.breakpoints.down('lg'))

  const getBarOpacity = useCallback((value: number, maxValue: number) => {
    if (maxValue === 0) return 1
    return 0.2 + (0.8 / maxValue) * (maxValue - value)
  }, [])

  const sortedChartData = useMemo(() => {
    return chartData?.sort((a, b) => b.value - a.value) ?? []
  }, [chartData])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload[0]?.payload) {
      const chartData = payload[0]?.payload as ChartData
      return (
        <ToolTipContainerStyled>
          {toolTipContent ? (
            toolTipContent(chartData)
          ) : (
            <Typography>{`${!!chartData.name.length ? `${chartData.name} : ` : ''}${
              chartData.value
            }${unit ? ` ${unit}` : ''}`}</Typography>
          )}
        </ToolTipContainerStyled>
      )
    }

    return null
  }

  return (
    <StatStyled
      spacing="35px"
      alignItems="center"
      height={chartType ? '100%' : undefined}
      numberStatOnly={!moreInfoText && !chartType}>
      <Stack alignSelf="flex-start" direction="row" spacing="6px" alignItems="center">
        <TitleIconStyled>{titleIcon}</TitleIconStyled>
        <Typography variant="h4">{title}</Typography>
        {tooltip && (
          <OwnTooltip title={tooltip}>
            <InfoIcon color="primary" />
          </OwnTooltip>
        )}
      </Stack>
      {!chartType && number !== undefined && (
        <Stack alignItems="center">
          <NumberStyled>{number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</NumberStyled>
          {numberSubtitle && <NumberSubtitleStyled>{numberSubtitle}</NumberSubtitleStyled>}
          {setOpenModalConfirm && (
            <Stack alignItems="center">
              <Button
                onClick={() => setOpenModalConfirm(true)}
                variant="text"
                color="primary"
                size="small"
                startIcon={<EditIcon />}>
                {t('global:actions.edit')}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      {!chartType && moreInfoText && (
        <MoreInfoStyled>
          <MoreInfoIconContainerStyled>{moreInfoIcon}</MoreInfoIconContainerStyled>
          <Typography textAlign="center" fontWeight={400} fontSize="12px">
            {moreInfoText}
          </Typography>
        </MoreInfoStyled>
      )}
      {(chartType === 'full-pie' || chartType === 'half-pie') && chartData && (
        <Box
          width="100%"
          height={chartType === 'full-pie' ? '282px' : '100%'}
          minHeight={downLg ? (hideLegend ? '130px' : '230px') : undefined}
          position="relative">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="value"
                startAngle={chartType === 'full-pie' ? 360 : 180}
                endAngle={0}
                data={chartData}
                cy={100}
                outerRadius={100}
                innerRadius={84}
                legendType="circle">
                {chartData.map((_entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              {!hideLegend && (
                <Legend layout="horizontal" verticalAlign="bottom" align="center" height={50} />
              )}
            </PieChart>
          </ResponsiveContainer>
          {number !== undefined && (
            <Stack
              alignItems="center"
              sx={{
                position: 'absolute',
                top: chartType === 'full-pie' ? '105px' : '80px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              <NumberStyled>{number}</NumberStyled>
              {numberSubtitle && <NumberSubtitleStyled>{numberSubtitle}</NumberSubtitleStyled>}
            </Stack>
          )}
        </Box>
      )}
      {chartType === 'bar' && chartData && (
        <Box width="100%" height={chartData.length * 50 + 30} position="relative">
          <ResponsiveContainer>
            <BarChart layout="vertical" data={sortedChartData} barSize={35} margin={{ left: -30 }}>
              <XAxis type="number" dataKey="value" unit={unit} />
              <YAxis
                yAxisId={0}
                dataKey="name"
                type="category"
                axisLine={false}
                tickMargin={5}
                tick={{ color: 'black', fontSize: '12px', fontWeight: 500 }}
                tickLine={false}
                width={barChartYAxisWidth}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" activeBar={<Rectangle stroke="white" />}>
                {sortedChartData.map((entry, index) => (
                  <Cell
                    fill={alpha(
                      DEFAULT_COLORS.primary,
                      getBarOpacity(index, sortedChartData.length),
                    )}
                    key={entry.name}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </StatStyled>
  )
}

export default Stat
