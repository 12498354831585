import React from 'react'
import { alpha, Box, styled, Typography } from '@mui/material'
import Stack from './Stack.common'
import TypedItem from './item/Typed.item'

import { ObjectUtils } from '../../utils/commons.utils'
import { ItemType, Actions as ActionsInterface, DetailItem } from '../../models/props.models'
import Actions from './Actions.common'

const DetailsStyled = styled(Box, { shouldForwardProp: (props) => props !== 'noElevation' })<{
  noElevation?: boolean
}>(({ theme, noElevation }) => ({
  backgroundColor: theme.palette.white,
  border: noElevation ? '' : `1px solid ${theme.palette.blockBorder}`,
  boxShadow: noElevation ? '' : `0px 2px 20px ${alpha(theme.palette.black, 0.05)}`,
  padding: noElevation ? '' : '40px',
  '& > div:first-of-type > .MuiTypography-root:first-of-type': {
    fontSize: '1.125rem',
    fontWeight: 700,
    marginBottom: '7px',
    paddingTop: '7px',
  },
  '& > div:nth-of-type(2)': {
    '& > div:nth-of-type(n)': {
      minHeight: '45px',
      padding: '6px 0 12px',
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.blockBorder}`,
        marginBottom: '10px',
      },
      '&[aria-label=block-column]': {
        '& > .MuiTypography-root:last-of-type': {
          textAlign: 'left',
        },
      },
      '& > .MuiTypography-root:not(.MuiTypography-h3):nth-of-type(2n + 1)': {
        color: theme.palette.blockLabel,
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.25,
      },
      '& > .MuiTypography-root:not(.MuiTypography-h3):nth-of-type(2n)': {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.25,
        textAlign: 'right',
        whiteSpace: 'pre-line',
      },
    },
  },
}))

interface DetailsProps {
  onClick?: () => void
  noElevation?: boolean
  value?: Record<string, any>
  items?: DetailItem[]
  children?: JSX.Element | JSX.Element[]
  title?: string | JSX.Element | JSX.Element[]
  actions?: ActionsInterface
  sx?: any
}

const Details: React.FC<DetailsProps> = ({
  noElevation,
  onClick,
  title,
  actions,
  items,
  children,
  value,
  sx,
}): JSX.Element => {
  return (
    <DetailsStyled noElevation={noElevation} sx={sx}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mr="-6px">
        {!!title && typeof title === 'string' && (
          <Typography
            onClick={onClick}
            color={onClick ? 'primary' : 'inherit'}
            sx={onClick ? { cursor: 'pointer', textDecoration: 'underline' } : {}}>
            {title}
          </Typography>
        )}
        {!!title && typeof title !== 'string' && (
          <Box
            onClick={onClick}
            sx={
              onClick
                ? { cursor: 'pointer', textDecoration: 'underline', width: '100%' }
                : { width: '100%' }
            }>
            {title}
          </Box>
        )}

        {actions && <Actions action={actions} />}
      </Box>
      <Box>
        {items &&
          items
            .filter((item) => {
              let val = ObjectUtils.getKeyValue(value ?? {}, item.key)
              if (item.formatValue) {
                val = item.formatValue(val)
              }
              return !!val || (item.type && item.type !== ItemType.typo)
            })
            .map((item, index) => (
              <React.Fragment key={index}>
                <Stack
                  aria-label={item.column ? 'block-column' : ''}
                  spacing={1.5}
                  direction={item.column ? 'column' : 'row'}
                  alignItems={item.column ? 'flex-start' : 'center'}
                  justifyContent={
                    item.column ? 'flex-start' : !item.label ? 'center' : 'space-between'
                  }>
                  {!!item.label && (
                    <Typography variant={item.isTitle ? 'h3' : 'inherit'}>{item.label}</Typography>
                  )}
                  {!item.isTitle && (
                    <TypedItem
                      type={item.type || ItemType.typo}
                      {...item}
                      value={ObjectUtils.getKeyValue(value ?? {}, item.key)}
                    />
                  )}
                </Stack>
              </React.Fragment>
            ))}
        {children && <Box>{children}</Box>}
      </Box>
    </DetailsStyled>
  )
}

export default Details
