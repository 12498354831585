import { useEffect, useState, useRef, useCallback } from 'react'
import {
  Box,
  InputLabel,
  Autocomplete,
  FormHelperText,
  Typography,
  MenuItem,
  Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { SelectOption, AsyncMultiSelectProps } from '../../../models/props.models'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import StyledInput from './Styled.input'
import { StringUtils } from '../../../utils/commons.utils'
const AsyncMultiSelect: React.FC<AsyncMultiSelectProps> = (props): React.ReactElement => {
  const {
    allowNew,
    ariaLabel,
    disabled,
    error,
    getOptions,
    options: propsOptions,
    onChange,
    label,
    minimumSearchLength,
    placeholder,
    readOnly,
    required,
    value = [],
  } = props
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [inputValue, setInputValue] = useState('')
  const timeoutRef = useRef<any>()

  const onInputChange = useCallback(
    (newInputValue: string) => {
      setInputValue(newInputValue)
      if (getOptions) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        setLoading(true)
        timeoutRef.current = setTimeout(() => {
          if (!minimumSearchLength || newInputValue.length >= minimumSearchLength) {
            ;(async () => {
              try {
                const results = await getOptions?.(newInputValue)

                setOptions(results)
                setLoading(false)
              } catch (err: any) {
                show(err)
                setLoading(false)
              }
            })()
          }
        }, 500)
      } else if (propsOptions) {
        setOptions(
          value.concat(
            propsOptions.filter((option) =>
              newInputValue ? StringUtils.normalizeIncludes(option.label, newInputValue) : true,
            ),
          ),
        )
      }
    },
    [getOptions, propsOptions, minimumSearchLength, show, value],
  )

  useEffect(() => {
    if (options.length === 0 && value.length > 0) {
      setOptions(value)
    }
  }, [options, value])

  useEffect(() => {
    if (propsOptions) {
      setOptions(propsOptions)
    }
  }, [propsOptions])

  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Autocomplete
        ListboxProps={{ style: { maxHeight: '50vh', overflow: 'auto' } }}
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.label || ''
        }}
        isOptionEqualToValue={(option: SelectOption, value: SelectOption) => {
          return option.value === value.value
        }}
        filterOptions={(x) => x}
        options={Array.isArray(options) ? options : []}
        multiple
        autoComplete
        disableCloseOnSelect
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOptions(value || [])
          setOpen(false)
        }}
        loading={minimumSearchLength && inputValue.length >= minimumSearchLength ? loading : false}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        renderTags={(value) => {
          return `(${value.length})`
        }}
        open={open}
        onChange={(_: any, newValues: SelectOption[] | null, reason: any) => {
          if (reason === 'clear') {
            setOptions([])
          }
          onChange?.(newValues || undefined)
        }}
        onInputChange={(_, newInputValue) => {
          onInputChange(newInputValue)
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.label}
          </li>
        )}
        noOptionsText={
          <>
            {allowNew && (
              <MenuItem>
                <Typography color="primary">{t('global:inputs.allowNew')}</Typography>
              </MenuItem>
            )}
            {minimumSearchLength && inputValue.length < minimumSearchLength ? (
              <Box padding="10px 16px">
                {t('global:inputs.minimumSearchLength', { count: minimumSearchLength })}
              </Box>
            ) : (
              <Box padding="10px 16px">{t('global:inputs.noOptions')}</Box>
            )}
          </>
        }
        loadingText={<Box padding="10px 16px">{t('global:actions.loading')}</Box>}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <StyledInput
            {...params}
            variant="outlined"
            color="primary"
            focused={readOnly ? false : undefined}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            error={!!error && !disabled}
            aria-label={ariaLabel}
          />
        )}
      />
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default AsyncMultiSelect
