import React from 'react'
import { Box, CardActionArea, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Constants from '../../constants'

const NewsContainer = styled(CardActionArea)<{ src: string }>((props: any) => ({
  width: '100%',
  '& > div[aria-label=news-background-image]': {
    backgroundImage: `url(${props.src})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '325px',
    width: '100%',
  },
  '& > div[aria-label=news-description]': {
    backgroundColor: Constants.colors.white,
    marginRight: '50px',
    marginTop: '-100px',
    padding: '20px',
    position: 'relative',
    '& > div[aria-label=news-type]': {
      backgroundColor: Constants.colors.home.newsType,
      clipPath: 'polygon(0 0, 100% 0, 100% 75%, 12.5px 74%, 0 100%, 0% 75%)',
      height: '40px',
      padding: '0 14px',
      position: 'absolute',
      right: '20px',
      top: '0px',
      transform: 'translateY(-50%)',
      '& > .MuiTypography-root': {
        fontSize: '0.875rem',
        fontWeight: 700,
        paddingTop: '5px',
      },
    },
    '& > .MuiTypography-root:first-of-type': {
      fontSize: '1.375rem',
      fontWeight: 800,
      lineHeight: 1.25,
      marginBottom: '5px',
      whiteSpace: 'pre-line',
    },
    '& > .MuiTypography-root:nth-of-type(2)': {
      fontSize: '0.938rem',
      color: Constants.colors.blockLabel,
      marginBottom: '5px',
    },
    '& > .MuiTypography-root:nth-of-type(3)': {
      fontSize: '0.938rem',
      fontWeight: 400,
      lineHeight: 1.25,
      whiteSpace: 'pre-line',
    },
  },
}))

interface News {
  shortDescription?: string
  picture: string
  title: string
  type: string
  publishedAt: string
}

interface NewsCardProps {
  news: News
  onClick: () => void
}

const NewsCard = (props: NewsCardProps) => {
  const { news, onClick } = props

  const { t } = useTranslation()

  return (
    <NewsContainer src={news.picture} onClick={onClick}>
      <Box aria-label="news-background-image" />
      <Box aria-label="news-description">
        <Box aria-label="news-type">
          <Typography>{news.type}</Typography>
        </Box>
        <Typography>{news.title}</Typography>
        <Typography>
          {t('sessions:components.cardNews.publishedAt', { date: new Date(news.publishedAt) })}
        </Typography>
        {!!news.shortDescription && <Typography>{news.shortDescription}</Typography>}
      </Box>
    </NewsContainer>
  )
}

export default NewsCard
