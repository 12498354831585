import PageOrderList from '../../../components/page/OrderList.page'
import { useOutletContext } from 'react-router-dom'
import { Catalog } from '../../../models/catalogs.models'

const CatalogOrdersTab = () => {
  const { catalog, useImperials } = useOutletContext<{
    catalog: Catalog
    useImperials: boolean
  }>()

  return <PageOrderList useImperials={useImperials} withTabs catalog={catalog._id} />
}
export default CatalogOrdersTab
