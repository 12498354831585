import { useState } from 'react'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'
import { sessionService, sessionQuery } from '../../store/session'

import Constants from '../../constants'
import LinkButton from '../common/button/Link.button'

import ModalFormContactUs from '../session/ModalFormContactUs.session'
import useRoute from '../../hooks/useRoute.hooks'
import { getPlatform } from '../../models/platforms.models'
import { Route } from '../../models/commons.models'

const FooterContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderTop: `2px solid ${theme.palette.blockBorder}`,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0 50px',
  justifyContent: 'space-around',
}))

const AddressText = styled(Typography)(({ theme }) => ({
  color: theme.palette.blockLabel,
  fontSize: '.875rem',
  lineHeight: 1.25,
  fontWeight: 500,
}))

const RaeText = styled(Typography, { shouldForwardProp: (props) => props !== 'blackFooter' })<{
  blackFooter: boolean
}>(({ theme, blackFooter }) => ({
  color: blackFooter ? theme.palette.white : theme.palette.black,
  fontSize: '.875rem',
  lineHeight: 1.25,
  fontWeight: 500,
}))

const Footer = () => {
  const { t } = useTranslation()
  const { getPath } = useRoute()
  const [user] = useObservable(sessionQuery.user)

  const [showContactForm, setShowContactForm] = useState(false)

  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const platform = getPlatform(Constants.platform)
  const blackFooter = !!platform.blackFooter

  const logo = (
    <Box padding="10px">
      <img width="250px" alt="" src={platform.footerLogo} />
    </Box>
  )
  const info = (
    <Box display="flex" alignItems="center" flexDirection="column" paddingY="10px">
      <AddressText>{t('global:contactInfo.address')}</AddressText>
      <AddressText>{t('global:contactInfo.street')}</AddressText>
      <AddressText>{t('global:contactInfo.city')}</AddressText>
    </Box>
  )

  return (
    <FooterContainer sx={{ background: blackFooter ? 'black' : undefined }}>
      {platform.footerCenterLogo ? (
        <>
          <Box display="flex" flexDirection="column">
            {logo}
            {info}
          </Box>
          <Box padding="10px" width="33%" minWidth="300px">
            <img width="100%" alt="" src={platform.footerCenterLogo} />
          </Box>
        </>
      ) : (
        <>
          {logo}
          {info}
        </>
      )}

      <Box display="flex" alignItems="center" flexDirection="column" paddingY="10px">
        <NavLink to={getPath({ route: Route.cgu })}>
          <LinkButton>{t('global:routes.cgu')}</LinkButton>
        </NavLink>
        <NavLink to={getPath({ route: Route.rgpd })}>
          <LinkButton>{t('global:routes.rgpd')}</LinkButton>
        </NavLink>
        {!platform?.disableContactUs && (
          <LinkButton onClick={() => setShowContactForm(true)}>
            {t('global:actions.contactUs')}
          </LinkButton>
        )}
        {platform?.madeByRaedificare && (
          <Box
            alignItems={downMd ? 'center' : 'flex-start'}
            width={downMd ? 'auto' : '100%'}
            marginTop="20px">
            <RaeText blackFooter={blackFooter}>
              {t('global:components.footer.madeByRaedificare')}
            </RaeText>
            <Box
              ml="-18px"
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://raedificare.com/', '_blank')}>
              <img width="200px" alt="" src="/logo.png" />
            </Box>
          </Box>
        )}
      </Box>
      {showContactForm && (
        <ModalFormContactUs
          user={user}
          onSubmit={sessionService.contactUs}
          onClose={() => setShowContactForm(false)}
        />
      )}
    </FooterContainer>
  )
}

export default Footer
