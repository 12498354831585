export const createTabs = (colors: any) => ({
  MuiTabs: {
    indicator: {
      backgroundColor: colors.primary,
      height: '4px',
    },
    scroller: {
      position: 'relative',
      overflow: 'auto ! important',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  MuiTab: {
    root: {
      // minWidth: '100px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      fontSize: '0.875rem',
      textTransform: 'none',
      color: `${colors.primary} !important`,
      alignItems: 'flex-start',
      '&[aria-label=secondary]': {
        color: `${colors.secondary} !important`,
      },
      '&[aria-label=light-mode-secondary]': {
        color: `${colors.white} !important`,
        '&.Mui-selected': {
          color: `${colors.primary} !important`,
        },
      },
      '&.Mui-selected': {
        fontWeight: 700,
      },
      '&[aria-label=light-mode]': {
        color: colors.white,
        '&.Mui-selected': {
          color: colors.primary,
        },
      },
    },
  },
})
