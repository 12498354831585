import Content from '../common/Contents.common'
import { useTranslation } from 'react-i18next'
import constants from '../../constants'
import { PlatformReference } from '../../models/platforms.models'
import RgpdCgu from './tm/rgpd.tm'

const RGPDContent: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  if (constants.platform === PlatformReference.tm) {
    return <RgpdCgu />
  }

  return (
    <Content
      title={t('sessions:components.rgpdContent.title')}
      intro={t('sessions:components.rgpdContent.intro')}
    />
  )
}
export default RGPDContent
