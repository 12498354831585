import { User, Profession } from './users.models'
import { ManageOrganization, ApiOrganization } from './organizations.models'
import { Currency, Point } from './commons.models'

export interface CountryParams {
  countryCode: string
  address: string
  date: string
  currency: Currency
  coordinates: Point
  canUseImperials?: boolean
  enableCerfa?: boolean
  tax?: number
  siret?: { format: string; validation: string }
  phoneNumber?: { format: string; validation: string }
}

export interface AuthenticationResponse {
  accessToken: string
  user: User
}
export interface SessionInterface {
  useImperials: boolean
  accessToken?: string
  user?: User
}

export interface LoginInterface {
  email: string
  password: string
}

export interface SignupInterface {
  lastname: string
  firstname: string
  phoneNumber: string
  professions: Profession[]
  address: string
  email: string
  password: string
  confirmPassword: string
  organization: ManageOrganization
  canUseMyData: boolean
  receiveEmails: boolean
  rgpd: boolean
}
export interface SignupInterfaceApi extends Omit<SignupInterface, 'organization'> {
  organization?: string
  newOrganization?: ApiOrganization
}

export enum ContactSubject {
  information = 'information',
  materialSearch = 'materialSearch',
  demolitionProject = 'demolitionProject',
  constructionProject = 'constructionProject',
  other = 'other',
}

export interface ContactUsData {
  lastname: string
  firstname: string
  companyName: string
  phoneNumber: string
  email: string
  subject: ContactSubject
  translatedSubject?: string
  message: string
}
