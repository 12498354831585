import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnItem, ItemType } from '../../models/props.models'
import List, { ListProps, ListRef } from '../common/List.common'
import useRoute from '../../hooks/useRoute.hooks'
import { Route } from '../../models/commons.models'
import { Chat, ChatStatus } from '../../models/chats.models'
import { User } from '../../models/users.models'
import { Organization } from '../../models/organizations.models'
import { StringUtils } from '../../utils/commons.utils'
import { Mail as MailIcon } from '@mui/icons-material'
import { Box } from '@mui/material'

export interface ChatListProps extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {
  isSeller?: boolean
  isClient?: boolean
  isCatalog?: boolean
}
const ChatList = forwardRef<ListRef, ChatListProps>((props, ref) => {
  const { isSeller, isClient, isCatalog, ...listProps } = props
  const { t } = useTranslation()
  const { goTo } = useRoute()

  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('chats:attributes.status'),
        key: 'status',
        formatValue: (status: ChatStatus) => t(`chats:status.${status}`),
      },
      ...(isCatalog
        ? []
        : [
            (chat: Chat | undefined) => ({
              label: t('chats:attributes.subject'),
              key: 'subject',
              type: !isClient && chat?.catalog ? ItemType.link : ItemType.typo,
              formatValue: (_: any) =>
                chat?.orderNumber
                  ? t('chats:attributes.title.order', { orderNumber: chat?.orderNumber })
                  : t('chats:attributes.title.catalog', { catalogName: chat?.catalogName }),
              props: {
                onClick: (e: any) => {
                  if (chat) {
                    if (!isClient && chat?.order) {
                      goTo({
                        route: Route.workspaceCatalogOrders,
                        catalogId: chat?.catalog,
                      })
                      e.stopPropagation()
                    } else {
                      goTo({
                        route: Route.workspaceCatalog,
                        catalogId: chat?.catalog,
                      })
                      e.stopPropagation()
                    }
                  }
                },
              },
            }),
          ]),
      ...(!isClient
        ? [
            (chat: Chat | undefined) => ({
              label: t('chats:attributes.client'),
              key: 'client',
              type: !isSeller && !isClient ? ItemType.link : ItemType.typo,
              formatValue: (client: User) =>
                `${StringUtils.capitalize(client.firstname)} ${StringUtils.capitalize(
                  client.lastname,
                )}`,
              props: {
                onClick: (e: any) => {
                  if (!isSeller && !isClient && chat?.client) {
                    goTo({
                      route: Route.user,
                      userId: chat?.client._id,
                    })
                    e.stopPropagation()
                  }
                },
              },
            }),
          ]
        : []),
      ...(!isSeller
        ? [
            (chat: Chat | undefined) => ({
              label: t('chats:attributes.seller'),
              key: 'seller',
              type: !isSeller && !isClient ? ItemType.link : ItemType.typo,
              formatValue: (seller: Organization) => StringUtils.capitalize(seller.name),
              props: {
                onClick: (e: any) => {
                  if (!isSeller && !isClient && chat?.seller) {
                    goTo({
                      route: Route.organization,
                      organizationId: chat?.seller._id,
                    })
                    e.stopPropagation()
                  }
                },
              },
            }),
          ]
        : []),
      (chat: Chat | undefined) => ({
        label: t('chats:attributes.lastMessage'),
        key: 'lastMessage',
        formatValue: () => {
          const unreadMessage =
            (!chat?.sellerUpToDate && !isClient) || (!chat?.clientUpToDate && !isSeller)

          return (
            <Box display="flex" alignItems="flex-end">
              {unreadMessage && <MailIcon color="primary" sx={{ marginRight: '5px' }} />}
              {chat?.lastMessageAt &&
                t('global:format.dateAndHour', { date: new Date(chat?.lastMessageAt) })}
            </Box>
          )
        },
      }),
    ],
    [t, goTo, isCatalog, isClient, isSeller],
  )

  return <List ref={ref} {...listProps} columns={columns} />
})
export default ChatList
