import useCheckRoute from '../hooks/useCheckRoute.hooks'
import { Mode } from '../models/commons.models'

import RGPDContent from '../components/session/rgpd.session'
import { Box } from '@mui/material'

const Rgpd = () => {
  useCheckRoute('Rgpd', [Mode.front, Mode.storeFront])

  return (
    <Box paddingX="20px">
      <RGPDContent />
    </Box>
  )
}

export default Rgpd
