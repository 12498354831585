import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Discount, DiscountType } from '../../models/orders.models'
import { FormItem, ItemType } from '../../models/props.models'

import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

interface ModalDiscountProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'title' | 'submitLabel'> {
  discount?: Discount
}
const ModalDiscount: React.FC<ModalDiscountProps> = (props) => {
  const { discount, onSubmit, ...formProps } = props
  const { t } = useTranslation()
  const [value, setValue] = useState<Discount>({
    type: DiscountType.amount,
    value: 10,
    ...discount,
  })

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.select,
        key: 'type',
        required: true,
        props: {
          label: t('orders:attributes.discount.type'),
          placeholder: t('orders:attributes.discount.type'),
          items: createOptionsFromEnum(DiscountType, 'orders:discountType'),
        },
      },
      {
        type: ItemType.number,
        key: 'value',
        required: true,
        props: {
          label: t('orders:attributes.discount.value'),
          placeholder: t('orders:attributes.discount.value'),
          min: 0,
        },
      },
    ],
    [t],
  )
  return (
    <ModalForm
      maxWidth="sm"
      {...formProps}
      value={value}
      setValue={setValue}
      onSubmit={onSubmit}
      items={items}
      title={t('orders:attributes.discount.label')}
      submitLabel={t('global:actions.confirm')}
      actions={[
        { label: t('global:actions.delete'), color: 'error', onClick: () => onSubmit(undefined) },
      ]}
    />
  )
}

export default ModalDiscount
