import {
  alpha,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  BusinessCenter as BusinessCenterIcon,
  Delete as DeleteIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  EnergySavingsLeaf as EnergySavingsLeafIcon,
  Euro as EuroIcon,
  Person as PersonIcon,
  ShoppingBasket as ShoppingBasketIcon,
  Home as HomeIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useOwnSnackbar from '../../hooks/useSnackbar.hooks'
import ArrayItem from '../common/item/Array.item'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FilterItem, ItemType, SelectOption } from '../../models/props.models'
import { useLocation } from 'react-router-dom'
import { sessionService } from '../../store/session'
import { Report, ReportResult } from '../../models/reports.model'
import { organizationsService } from '../../store/organizations'
import { catalogsService } from '../../store/catalogs'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { BuildingType, CatalogStatus, CatalogType } from '../../models/catalogs.models'
import { createOptionsFromYearStart } from '../../utils/dayjs.utils'
import constants from '../../constants'
import InputCategory, { InputCategoryValue } from '../category/Input.category'
import { useObservable } from '@ngneat/react-rxjs'
import { categoriesQuery } from '../../store/categories'
import { reportQuery, reportsService } from '../../store/reports'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import ModalFormReport from '../report/ModalForm.report'
import Modal from '../layout/Modal.layout'
import { PDFUtils } from '../../utils/commons.utils'
import Stat from '../common/Stat.common'
import { OrderStatus } from '../../models/orders.models'
import { TermsOfSale } from '../../models/materials.models'
import { LegalForm } from '../../models/organizations.models'

interface PageReportProp {
  owned?: boolean
  organization?: string
  catalog?: string
}
interface ReportForm
  extends Omit<Partial<Report>, 'tertiaryCategories' | 'organizations' | 'catalogs'> {
  selectedCategories?: InputCategoryValue[]
  organizations?: SelectOption[]
  catalogs?: SelectOption[]
}

const PageReport: React.FC<PageReportProp> = ({ owned, organization, catalog }) => {
  const { t } = useTranslation()
  const show = useOwnSnackbar()
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()

  const reportsContainerRef = useRef<HTMLDivElement>(null)
  const locationRef = useRef(location.pathname)
  const fetchDirectrly = useRef<boolean>(true)
  const timeoutRef = useRef<any>()

  const [reports] = useObservable(reportQuery.list)
  const [primaryCategories] = useObservable(categoriesQuery.primaryCategories)
  const [secondaryCategories] = useObservable(categoriesQuery.secondaryCategories)
  const [tertiaryCategories] = useObservable(categoriesQuery.tertiaryCategories)

  const [loading, setLoading] = useState(false)
  const [modalReport, setModalReport] = useState('')
  const [reportResult, setReportResult] = useState<ReportResult>()
  const [filterValue, setFilterValue] = useState<ReportForm>(
    sessionService.getFilter(locationRef.current),
  )

  const updateFilterValue = useCallback((updatedFilterValue: any) => {
    fetchDirectrly.current = false
    setFilterValue(updatedFilterValue)
  }, [])
  useEffect(() => {
    if (filterValue._id) {
      const report = reports.find((report) => report._id === filterValue._id)

      if (report) {
        const loadReport = async () => {
          try {
            const catalogs: SelectOption[] = []
            if (report.catalogs) {
              for (let i = 0; i < report.catalogs.length; i++) {
                try {
                  const catalog = await catalogsService.getCatalog(report.catalogs[i])
                  if (catalog) {
                    catalogs.push({ label: catalog.name, value: catalog._id })
                  }
                } catch (err) {}
              }
            }
            const organizations: SelectOption[] = []
            if (report.organizations) {
              for (let i = 0; i < report.organizations.length; i++) {
                try {
                  const organization = await organizationsService.getOrganizationById(
                    report.organizations[i],
                  )
                  if (organization) {
                    organizations.push({ label: organization.name, value: organization._id })
                  }
                } catch (err) {}
              }
            }
            const selectedCategories: InputCategoryValue[] = []
            if (report.tertiaryCategories) {
              for (let i = 0; i < report.tertiaryCategories.length; i++) {
                const tertiaryCategory = tertiaryCategories.find(
                  (category) => category._id === report.tertiaryCategories![i],
                )
                if (!tertiaryCategory) {
                  continue
                }
                const secondaryCategory = secondaryCategories.find(
                  (category) => category._id === tertiaryCategory.parent,
                )
                if (!secondaryCategory) {
                  continue
                }
                const primaryCategory = primaryCategories.find(
                  (category) => category._id === secondaryCategory.parent,
                )
                if (!primaryCategory) {
                  continue
                }
                selectedCategories.push({
                  tertiaryCategory,
                  secondaryCategory,
                  primaryCategory,
                })
              }
            }
            setFilterValue((filterValue: ReportForm) => ({
              ...filterValue,
              years: report.years,
              buildingTypes: report.buildingTypes,
              catalogs,
              organizations,
              selectedCategories,
            }))
          } catch (err: any) {
            show(err)
          }
        }
        loadReport()
      }
    }
  }, [show, filterValue._id, reports, tertiaryCategories, secondaryCategories, primaryCategories])

  const parsedFilter: Partial<Report> = useMemo(
    () => ({
      ...filterValue,
      selectedCategories: undefined,
      organizations: filterValue.organizations?.map((val: SelectOption) => val.value),
      catalogs: filterValue.catalogs?.map((val: SelectOption) => val.value),
      tertiaryCategories: filterValue.selectedCategories?.map(
        (cat: InputCategoryValue) => cat.tertiaryCategory!._id,
      ),
    }),
    [filterValue],
  )
  const computedFilter = useMemo(
    () => ({
      ...parsedFilter,
      _id: undefined,
      owned,
      organizations: organization ? [organization] : parsedFilter.organizations,
      catalogs: catalog ? [catalog] : parsedFilter.catalogs,
    }),
    [parsedFilter, owned, organization, catalog],
  )
  const hasAtLeastOneFilterSet = useMemo(
    () =>
      !!Object.values(filterValue).filter((x: any) => typeof x !== 'string' && !!x?.length)?.length,
    [filterValue],
  )
  const isReportUpdated = useMemo(() => {
    if (!filterValue._id) {
      return false
    }
    const report = reports.find((report) => report._id === filterValue._id)
    if (!report) {
      return false
    }
    const arrayEqual = (
      array1: any[] | undefined,
      array2: any[] | undefined,
      getItem1?: (item: any) => any,
    ) => {
      if (!array1 || !array2) {
        return array1 === array2
      }
      return (
        array1.length === array2.length &&
        !array1.find(
          (item1) => !array2.find((item2) => (getItem1 ? getItem1(item1) : item1) === item2),
        )
      )
    }
    return !(
      arrayEqual(
        filterValue.organizations,
        report.organizations,
        (selectOption: SelectOption) => selectOption.value,
      ) &&
      arrayEqual(
        filterValue.catalogs,
        report.catalogs,
        (selectOption: SelectOption) => selectOption.value,
      ) &&
      arrayEqual(
        filterValue.selectedCategories,
        report.tertiaryCategories,
        (inputCategory: InputCategoryValue) => inputCategory.tertiaryCategory!._id,
      ) &&
      arrayEqual(filterValue.years, report.years) &&
      arrayEqual(filterValue.buildingTypes, report.buildingTypes)
    )
  }, [filterValue, reports])

  const computeReport = useCallback(async () => {
    setLoading(true)
    try {
      console.log(computedFilter)
      const data = await reportsService.computeReport(computedFilter)
      setReportResult(data)
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show, computedFilter])
  const exportXlsx = useCallback(async () => {
    setLoading(true)
    try {
      await reportsService.exportXlS(computedFilter)
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [computedFilter, show])

  const exportPdf = useCallback(async () => {
    setLoading(true)
    try {
      if (!reportsContainerRef.current) throw new Error('No container to export')
      const response = await PDFUtils.captureContainerAsImage(reportsContainerRef.current)
      if (!response) throw new Error('No screenshot to export')
      const { imgData, ratio } = response
      PDFUtils.convertToPdf(imgData, ratio, 'reports')
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show])

  const createReport = useCallback(
    async ({ name }: { name: string }) => {
      try {
        const created = await reportsService.createReport({ ...parsedFilter, name })
        setFilterValue((filterValue: any) => ({ ...filterValue, _id: created._id }))
        show(t('reports:messages.reportSuccess'), 'success')
      } catch (err: any) {
        show(err)
      }
    },
    [parsedFilter, show, t],
  )
  const updateReport = useCallback(async () => {
    try {
      reportsService.updateReport(parsedFilter._id!, parsedFilter)
      show(t('reports:messages.reportSuccess'), 'success')
    } catch (err: any) {
      show(err)
    }
  }, [parsedFilter, show, t])

  useEffect(() => {
    sessionService.setFilter(locationRef.current, filterValue)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (!fetchDirectrly.current) {
      timeoutRef.current = setTimeout(() => {
        fetchDirectrly.current = true
        computeReport()
      }, 500)
    } else {
      computeReport()
    }
  }, [computeReport, filterValue])

  const topFilters = useMemo<FilterItem[]>(
    () => [
      ...((!owned && !organization && !catalog
        ? [
            {
              type: ItemType.asyncMultiSelect,
              key: 'organizations',
              grid: { xs: 4, md: 2 },
              props: {
                placeholder: t('reports:components.page.organizationFilter'),
                minimumSearchLength: 3,
                getOptions: (search?: string) =>
                  organizationsService.getOrganizationOptions(search),
              },
            },
          ]
        : []) as FilterItem[]),
      ...((!catalog
        ? [
            {
              type: ItemType.asyncMultiSelect,
              key: 'catalogs',
              grid: { xs: 4, md: 2 },
              props: {
                placeholder: t('reports:components.page.catalogFilter'),
                minimumSearchLength: 3,
                getOptions: (search?: string) =>
                  catalogsService.getCatalogOptions({ search, owned, organization }),
              },
            },
            {
              type: ItemType.multiSelect,
              key: 'buildingTypes',
              grid: { xs: 4, md: 2 },
              props: {
                multiple: true,
                size: 'normal',
                placeholder: t('reports:components.page.buildingTypeFilter'),
                items: createOptionsFromEnum(BuildingType, 'catalogs:buildingType'),
              },
            } as FilterItem,
          ]
        : []) as FilterItem[]),
      {
        type: ItemType.custom,
        key: 'selectedCategories',
        grid: { xs: 4, md: 2 },
        position: 'modal',
        custom: (
          <InputCategory
            placeholder={t('reports:components.page.categoryFilter')}
            value={filterValue.selectedCategories ?? []}
            accurateCategory
            multiple
            onChange={(selectedValue: any) => {
              updateFilterValue((filterValue: any) => ({
                ...filterValue,
                selectedCategories: selectedValue,
              }))
            }}
          />
        ),
      },
      {
        type: ItemType.multiSelect,
        key: 'years',
        grid: { xs: 4, md: 2 },
        props: {
          multiple: true,
          size: 'normal',
          placeholder: t('reports:components.page.dateFilter'),
          items: createOptionsFromYearStart(constants.startYearReportFilter),
        },
      },
      {
        type: ItemType.button,
        key: 'exportXlsx',
        grid: { xs: 4, sm: 2, lg: 1 },
        props: {
          fullWidth: true,
          children: t('reports:actions.exportXlsx'),
          onClick: exportXlsx,
        },
      },
      {
        type: ItemType.button,
        key: 'exportPdf',
        grid: { xs: 4, sm: 2, lg: 1 },
        props: {
          fullWidth: true,
          children: t('reports:actions.exportPdf'),
          onClick: exportPdf,
        },
      },
      ...((reports.length === 0
        ? []
        : [
            {
              type: ItemType.select,
              key: '_id',
              grid: { sm: 12 },
              props: {
                items: reports.map((report: Report) => ({ value: report._id, label: report.name })),
                placeholder: t('reports:components.page.savedFilters'),
                endAdornment: (option: SelectOption) => (
                  <IconButton onClick={setModalReport.bind(null, option.value)}>
                    <DeleteOutlineOutlinedIcon color="primary" fontSize="small" />
                  </IconButton>
                ),
              },
            },
          ]) as FilterItem[]),
    ],
    [
      t,
      updateFilterValue,
      filterValue.selectedCategories,
      owned,
      organization,
      catalog,
      exportPdf,
      exportXlsx,
      reports,
    ],
  )

  const reusedMaterialsByCategory = useMemo(() => {
    if (reportResult) {
      return primaryCategories.map((category) => {
        let primaryPerType = reportResult.products.perPrimaryCategory.find(
          (primaryPerType) => category._id === primaryPerType.type,
        )

        return {
          name: category.name,
          value: primaryPerType?.count ?? 0,
          metaData: {
            secondaryCategoryDetails: reportResult.products.perSecondaryCategory.reduce(
              (res: any[], secondaryPerType) => {
                let secondary = secondaryCategories.find(
                  (category) => category._id === secondaryPerType.type,
                )
                if (secondary?.parent === primaryPerType?.type) {
                  res.push({
                    name: secondary?.name ?? '',
                    value: secondaryPerType.count,
                  })
                }
                return res
              },
              [],
            ),
          },
        }
      })
    }
    return []
  }, [reportResult, primaryCategories, secondaryCategories])
  const productPerTermOfSale = useMemo(() => {
    const sale = reportResult?.products.perTermsOfSale.find(
      (perType) => perType.type === TermsOfSale.sale,
    )
    const saleCount = sale?.count ?? 0
    const donation = reportResult?.products.perTermsOfSale.find(
      (perType) => perType.type === TermsOfSale.donation,
    )
    const donationCount = donation?.count ?? 0
    const total = saleCount + donationCount || 1
    return {
      sale: saleCount,
      percentSale: ((saleCount / total) * 100).toFixed(1),
      donation: donationCount,
      percentDonation: ((donationCount / total) * 100).toFixed(1),
    }
  }, [reportResult?.products.perTermsOfSale])

  return (
    <>
      {loading && <LoaderOverlay />}
      <Box
        padding={`15px ${onlyXs ? '10px' : '15px'}`}
        width="100%"
        display="flex"
        position="relative"
        flexDirection="column"
        flexGrow="1">
        <Box mb="15px" display="flex" flexDirection={downMd ? 'column' : 'row'}>
          <Grid container spacing={3}>
            <ArrayItem items={topFilters} value={filterValue} setValue={updateFilterValue} />
          </Grid>
        </Box>

        {reportResult && (
          <Grid container spacing={3} ref={reportsContainerRef} padding="16px">
            <Grid item xs={12} lg={6}>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.environmentBalance')}
              </Typography>
              <Grid container spacing="25px" justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.reusedMaterials')}
                    titleIcon={<DeleteIcon />}
                    number={reportResult.tonnage}
                    numberSubtitle={t('reports:attributes.tonneUnitFull')}
                    chartType="half-pie"
                    unit={t('reports:attributes.tonneUnit')}
                    hideLegend
                    chartData={[{ name: 'test', value: reportResult.tonnage }]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.carbonEconomyViaReuse')}
                    number={reportResult.carbonEconomy || 0}
                    numberSubtitle={t('reports:attributes.carbonUnit')}
                    moreInfoText={t('reports:attributes.equialentCarTrip', {
                      arNb: (reportResult.carbonEconomy / 107.256).toFixed(3),
                    })}
                    titleIcon={<EnergySavingsLeafIcon />}
                    moreInfoIcon={<EnergySavingsLeafIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stat
                    title={t('reports:attributes.reusedMaterialsByCategory')}
                    chartType="bar"
                    titleIcon={<img src={constants.resources.materialIcon} alt="material" />}
                    chartData={reusedMaterialsByCategory}
                    barChartYAxisWidth={200}
                    unit={t('reports:attributes.tonneUnit')}
                    toolTipContent={(data) => {
                      return (
                        <Stack spacing="16px" width="210px">
                          <Typography
                            fontSize="12px"
                            fontWeight={600}
                            width="100%"
                            textAlign="center">
                            {data.name}
                          </Typography>
                          <Stack>
                            {data.metaData.secondaryCategoryDetails
                              ? data.metaData.secondaryCategoryDetails.map(
                                  (x: any, index: number) => (
                                    <Stack key={x.name}>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between">
                                        <Typography fontSize="12px" fontWeight={500}>
                                          {x.name}
                                        </Typography>
                                        <Typography fontSize="12px" fontWeight={600}>
                                          {x.value} {t('reports:attributes.tonneUnit')}
                                        </Typography>
                                      </Stack>
                                      {index !==
                                        data.metaData.secondaryCategoryDetails.length - 1 && (
                                        <Box
                                          my="7px"
                                          width="100%"
                                          height="0.5px"
                                          bgcolor={alpha('#ffff', 0.13)}
                                        />
                                      )}
                                    </Stack>
                                  ),
                                )
                              : null}
                          </Stack>
                        </Stack>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.economicBalance')}
              </Typography>
              <Grid container spacing="25px" justifyContent="flex-start">
                {!owned && !catalog && (
                  <Grid item xs={12} md={6}>
                    <Stat
                      title={t('reports:attributes.totalOrders')}
                      number={reportResult.orders.total || 0}
                      numberSubtitle={t('reports:attributes.orders')}
                      chartType="full-pie"
                      chartData={reportResult.orders.perTypes.map((perType) => ({
                        name: t(`orders:status.${perType.type as OrderStatus}`),
                        value: perType.count,
                      }))}
                      titleIcon={<ShoppingBasketIcon />}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.totalReuseSale')}
                    number={reportResult.price}
                    numberSubtitle={t('reports:attributes.currency')}
                    titleIcon={<EuroIcon />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalSaleCessionsMade')}
                        number={productPerTermOfSale.sale}
                        numberSubtitle={t('reports:attributes.percentageCessions', {
                          percentage: productPerTermOfSale.percentSale,
                        })}
                        titleIcon={<img src={constants.resources.handIcon} alt="cession" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalGiftCessionsMade')}
                        number={productPerTermOfSale.donation}
                        numberSubtitle={t('reports:attributes.percentageCessions', {
                          percentage: productPerTermOfSale.percentDonation,
                        })}
                        titleIcon={<img src={constants.resources.handIcon} alt="cession" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!owned && !catalog && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalProjects')}
                        number={reportResult.orderedCatalogs.total || 0}
                        numberSubtitle={t('reports:attributes.projects')}
                        chartType="full-pie"
                        chartData={reportResult.orderedCatalogs.perTypes.map((perType) => ({
                          name: t(`catalogs:type.${perType.type as CatalogType}`),
                          value: perType.count,
                        }))}
                        titleIcon={<img src={constants.resources.projectsIcon} alt="projects" />}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.balanceBuyers')}
              </Typography>
              <Grid container spacing="25px" justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.totalBuyers')}
                    number={reportResult.clients.total || 0}
                    numberSubtitle={t('reports:attributes.buyers')}
                    titleIcon={<PersonIcon />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.totalHoursDedicatedToReuse')}
                    number={reportResult.hoursDedicatedToReuse || 0}
                    numberSubtitle={t('reports:attributes.hours')}
                    titleIcon={<BusinessCenterIcon />}
                    tooltip={t('reports:attributes.tooltipHours')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stat
                    title={t('reports:attributes.buyersByLegalStructure')}
                    chartType="bar"
                    titleIcon={<PersonIcon />}
                    chartData={reportResult.clients.perTypes.map((perType) => ({
                      name: t(`organizations:legalForms.${perType.type as LegalForm}`),
                      value: perType.count,
                    }))}
                    barChartYAxisWidth={200}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.platformBalance')}
              </Typography>
              <Grid container spacing="25px">
                {!catalog && (
                  <Grid item xs={12} md={6}>
                    <Stat
                      title={t('reports:attributes.totalCatalogs')}
                      number={reportResult.catalogs.total || 0}
                      numberSubtitle={t('reports:attributes.catalogs')}
                      titleIcon={<HomeIcon />}
                      chartType="full-pie"
                      chartData={reportResult.catalogs.perTypes.map((perType) => ({
                        name:
                          perType.type === 'visible'
                            ? t('catalogs:attributes.visible')
                            : t(`catalogs:status.${perType.type as CatalogStatus}`),
                        value: perType.count,
                      }))}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.totalMaterials')}
                    number={reportResult.materials.total || 0}
                    numberSubtitle={t('reports:attributes.materials')}
                    titleIcon={<HomeIcon />}
                    chartType="full-pie"
                    hideLegend
                    chartData={reportResult.materials.perTypes.map((perType) => {
                      let primary = primaryCategories.find((cat) => cat._id === perType.type)
                      return { name: primary?.name ?? '', value: perType.count }
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {hasAtLeastOneFilterSet && (
          <Stack width="100%" position="fixed" bottom="30px" alignItems="center">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Button variant="contained" onClick={() => setModalReport('create')}>
                  {t('reports:actions.createReport')}
                </Button>
              </Grid>
              <Grid item>
                {isReportUpdated && (
                  <Button variant="contained" onClick={updateReport}>
                    {t('reports:actions.updateReport')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Stack>
        )}
        {modalReport === 'create' && (
          <ModalFormReport onSubmit={createReport} onClose={() => setModalReport('')} />
        )}
        {!!modalReport && modalReport !== 'create' && (
          <Modal
            confirmLabel={t('global:actions.delete')}
            title={t(`reports:actions.deleteReport`)}
            onSuccess={() => {
              if (filterValue._id === modalReport) {
                setFilterValue((filterValue) => ({ ...filterValue, _id: undefined }))
              }
              show(t(`reports:messages.deleteReportSuccess`))
            }}
            onClose={() => setModalReport('')}
            onConfirm={reportsService.deleteReport.bind(null, modalReport)}
          />
        )}
      </Box>
    </>
  )
}

export default PageReport
