import useCheckRoute from '../hooks/useCheckRoute.hooks'
import { Mode } from '../models/commons.models'
import { useTranslation } from 'react-i18next'

import { AdminHeaderTitle } from '../components/layout/AdminHeader.layout'
import PageReport from '../components/page/Report.page'

const Report = () => {
  useCheckRoute('Report', [Mode.admin])
  const { t } = useTranslation()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.report')} />
      <PageReport />
      <></>
    </>
  )
}
export default Report
