import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  Drawer,
  Typography,
  AppBar,
  Box,
  Button,
  IconButton,
  styled,
  Toolbar,
  Menu,
  Fade,
  MenuItem,
} from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'
import { useTranslation } from 'react-i18next'

import {
  Mail as MailIcon,
  Handshake as HandshakeIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Logout as LogoutIcon,
  SwapHoriz as SwapHorizIcon,
  AccountBox as AccountBoxIcon,
  Sell as SellIcon,
  Apartment as ApartmentIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Domain as DomainIcon,
  LineStyle as LineStyleIcon,
  Equalizer as EqualizerIcon,
} from '@mui/icons-material'

import { Route } from '../../models/commons.models'
import { UserType } from '../../models/users.models'
import { PlatformReference, getPlatform } from '../../models/platforms.models'
import useRoute, { RoutePath } from '../../hooks/useRoute.hooks'
import { sessionQuery, sessionService } from '../../store/session'
import Stack from '../common/Stack.common'
import Environment from './Environment.layout'

const MenuButton = styled(Box)(({ theme }) => ({
  color: theme.palette.white,
  marginTop: '25px',
  textDecoration: 'none !important',
  width: '100%',
  '& > div:first-of-type': {
    marginRight: 10,
  },
  '& > .MuiTypography-root': {
    borderColor: 'transparent',
    color: theme.palette.white,
    fontSize: '0.813rem',
    fontWeight: 500,
    textDecoration: 'none !important',
    '&[aria-label=active]': {
      color: theme.palette.primary.main,
    },
  },
}))

const SwitchPlatformButton = styled(Button)({ fontSize: '0.8rem' })

const AccountButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: '22px',
  color: theme.palette.white,
  cursor: 'default',
  fontSize: '0.875rem',
  fontWeight: 600,
  height: '44px',
  marginRight: '10px',
  padding: '0 20px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

type AdminHeaderTitleProps = {
  title: string
  children?: JSX.Element | JSX.Element[]
  canGoBack?: boolean | string
}

export const AdminHeaderTitle = ({ children, title, canGoBack }: AdminHeaderTitleProps) => {
  const navigate = useNavigate()

  let headerTitle = document.getElementById('header-title')
  if (!headerTitle) {
    return <></>
  }
  return createPortal(
    <Stack direction="row" alignItems="center" flex={1} ml="10px" spacing="10px">
      {canGoBack && (
        <IconButton
          color="inherit"
          onClick={() => (canGoBack === true ? navigate('..') : navigate(canGoBack))}>
          <ArrowBackIcon />
        </IconButton>
      )}

      <Typography variant="h1">{title}</Typography>
      {children}
    </Stack>,
    headerTitle,
  )
}

type AdminHeader = {
  additionals?: { path: string; key: string }[]
}
const AdminHeaderComponent = (_: AdminHeader) => {
  const [user] = useObservable(sessionQuery.user)
  const [openDrawer, setOpenDrawer] = useState(false)
  const { t } = useTranslation()
  const { getPath } = useRoute()
  const platform = getPlatform(sessionService.getAdminPlatform())
  const isPlatformAdmin = user?.type === UserType.platformAdmin
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onPlatformMenuClose = () => setAnchorEl(null)
  const selectPlatform = useCallback((platform: PlatformReference) => {
    sessionService.setAdminPlatform(platform)
  }, [])

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ overflowY: 'auto' }}>
          <Environment />
          {!!user && (
            <>
              {!isPlatformAdmin && (
                <>
                  <IconButton
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    onClick={(evt: React.MouseEvent<HTMLElement>) =>
                      setAnchorEl(evt.currentTarget)
                    }>
                    <img
                      alt=""
                      height="24px"
                      src={platform.icon}
                      style={{ filter: platform.adminLogoFilter ?? '' }}
                    />
                  </IconButton>
                  {Boolean(anchorEl) && (
                    <Menu
                      open
                      anchorEl={anchorEl}
                      keepMounted
                      onClose={onPlatformMenuClose}
                      TransitionComponent={Fade}
                      style={{ marginTop: '50px' }}
                      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                      {Object.values(PlatformReference)
                        .filter((platform: any) => typeof platform === 'number')
                        .map((platform: any) => (
                          <MenuItem
                            key={platform}
                            onClick={() => {
                              onPlatformMenuClose()
                              selectPlatform(platform as PlatformReference)
                            }}>
                            <img alt="" height="24px" src={getPlatform(platform).logo} />
                          </MenuItem>
                        ))}
                    </Menu>
                  )}
                </>
              )}

              <IconButton color="inherit" onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
              </IconButton>

              <Box flex={1} ml="10px">
                <div id="header-title" />
              </Box>

              <AccountButton variant="outlined" color="primary" disableRipple>
                {user.firstname} {user.lastname}
              </AccountButton>

              <IconButton color="inherit" onClick={() => sessionService.logout()}>
                <LogoutIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        open={openDrawer}
        anchor="left"
        variant="temporary"
        onClose={() => setOpenDrawer(false)}
        transitionDuration={200}>
        <Box px="10px" display="flex" justifyContent="center">
          <img
            alt=""
            height="45px"
            src={platform.logo}
            style={{ filter: platform.adminLogoFilter ?? '' }}
          />
        </Box>
        <Box flex={1} mt="45px">
          {[
            Route.workspace,
            Route.adminOrders,
            Route.materials,
            Route.materialModels,
            Route.users,
            Route.organizations,
            Route.requests,
            Route.chats,
            Route.report,
          ]
            .filter((route) => (platform.filterAdminMenu ? platform.filterAdminMenu(route) : true))
            .map((route) => (
              <NavLink
                key={route}
                to={getPath({ route: route } as RoutePath)}
                onClick={() => setOpenDrawer(false)}>
                {({ isActive }: { isActive: boolean }) => (
                  <MenuButton display="flex" alignItems="center" justifyContent="flex-start">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="16px"
                      width="16px">
                      {route === Route.users && <AccountBoxIcon />}
                      {route === Route.requests && <HandshakeIcon />}
                      {route === Route.chats && <MailIcon />}
                      {route === Route.adminOrders && <SellIcon />}
                      {route === Route.workspace && <ApartmentIcon />}
                      {route === Route.materialModels && <InsertDriveFileIcon />}
                      {route === Route.materials && <LineStyleIcon />}
                      {route === Route.organizations && <DomainIcon />}
                      {route === Route.report && <EqualizerIcon />}
                    </Box>
                    <Typography aria-label={isActive ? 'active' : ''}>
                      {t(`global:routes.${route}`)}
                    </Typography>
                  </MenuButton>
                )}
              </NavLink>
            ))}
        </Box>
        <SwitchPlatformButton
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_FRONT_HOST}`
          }}
          startIcon={<SwapHorizIcon />}>
          {t('global:routes.front')}
        </SwitchPlatformButton>
      </Drawer>
    </>
  )
}

export default AdminHeaderComponent
