import { useCallback, useRef } from 'react'

import Form, { FormProps } from './Form.common'
import Modal from '../layout/Modal.layout'

export interface ModalFormProps extends FormProps {
  title: string
  description?: string
  onClose: () => void
  keepOpen?: boolean

  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}
const ModalForm: React.FC<ModalFormProps> = (props) => {
  const { description, title, onClose, keepOpen, onSuccess, maxWidth, ...formProps } = props
  const ref = useRef<{ loading: boolean }>({ loading: false })
  const handleSuccess = useCallback(
    async (formValue: any) => {
      await onSuccess?.(formValue)
      if (!keepOpen) {
        onClose()
      }
    },
    [onSuccess, keepOpen, onClose],
  )
  return (
    <Modal
      loadingRef={ref}
      permanent
      title={title}
      description={description}
      onClose={onClose}
      hideAction
      maxWidth={maxWidth}
      noTitleMargin={!!formProps.tabs || !!formProps.header}>
      <>
        <Form
          ref={ref}
          fullwidth={maxWidth === 'xl' || maxWidth === 'lg'}
          scrollable
          noPadding
          {...formProps}
          onSuccess={handleSuccess}
        />
      </>
    </Modal>
  )
}
export default ModalForm
