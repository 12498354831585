import { Slider, Box, InputLabel, styled } from '@mui/material'
import AddressInput from './Address.input'
import { useTranslation } from 'react-i18next'
import { AddressRadiusProps } from '../../../models/props.models'

const StyleSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'transparent',
    top: '45px',
    '& .MuiSlider-valueLabelLabel': {
      color: theme.palette.primary.main,
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
}))
const AddressRadiusInput: React.FC<AddressRadiusProps> = ({ onChange, value, ...inputProps }) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <AddressInput
        value={value?.location}
        placeholder={t('global:inputs.searchCity')}
        {...inputProps}
        onChange={(location) => {
          onChange?.({ radius: value?.radius ?? 200, location })
        }}
        types={['(cities)']}
      />
      <InputLabel sx={{ margin: '5px 0 0 0' }}>{t('global:inputs.radius')}</InputLabel>
      <Box sx={{ marginRight: '10px' }}>
        <StyleSlider
          value={value?.radius ?? 200}
          disabled={inputProps?.disabled || inputProps?.readOnly}
          onChange={(_, radius) => {
            onChange?.({ ...value, radius })
          }}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => <>{value} km</>}
          min={5}
          step={5}
          max={200}
        />
      </Box>
    </Box>
  )
}
export default AddressRadiusInput
