import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ChatList, { ChatListProps } from '../chat/List.chat'
import { sessionQuery } from '../../store/session'
import { usersService } from '../../store/users'
import { organizationsService } from '../../store/organizations'

import { Mode } from '../../models/commons.models'
import { FilterItem, Filter, ItemType } from '../../models/props.models'
import useModal from '../../hooks/useModal.hooks'

import { ListRef } from '../common/List.common'
import { chatsService } from '../../store/chats'
import ModalFormChat from '../chat/ModalForm.chat'
import { Chat, MessageFrom } from '../../models/chats.models'
import constants from '../../constants'

interface PageUserListProps
  extends Omit<
    ChatListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
  > {
  catalog?: string
  from: MessageFrom | 'admin'
}
const PageUserList: React.FC<PageUserListProps> = (props) => {
  const { catalog, from, ...listProps } = props
  const { t } = useTranslation()
  const listRef = useRef<ListRef | null>(null)
  const [modal, setModal] = useModal<string>()

  const parseFilter = useCallback(
    (filters: any) => ({
      ...filters,
      upToDate: !!filters.unReadMessage ? false : undefined,
      seller:
        from === MessageFrom.seller ? sessionQuery.getOrganizationId() : filters?.seller?.value,
      client: from === MessageFrom.client ? sessionQuery.getUserId() : filters?.client?.value,
      catalog,
    }),
    [catalog, from],
  )

  const getChats = useCallback(
    async (filters: any) => {
      return await chatsService.getChats(parseFilter(filters))
    },
    [parseFilter],
  )

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.checkbox,
          key: 'unReadMessage',
          grid: { md: 2, xs: 4 },
          props: {
            label: t('chats:components.listPage.unreadFilter'),
          },
        },
        ...((constants.mode === Mode.admin
          ? [
              {
                type: ItemType.asyncSelect,
                key: 'client',
                grid: { md: 5, xs: 4 },
                props: {
                  placeholder: t('chats:components.listPage.clientFilter'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) => usersService.getUserOptions(search),
                },
              },
            ]
          : []) as FilterItem[]),
        ...((constants.mode === Mode.admin && !catalog
          ? [
              {
                type: ItemType.asyncSelect,
                key: 'seller',
                grid: { md: 5, xs: 4 },
                props: {
                  placeholder: t('chats:components.listPage.sellerFilter'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) =>
                    organizationsService.getOrganizationOptions(search),
                },
              },
            ]
          : []) as FilterItem[]),
      ],
    }),
    [t, catalog],
  )

  return (
    <>
      <ChatList
        {...listProps}
        ref={listRef}
        filter={filter}
        isSeller={from === MessageFrom.seller}
        isClient={from === MessageFrom.client}
        isCatalog={!!catalog}
        onValueClick={(chat: Chat) => setModal(chat._id)}
        getValues={getChats}
      />
      {modal && (
        <ModalFormChat
          chatId={modal}
          updateList={() => listRef?.current?.updateValues()}
          from={from}
          onClose={() => setModal('')}
        />
      )}
    </>
  )
}
export default PageUserList
