import { useTranslation } from 'react-i18next'
import { Material, MaterialType, Unit } from '../../models/materials.models'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import ModalFormMaterial from './ModalForm.material'
import { useCallback, useState } from 'react'
import { materialsService } from '../../store/materials'
import Table from '../common/Table.common'
import { ItemType } from '../../models/props.models'
import { traduceResourceCategory, traduceWasteCategory } from '../../models/pemd.model'

interface ModalPemdMaterialProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs' | 'title'> {
  materials: Material[]
  useImperials: boolean
  type: 'pem' | 'd'
}
const ModalPemdMaterial: React.FC<ModalPemdMaterialProps> = (props) => {
  const { t } = useTranslation()
  const { materials, type, useImperials, ...formProps } = props
  const hasError = useCallback(
    (material: Material): boolean => {
      if (type === 'pem') {
        return !material.cerfaResource?.primaryCategory || material.unit === Unit.tone
      }
      let cerfaWaste = material.cerfaWaste
      if (!cerfaWaste || cerfaWaste.length === 0 || !material.unitWeight) {
        return true
      }
      return cerfaWaste?.some(
        (cerfa) =>
          !cerfa.primaryCategory ||
          (cerfa.reusePercent ?? 0) +
            (cerfa.recyclablePercent ?? 0) +
            (cerfa.backfillingPercent ?? 0) +
            (cerfa.incineratedWithEnergyPercent ?? 0) +
            (cerfa.incineratedWithoutEnergyPercent ?? 0) +
            (cerfa.nonRecoverablePercent ?? 0) !==
            100,
      )
    },
    [type],
  )
  const [value, setValue] = useState<{ materials: (Material & { hasError: boolean })[] }>({
    materials: materials
      .filter(
        (material: Material) =>
          (type === 'pem' && material.initialQty > 0) || (type === 'd' && material.totalQty > 0),
      )
      .map((material: Material) => ({
        ...material,
        hasError: hasError(material),
      })),
  })
  const [indexOpen, setIndexOpen] = useState<number>(-1)

  return (
    <>
      <ModalForm
        {...formProps}
        title={t(`materials:components.modalPemd.${type}Title`)}
        submitLabel={
          type === 'pem' ? t('catalogs:actions.downloadCsvPem') : t('catalogs:actions.downloadCsvD')
        }
        value={value}
        setValue={setValue}
        maxWidth="xl"
        items={[
          {
            type: ItemType.custom,
            key: 'materials',
            grid: { xs: 12 },
            rules: [
              ({ materials }) => {
                return materials.some(
                  (material: Material & { hasError: boolean }) => material.hasError,
                )
              },
            ],
            custom: (
              <Table
                onValueClick={(_: any, index: number) => {
                  setIndexOpen(index)
                }}
                columns={[
                  {
                    label: t('materials:attributes.name'),
                    key: 'name',
                  },
                  (material: Material) => ({
                    label:
                      type === 'pem'
                        ? t('materials:attributes.cerfaResource.tertiaryCategory')
                        : t('materials:attributes.cerfaWaste.secondaryCategory'),
                    key: 'category',
                    formatValue: () => {
                      if (!material) {
                        return ''
                      } else if (type === 'pem') {
                        return traduceResourceCategory(
                          material.cerfaResource?.tertiaryCategory ??
                            material.cerfaResource?.secondaryCategory ??
                            material.cerfaResource?.primaryCategory,
                        )
                      } else {
                        return (
                          material.cerfaWaste
                            ?.map((cerfaWaste) =>
                              traduceWasteCategory(
                                cerfaWaste?.tertiaryCategory ??
                                  cerfaWaste?.secondaryCategory ??
                                  cerfaWaste?.primaryCategory,
                              ),
                            )
                            .join(', ') ?? ''
                        )
                      }
                    },
                  }),
                  {
                    label: t('materials:components.modalImport.hasError'),
                    key: 'hasError',
                    formatValue: (hasError?: boolean) => {
                      return hasError
                        ? t('materials:components.modalImport.invalid')
                        : t('materials:components.modalImport.valid')
                    },
                  },
                ]}
                values={{
                  data: value.materials,
                  total: value.materials.length,
                  count: value.materials.length,
                }}
              />
            ),
          },
        ]}
      />

      {indexOpen >= 0 && indexOpen < value.materials.length && (
        <ModalFormMaterial
          showCerfa={true}
          useImperials={useImperials}
          checkError={type}
          title={t(`materials:actions.update${MaterialType.resource}.label`)}
          material={{
            ...value.materials[indexOpen],
            catalog: value.materials[indexOpen].catalog._id,
          }}
          onClose={() => setIndexOpen(-1)}
          onSubmit={async (updated: any) => {
            const updateMaterial = await materialsService.updateMaterial(
              value.materials[indexOpen],
              updated,
            )
            setValue((val: any) => ({
              ...val,
              materials: value.materials.map((material: any, mapIndex: number) => {
                return mapIndex === indexOpen
                  ? { ...updateMaterial, hasError: hasError(updateMaterial) }
                  : material
              }),
            }))
          }}
        />
      )}
    </>
  )
}
export default ModalPemdMaterial
