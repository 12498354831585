import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import { PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'
import { Chat, ChatsPagination, ChatStatus, CreateChat, UpdateChat } from '../models/chats.models'
export namespace ChatsApi {
  export interface GetListParams extends PaginationParams {
    owned?: boolean
    platform?: PlatformReference
    status?: ChatStatus
    client?: string
    seller?: string
    catalog?: string
    order?: string
    upToDate?: boolean
  }
  export const getList = async (params?: GetListParams): Promise<ChatsPagination> => {
    const response: AxiosResponse<ChatsPagination> = await axiosApi.get(`/chats`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
      },
    })
    return response.data
  }
  export const getById = async (chatId: string): Promise<Chat> => {
    const response: AxiosResponse<Chat> = await axiosApi.get(`/chats/${chatId}`)
    return response.data
  }
  export const create = async (createChat: CreateChat): Promise<Chat> => {
    const response: AxiosResponse<Chat> = await axiosApi.post(`/chats`, createChat)
    return response.data
  }
  export const update = async (chatId: string, updateChat: Partial<UpdateChat>): Promise<Chat> => {
    const response: AxiosResponse<Chat> = await axiosApi.patch(`/chats/${chatId}`, updateChat)
    return response.data
  }
}
