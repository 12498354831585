import { ChatsApi } from '../../api/chats.api'
import { NetworkUtils } from '../../utils/networks.utils'
import { ChatsPagination, Chat, UpdateChat, CreateChat } from '../../models/chats.models'
import { Mode } from '../../models/commons.models'
import { sessionService } from '../session'
import Constants from '../../constants'
import { logIfDev } from '../../utils/commons.utils'
import { catalogsService } from '../catalogs'

export class ChatsService {
  getChats = async (filters: ChatsApi.GetListParams): Promise<ChatsPagination> => {
    await NetworkUtils.checkConnected()

    return await ChatsApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin
        ? { platform: sessionService.getAdminPlatform() }
        : { owned: true }),
    })
  }
  getChatById = async (chatId: string): Promise<Chat> => {
    await NetworkUtils.checkConnected()
    return await ChatsApi.getById(chatId)
  }
  updateChat = async (chat: Chat, updatedChat: Partial<UpdateChat>): Promise<Chat> => {
    await NetworkUtils.checkConnected()
    const updated = await ChatsApi.update(chat._id, updatedChat)
    try {
      await this.updateCatalogReadCount(updated, chat)
    } catch (err) {
      logIfDev(err)
    }
    return updated
  }
  createChat = async (createChat: CreateChat): Promise<Chat> => {
    await NetworkUtils.checkConnected()
    const chat = await ChatsApi.create(createChat)
    try {
      await this.updateCatalogReadCount(chat)
    } catch (err) {
      logIfDev(err)
    }
    return chat
  }
  updateCatalogReadCount = async (currentChat: Chat, previousChat?: Chat) => {
    if (!currentChat.order) {
      await catalogsService.updateReadCount(currentChat.catalog, {
        clientChatToReadCount:
          (!previousChat || previousChat?.clientUpToDate) && !currentChat.clientUpToDate
            ? 1
            : currentChat.clientUpToDate && previousChat?.clientUpToDate === false
            ? -1
            : 0,
        sellerChatToReadCount:
          (!previousChat || previousChat?.sellerUpToDate) && !currentChat.sellerUpToDate
            ? 1
            : currentChat.sellerUpToDate && previousChat?.sellerUpToDate === false
            ? -1
            : 0,
      })
    }
  }
}

export const chatsService = new ChatsService()
