import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { sessionService } from '../../store/session'
import { YupUtils } from '../../utils/yup.utils'
import { FormItem, ItemType } from '../../models/props.models'
import { LoginInterface } from '../../models/sessions.models'

import Form from '../common/Form.common'
import ModalForm from '../common/ModalForm.common'
import ForgotPasswordModal from './ModalForgotPassword.session'

type FormLoginProps = {
  onSuccess?: () => void
  onClose?: () => void
}
const FormLogin: React.FC<FormLoginProps> = ({ onSuccess, onClose }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<LoginInterface>({} as LoginInterface)
  const [forgotPassword, setForgotPassword] = useState(false)

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'email',
        required: true,
        rules: [YupUtils.FieldValidationType.email],
        props: {
          label: t('users:attributes.email'),
          placeholder: t('users:attributes.email'),
          autocomplete: 'email',
        },
      },
      {
        type: ItemType.text,
        key: 'password',
        required: true,
        props: {
          label: t('users:attributes.password'),
          placeholder: t('users:attributes.password'),
          type: 'password',
          autocomplete: 'current-password',
        },
      },
      {
        type: ItemType.link,
        key: 'forgotPassword',
        props: {
          children: t('sessions:actions.forgotPassword'),
          onClick: () => setForgotPassword(true),
        },
      },
    ],
    [t],
  )

  const formProps = {
    value: value,
    onSubmit: sessionService.login,
    submitLabel: t('sessions:actions.login'),
    onSuccess: onSuccess,
    items: items,
    setValue: setValue,
  }
  return (
    <>
      {!!onClose && (
        <ModalForm
          {...formProps}
          title={t('sessions:components.formLogin.modalTitle')}
          onClose={onClose}
        />
      )}
      {!onClose && <Form {...formProps} />}

      {forgotPassword && <ForgotPasswordModal onClose={() => setForgotPassword(false)} />}
    </>
  )
}

export default FormLogin
