import { updateRequestStatus } from '@ngneat/elf-requests'

import Constants from '../../constants'
import { materialsStore } from './materials.store'
import { catalogsService } from '../catalogs/catalogs.service'

import { CatalogLight, Catalog } from '../../models/catalogs.models'
import {
  Material,
  MaterialType,
  computeResourceQuantities,
  ManageMaterial,
  MaterialsPagination,
  ExportedMaterial,
  QuantityType,
  ManageMultipleMaterial,
} from '../../models/materials.models'
import { Order } from '../../models/orders.models'
import { FileDetails } from '../../models/files.models'
import { Mode } from '../../models/commons.models'
import { MaterialsApi } from '../../api/materials.api'
import { FilesApi } from '../../api/files.api'

import { FileUtils } from '../../utils/files.utils'
import { NetworkUtils } from '../../utils/networks.utils'
import { ObjectUtils } from '../../utils/commons.utils'
import { sessionQuery, sessionService } from '../session'

// do not save src in json
const materialsToJson = (materials: Material[]): Material[] => {
  return materials.map((material) => ({
    ...material,
    mainImageFile: material.mainImageFile
      ? {
          ...material.mainImageFile,
          src: undefined,
        }
      : material.mainImageFile,
    imageFiles: material.imageFiles.map((file) => ({
      ...file,
      src: undefined,
    })),
  }))
}

const downloadMaterialsFiles = async (catalogId: string, materials: Material[]): Promise<void> => {
  const baseFolder = `raedificare/${catalogId}/`

  for (let i = 0; i < materials.length; i++) {
    const cleanName = FileUtils.nameToPath(materials[i].name)
    materials[i].mainImageFile = materials[i].mainImageFile
      ? await FileUtils.importFile(
          materials[i].mainImageFile!,
          `${baseFolder}${cleanName}.${materials[i]._id}.main`,
        )
      : materials[i].mainImageFile
    materials[i].imageFiles = await Promise.all(
      materials[i].imageFiles.map((fileDetails: FileDetails) =>
        FileUtils.importFile(
          fileDetails,
          `${baseFolder}${cleanName}.${materials[i]._id}.image.${fileDetails._id}`,
        ),
      ),
    )
    materials[i].files = await Promise.all(
      materials[i].files.map((fileDetails: FileDetails) =>
        FileUtils.importFile(
          fileDetails,
          `${baseFolder}${cleanName}.${materials[i]._id}.file.${fileDetails._id}`,
        ),
      ),
    )
  }
}

export class MaterialsService {
  store = materialsStore

  loadMaterialsImages = async (materials: Material[]): Promise<void> => {
    const load = async (fileDetails: FileDetails): Promise<FileDetails> => {
      return { ...fileDetails, src: await FileUtils.loadImage(fileDetails) }
    }

    for (let i = 0; i < materials.length; i++) {
      materials[i].mainImageFile = materials[i].mainImageFile
        ? await load(materials[i].mainImageFile!)
        : materials[i].mainImageFile
      materials[i].imageFiles = await Promise.all(
        materials[i].imageFiles.map((fileDetails: FileDetails) => load(fileDetails)),
      )
    }
  }

  getCsv = async (filters: MaterialsApi.GetListParams): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await MaterialsApi.getCsv({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
    await FileUtils.downloadFile(`materials.csv`, data)
  }
  getMaterials = async (filters: MaterialsApi.GetListParams): Promise<MaterialsPagination> => {
    await NetworkUtils.checkConnected()
    return await MaterialsApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }
  getMaterial = async (catalogId: string, publicMaterial?: boolean): Promise<Material> => {
    await NetworkUtils.checkConnected()
    return await MaterialsApi.getById(catalogId, publicMaterial)
  }

  init = async (
    catalogId: string,
    type: MaterialType,
    publicMaterials?: boolean,
    localMaterials?: Material[],
  ): Promise<void> => {
    if (publicMaterials && type === MaterialType.need && !sessionQuery.getUserId()) {
      return
    }

    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      let materials: Material[]
      if (Constants.getIsLocal() && !publicMaterials) {
        materials = localMaterials as Material[]
      } else {
        materials = (
          await this.getMaterials({
            owned: !publicMaterials && Constants.mode !== Mode.admin,
            type,
            catalog: catalogId,
            public: publicMaterials,
            disablePaginate: true,
          })
        ).data
      }

      await this.loadMaterialsImages(materials)
      this.store.update(
        (state) => ({
          ...state,
          list: materials,
        }),
        updateRequestStatus('init', 'success'),
      )
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  updateFormCatalogsService = async (materials: Material[]) => {
    await this.loadMaterialsImages(materials)
    this.store.update((state) => ({
      ...state,
      list: materials,
    }))
  }

  updateStoredMaterial = async (
    updatedMaterials: Material[],
    type: 'update' | 'delete' | 'create',
  ): Promise<void> => {
    if (!updatedMaterials.length) {
      return
    }

    if (Constants.getIsLocal()) {
      const catalogId = updatedMaterials[0].catalog?._id
      const { detail } = catalogsService.getPathes(catalogId)

      if (type === 'delete') {
        for (let i = 0; i < updatedMaterials.length; i++) {
          await Promise.all([
            ...(updatedMaterials[i].mainImageFile
              ? [FileUtils.deleteFile(updatedMaterials[i].mainImageFile!.path as string)]
              : []),
            ...updatedMaterials[i].imageFiles.map((file: FileDetails) =>
              FileUtils.deleteFile(file.path as string),
            ),
            ...updatedMaterials[i].files.map((file: FileDetails) =>
              FileUtils.deleteFile(file.path as string),
            ),
          ])
        }
      }

      const { catalog, materials } = await FileUtils.readJSON(detail)
      const localUpdatedAt = new Date()
      await FileUtils.writeJSON(detail, {
        catalog: { ...catalog, localUpdatedAt },
        materials:
          type === 'delete'
            ? materials.filter(
                (material: Material) =>
                  !updatedMaterials.find(
                    (updatedMaterial: Material) => updatedMaterial._id === material._id,
                  ),
              )
            : type === 'create'
            ? [...materialsToJson(updatedMaterials), ...materials]
            : materialsToJson(
                materials.map(
                  (material: Material) =>
                    updatedMaterials.find(
                      (updatedMaterial: Material) => updatedMaterial._id === material._id,
                    ) || material,
                ),
              ),
      })
      await catalogsService.updateLocatlUpdatedAt(localUpdatedAt)
    }

    if (type !== 'delete') {
      await this.loadMaterialsImages(updatedMaterials)
    }

    this.store.update((state) => ({
      ...state,
      list:
        type === 'delete'
          ? state.list.filter(
              (material: Material) =>
                !updatedMaterials.find(
                  (updatedMaterial: Material) => updatedMaterial._id === material._id,
                ),
            )
          : type === 'create'
          ? [...updatedMaterials, ...state.list]
          : state.list.map(
              (material: Material) =>
                updatedMaterials.find(
                  (updatedMaterial: Material) => updatedMaterial._id === material._id,
                ) || material,
            ),
    }))
  }

  importMaterials = async (catalogId: string): Promise<Material[]> => {
    if (!Constants.getIsLocal()) {
      throw new Error('can not use local function')
    }
    await NetworkUtils.checkConnected()

    let materials = (
      await this.getMaterials({
        owned: Constants.mode !== Mode.admin,
        catalog: catalogId,
        disablePaginate: true,
      })
    ).data

    await downloadMaterialsFiles(catalogId, materials)

    return materials
  }

  beforeExport = async (materials: Material[]): Promise<ExportedMaterial[]> => {
    await NetworkUtils.checkConnected()
    let apiMaterials = []
    const handleFile = async (fileDetail: FileDetails): Promise<string> => {
      if (!Constants.getIsLocal()) {
        return fileDetail._id
      }
      if (fileDetail._id.includes('local')) {
        const file = new File([await FileUtils.readFile(fileDetail)], fileDetail.path, {
          type: fileDetail.mimeType,
        })
        const createdFileDetails = (await FilesApi.create(file)) as FileDetails
        return createdFileDetails._id
      } else {
        return fileDetail._id.replace('sync.', '')
      }
    }

    let material
    for (let i = 0; i < materials.length; i++) {
      material = materials[i]
      apiMaterials.push({
        ...material,
        catalog: undefined,
        _id: material._id.includes('local') ? undefined : material._id,
        primaryCategory: material.primaryCategory?._id,
        secondaryCategory: material.secondaryCategory?._id,
        tertiaryCategory: material.tertiaryCategory?._id,
        mainImageFile: material.mainImageFile
          ? await handleFile(material.mainImageFile)
          : material.mainImageFile,
        files: await Promise.all(material.files.map((file) => handleFile(file))),
        imageFiles: await Promise.all(
          material.imageFiles.map((imageFile) => handleFile(imageFile)),
        ),
        quantities: materials[i].quantities
          ? material.quantities.map((materialQuantity) => {
              return {
                ...materialQuantity,
                _id:
                  !materialQuantity._id || materialQuantity._id.includes('local')
                    ? undefined
                    : materialQuantity._id,
                plan: materialQuantity.plan?._id,
                room: materialQuantity.room?._id,
              }
            })
          : [],
      } as ExportedMaterial)
    }

    return apiMaterials
  }

  afterExport = async (
    materials: Material[],
    serverMaterials: Material[],
    catalogId: string,
    newCatalogId: string,
  ) => {
    if (Constants.getIsLocal()) {
      const moveFile = async (
        file: FileDetails,
        serverFile: FileDetails,
        materialId: string,
        newMaterialId: string,
      ) => {
        await FileUtils.replaceIds(
          file,
          [catalogId, file._id.replace('sync.', ''), materialId],
          [newCatalogId, serverFile._id, newMaterialId],
        )
        file._id = `sync.${serverFile._id}`
        return file
      }
      for (let i = 0; i < materials.length; i++) {
        let material = materials[i]
        let serverMaterial = serverMaterials[i]
        if (material.mainImageFile) {
          serverMaterial.mainImageFile = await moveFile(
            material.mainImageFile,
            serverMaterial.mainImageFile!,
            material._id,
            serverMaterial._id,
          )
        }
        for (let j = 0; j < material.files.length; j++) {
          serverMaterial.files[j] = await moveFile(
            material.files[j],
            serverMaterial.files[j],
            material._id,
            serverMaterial._id,
          )
        }
        for (let j = 0; j < material.imageFiles.length; j++) {
          serverMaterial.imageFiles[j] = await moveFile(
            material.imageFiles[j],
            serverMaterial.imageFiles[j],
            material._id,
            serverMaterial._id,
          )
        }
      }
    }
  }

  saveTmpMaterial = async (
    catalog: CatalogLight,
    manageMaterial: ManageMaterial,
  ): Promise<Material> => {
    if (!Constants.getIsLocal()) {
      await NetworkUtils.checkConnected()
    }

    const saveFile = async (
      file: File,
      filename: string,
      addIdInName?: boolean,
    ): Promise<FileDetails> => {
      return Constants.getIsLocal()
        ? FileUtils.saveLocalFile(file, filename, addIdInName)
        : FilesApi.create(file)
    }

    const catalogId = manageMaterial.catalog
    const baseFolder = `raedificare/${catalogId}/`
    const { mainImageFileFile, filesFile, imageFilesFile, ...data } = manageMaterial
    const _id = manageMaterial._id || `local.${Math.random().toString().split('.')[1]}`
    const cleanName = FileUtils.nameToPath(data.name)

    return {
      ...data,
      _id,
      catalog: catalog,
      currency: data.currency || catalog.currency,
      mainImageFile: mainImageFileFile
        ? await saveFile(mainImageFileFile, `${baseFolder}${cleanName}.${_id}.main`, false)
        : manageMaterial.mainImageFile,
      imageFiles: (
        await Promise.all(
          imageFilesFile?.map((file) => saveFile(file, `${baseFolder}${cleanName}.${_id}.image`)) ||
            [],
        )
      ).concat(manageMaterial.imageFiles || []),
      files: (
        await Promise.all(
          filesFile?.map((file) => saveFile(file, `${baseFolder}${cleanName}.${_id}.file`)) || [],
        )
      ).concat(manageMaterial.files || []),
    } as Material
  }

  createMaterial = async (
    catalog: CatalogLight,
    manageMaterial: ManageMaterial,
  ): Promise<Material> => {
    let material = await this.saveTmpMaterial(catalog, manageMaterial)

    if (!Constants.getIsLocal()) {
      material = await MaterialsApi.create({
        ...material,
        _id: undefined,
        catalog: catalog._id,
        primaryCategory: material.primaryCategory?._id,
        secondaryCategory: material.secondaryCategory?._id,
        tertiaryCategory: material.tertiaryCategory?._id,
        mainImageFile: material.mainImageFile?._id,
        files: material.files.map((file: FileDetails) => file._id),
        imageFiles: material.imageFiles.map((file: FileDetails) => file._id),
        quantities: material.quantities?.map((materialQuantitiy) => ({
          ...materialQuantitiy,
          _id:
            !materialQuantitiy._id || materialQuantitiy._id.includes('local')
              ? undefined
              : materialQuantitiy._id,
          plan: materialQuantitiy.plan?._id,
          room: materialQuantitiy.room?._id,
        })),
      })
    }

    await this.updateStoredMaterial([material], 'create')

    return material
  }

  updateMaterial = async (
    material: Material,
    updatedMaterial: ManageMaterial,
  ): Promise<Material> => {
    material = await this.saveTmpMaterial(material.catalog, updatedMaterial)
    if (!Constants.getIsLocal()) {
      const catalogId = (material.catalog as Catalog)?._id
      material = await MaterialsApi.update(material._id, {
        ...material,
        catalog: catalogId,
        price: material.price ?? null,
        primaryCategory: material.primaryCategory?._id,
        secondaryCategory: material.secondaryCategory?._id,
        tertiaryCategory: material.tertiaryCategory?._id,
        mainImageFile: material.mainImageFile?._id,
        files: material.files.map((file: FileDetails) => file._id),
        imageFiles: material.imageFiles.map((file: FileDetails) => file._id),
        quantities: material.quantities?.map((materialQuantity) => ({
          ...materialQuantity,
          _id:
            !materialQuantity._id || materialQuantity._id.includes('local')
              ? undefined
              : materialQuantity._id,
          plan: materialQuantity.plan?._id,
          room: materialQuantity.room?._id,
        })),
      })
    }
    await this.updateStoredMaterial([material], 'update')

    return material
  }

  deleteMaterialById = async (materialId: string) => {
    const materials = this.store.getValue().list
    const deletedMaterial = materials.find(
      (material: Material) => material._id === materialId,
    ) as Material

    if (!Constants.getIsLocal()) {
      await NetworkUtils.checkConnected()
      await MaterialsApi.remove(materialId)
    }

    await this.updateStoredMaterial([deletedMaterial], 'delete')
  }
  filterList(filters: MaterialsApi.ActionListParams): Material[] {
    const materials = this.store.getValue().list
    // only filter if we are on the catalog page
    if (!materials?.length || materials[0].catalog._id !== filters?.catalog) {
      return materials
    }

    // force only available filter (to make sure you will update when a new filter is needed)
    Object.keys(filters).forEach((key: string) => {
      if (
        ![
          'platform',
          'type',
          'owned',
          'public',
          'catalog',
          'selectAll',
          'ids',
          'tertiaryCategory',
          'secondaryCategory',
          'primaryCategory',
          'visible',
          'defaultRetrieval',
          'search',
          'page',
          'itemsPerPage',
          'disablePaginate',
        ].includes(key) &&
        (filters as any)[key]
      ) {
        throw new Error('others filters are not yet implemented for detail pages')
      }
    })

    return materials.filter((material: Material) => {
      let match =
        (filters.selectAll && !filters.ids.find((id) => material._id === id)) ||
        (!filters.selectAll && filters.ids.find((id) => material._id === id))
      if (filters.tertiaryCategory) {
        match = filters.tertiaryCategory === material.tertiaryCategory._id
      } else if (filters.secondaryCategory) {
        match = filters.secondaryCategory === material.secondaryCategory._id
      } else if (filters.primaryCategory) {
        match = filters.primaryCategory === material.primaryCategory._id
      }

      if (filters.platform) {
        match = match && material.catalog.organization.platform === filters.platform
      }

      if (filters.visible === true) {
        match = match && material.visible !== false
      } else if (filters.visible === false) {
        match = match && material.visible === false
      }

      if (filters.defaultRetrieval === false) {
        match = match && material.retrieval?.fromDefault === false
      } else if (filters.defaultRetrieval === true) {
        match = match && material.retrieval?.fromDefault !== false
      }

      if (filters.search) {
        const regexp = new RegExp(filters.search, 'gi')
        match = match && (regexp.test(material.name) || regexp.test(material.reference))
      }

      return match
    })
  }
  deleteMaterials = async (filters: MaterialsApi.ActionListParams): Promise<void> => {
    const deletedFromCurrentCatalog = this.filterList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })

    if (!Constants.getIsLocal()) {
      await NetworkUtils.checkConnected()
      await MaterialsApi.removeMany({
        ...filters,
        ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
      })
    }

    if (deletedFromCurrentCatalog.length) {
      await this.updateStoredMaterial(deletedFromCurrentCatalog, 'delete')
    }
  }
  updateMaterials = async (
    filters: MaterialsApi.ActionListParams,
    updatedMaterial: Partial<ManageMultipleMaterial>,
  ): Promise<void> => {
    const { name, imageFiles, mainImageFile, files, catalog, quantities, ...update } =
      updatedMaterial
    const updateFromCurrentCatalog = this.filterList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })

    if (!Constants.getIsLocal()) {
      await MaterialsApi.updateMany(
        {
          ...filters,
          ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
        },
        {
          ...update,
          primaryCategory: update.primaryCategory?._id,
          secondaryCategory: update.secondaryCategory?._id,
          tertiaryCategory: update.tertiaryCategory?._id,
        },
      )
    }

    if (updateFromCurrentCatalog) {
      await this.updateStoredMaterial(
        updateFromCurrentCatalog.map((material) => {
          let updateCopy: any = ObjectUtils.copy(update)
          Object.keys(updateCopy).forEach((key: string) => {
            if (updateCopy[key] !== undefined) {
              if (key === 'cerfaResource') {
                material.cerfaResource = {
                  ...material.cerfaResource,
                  ...updateCopy.cerfaResource,
                }
              } else if (key === 'cerfaWaste') {
                if (material.cerfaWaste && material.cerfaWaste.length > 0) {
                  material.cerfaWaste[0] = {
                    ...material.cerfaWaste[0],
                    ...updateCopy.cerfaWaste,
                  }
                } else {
                  material.cerfaWaste = [updateCopy.cerfaWaste]
                }
              } else {
                ;(material as any)[key] = updateCopy[key]
              }
            }
          })
          return material
        }),
        'update',
      )
    }
  }
  transferMaterials = async (
    filters: MaterialsApi.ActionListParams,
    catalog: CatalogLight,
  ): Promise<void> => {
    if (Constants.getIsLocal()) {
      // WARNING IF WE IMPLEMENT THIS WE CAN NOT ANMORE USE updateStoredMaterial(updateFromCurrentCatalog, 'delete')
      //  <= must update json and not only delete them
      throw new Error('Local transfer are not yet implemented')
    }
    const updateFromCurrentCatalog = this.filterList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })

    await MaterialsApi.transfer(
      {
        ...filters,
        ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
      },
      catalog._id,
    )

    if (updateFromCurrentCatalog.length) {
      // Only possible to use because !Constants.getIsLocal()
      await this.updateStoredMaterial(updateFromCurrentCatalog, 'delete')
    }
  }
  acceptOrder = async (order: Order) => {
    const materials = this.store.getValue().list

    await this.updateStoredMaterial(
      materials.map((material) => {
        order.products.forEach((product) => {
          // on public catalog we do not have material.quantities
          if (material._id === product.material._id && material.quantities) {
            material.quantities.push({
              _id: '',
              initial: false,
              type: QuantityType.order,
              order: order._id,
              quantity: -product.quantity,
              description: `n° ${order.orderNumber}`,
            })
            material = {
              ...material,
              ...computeResourceQuantities(material.quantities),
            }
          }
        })

        return material
      }),
      'update',
    )
  }
  finishOrder = async (order: Order) => {
    const materials = this.store.getValue().list

    await this.updateStoredMaterial(
      materials.map((material) => {
        // on public catalog we do not have material.quantities
        if (material.quantities) {
          let product = order.products.find((product) => material._id === product.material._id)
          let quantityIndex = material.quantities.findIndex(
            (quantity) => quantity.order === order._id,
          )
          if (product && quantityIndex !== -1) {
            material.quantities[quantityIndex].quantity = -product.quantity
          } else if (product && quantityIndex === -1) {
            material.quantities.push({
              _id: '',
              initial: false,
              type: QuantityType.order,
              order: order._id,
              quantity: -product.quantity,
              description: `n° ${order.orderNumber}`,
            })
          } else if (!product && quantityIndex !== -1) {
            material.quantities.splice(quantityIndex, 1)
          }

          material = {
            ...material,
            ...computeResourceQuantities(material.quantities),
          }
        }

        return material
      }),
      'update',
    )
  }
}

export const materialsService = new MaterialsService()
