import { ReactNode, useState } from 'react'
import { Menu as MenuIcon, Edit as EditIcon } from '@mui/icons-material'
import { MenuItem, Menu, styled, Typography, IconButton, Box, Button } from '@mui/material'
import Stack from './Stack.common'
import {
  Action,
  Action as ActionInterface,
  Actions as ActionsInterface,
  MenuAction,
} from '../../models/props.models'

const OptionLabel = styled(Typography)<{ color?: string }>(({ color, theme }) => ({
  fontSize: '0.75rem',
  color: color
    ? (theme.palette as any)[color]?.main || (theme.palette as any)[color] || color
    : 'inherit',
  fontWeight: 500,
}))

interface ActionsProps {
  action: ActionsInterface
}

const MenuItems: React.FC<{ items: Action[]; setAnchorEl: any; group?: boolean }> = ({
  items,
  setAnchorEl,
  group,
}) => {
  return (
    <>
      {items.map((item, i) => (
        <MenuItem
          key={i}
          onClick={(evt) => {
            evt.stopPropagation()
            item.onClick(evt)
            setAnchorEl(null)
          }}>
          {item.custom ? (
            item.custom
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              padding={group ? '0 12px' : undefined}>
              {item.icon}
              <OptionLabel color={item.color}> {item.label}</OptionLabel>
            </Stack>
          )}
        </MenuItem>
      ))}
    </>
  )
}

const Actions: React.FC<ActionsProps> = ({ action }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { items, ...actionProps } = action as MenuAction
  const isMenu = items?.length > 0
  const menuGroups = isMenu
    ? items.reduce((groups: { label: string; color?: string }[], item) => {
        if (item.group && !groups.find((group) => item.group === group.label)) {
          groups.push({ color: item.color, label: item.group })
        }
        return groups
      }, [])
    : []
  const base: ActionInterface = (
    isMenu
      ? {
          ...(!actionProps.label && !actionProps.icon
            ? { icon: <MenuIcon color="primary" /> }
            : {}),
          ...actionProps,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
        }
      : { icon: <EditIcon />, color: 'primary', ...actionProps }
  ) as ActionInterface

  return (
    <>
      <Box
        onClick={(event) => {
          event.stopPropagation()
          base.onClick(event)
        }}>
        {base.custom ? (
          base.custom
        ) : base.label ? (
          <Button variant="contained" color="primary" startIcon={base.icon}>
            {base.label}
          </Button>
        ) : (
          <IconButton color={base.color}>{base.icon}</IconButton>
        )}
      </Box>
      {isMenu && (
        <Menu
          onClick={(event) => event.stopPropagation()}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={setAnchorEl.bind(null, null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {menuGroups.length === 0 && <MenuItems items={items} setAnchorEl={setAnchorEl} />}
          {menuGroups.length > 0 &&
            menuGroups.reduce((res: ReactNode[], group, groupIndex) => {
              res.push(
                <Typography
                  key={'group' + groupIndex}
                  color={group.color}
                  fontSize="18"
                  fontWeight="700"
                  padding="12px 12px 0 12px">
                  {group.label}
                </Typography>,
              )
              res.push(
                <MenuItems
                  key={'items' + groupIndex}
                  items={items.filter((item) => item.group === group.label)}
                  group
                  setAnchorEl={setAnchorEl}
                />,
              )
              return res
            }, [])}
        </Menu>
      )}
    </>
  )
}

export default Actions
