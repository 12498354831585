import { useTranslation } from 'react-i18next'
import { useObservable } from '@ngneat/react-rxjs'
import { alpha, Box, Button, styled, Typography, Grid, useMediaQuery } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles/index.js'

import Constants from '../constants'

import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import useSnackbar from '../hooks/useSnackbar.hooks'
import { Mode, Route } from '../models/commons.models'

import { CatalogLight, isNeeds } from '../models/catalogs.models'
import { getPlatform } from '../models/platforms.models'
import { catalogsService } from '../store/catalogs'
import { sessionQuery, sessionService } from '../store/session'

import Stack from '../components/common/Stack.common'
import LoadingComponent from '../components/common/Loader.common'
import SearchComponent from '../components/common/input/Search.input'
import MapComponent from '../components/common/Map.common'
import CatalogCard from '../components/catalog/Card.catalog'
import { useEffect, useState } from 'react'
import News from '../components/session/news.session'

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `url(${Constants.resources.home})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  minHeight: '100vh',
  marginTop: '-80px',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
  '&::before': {
    zIndex: 0,
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 'calc(100%)',
    top: '0px',
    left: '0px',
    backgroundColor: alpha(theme.palette.black, 0.4),
  },
}))

const MapContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '0 100px',
  marginTop: '80px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '50px',
    padding: '0 80px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '30px',
    padding: '0 50px',
  },
  [theme.breakpoints.only('xs')]: {
    marginTop: '20px',
    padding: '0 25px',
  },
}))

const HeaderContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  padding: '0 100px',
  flexWrap: 'wrap',
  minHeight: '50vh',
  maxWidth: '100vw',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    padding: '0 50px',
  },
  [theme.breakpoints.only('xs')]: {
    padding: '0 25px',
  },

  '& span > .MuiTypography-root:first-of-type': {
    zIndex: 1,
    color: theme.palette.white,
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'pre-line',
    lineHeight: 1.25,
    maxWidth: '1300px',
    fontSize: '3.75rem',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100vw',
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      fontSize: '2rem',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100vw',
      fontSize: '1.5rem',
    },
  },
  '& span > .MuiTypography-root:nth-of-type(2)': {
    color: theme.palette.white,
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1.25,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },

  '& .MuiButton-root': {
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    fontWeight: 500,
    flexShrink: 0,
    color: theme.palette.primary.main,

    height: '50px',
    padding: '0 15px',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.white,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      fontSize: '0.85rem',
      padding: '0 10px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 5px',
      height: '30px',
      fontSize: '0.7rem',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    height: '50px !important',
    '& input::placeholder': {
      textAlign: 'center',
    },
    '& svg': {
      transform: 'scale(1.4)',
      [theme.breakpoints.only('xs')]: {
        transform: 'scale(0.8)',
      },
    },
    '& svg #main-group': {
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      height: '40px !important',
      fontSize: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      height: '30px !important',
      fontSize: '0.7rem',
    },
  },
  '& .MuiTextField-root': {
    borderRadius: 0,
  },
  '& .MuiOutlinedInput-adornedStart': {
    margin: '0 10px',
  },
  '& .MuiOutlinedInput-inputAdornedStart': {
    marginLeft: 10,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '.9375rem !important',
  },
}))

const HomeTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.home.title,
  fontSize: '1.875rem',
  fontWeight: 800,
  marginBottom: '45px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '35px',
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
    fontSize: '1.25rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1rem',
    marginBottom: '15px',
  },
}))

const DownIcon = styled(Box, { shouldForwardProp: (props) => props !== 'black' })<{
  black: boolean
}>(({ theme, black }) => ({
  alignItems: 'center',
  backgroundColor: black ? theme.palette.black : theme.palette.primary.main,
  borderRadius: '50%',
  bottom: '40px',
  display: 'flex',
  height: '52px',
  justifyContent: 'center',
  position: 'absolute',
  width: '52px',
}))

const EndButton = styled(Button, { shouldForwardProp: (props) => props !== 'black' })<{
  black: boolean
}>(({ theme, black }) => ({
  backgroundColor: black ? theme.palette.black : theme.palette.primary.main,
  clipPath: 'polygon(0 0, 100% 0, 100% 75%, 30px 80px, 0 100%, 0% 75%)',
  color: theme.palette.white,
  fontSize: 20,
  fontWeight: 800,
  height: '110px',
  padding: '0 60px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.only('xs')]: {
    padding: '0 30px',
    fontSize: '0.85rem',
  },
  textTransform: 'uppercase',
  '&:hover': {
    background: `
      linear-gradient(90deg, ${alpha(theme.palette.white, 0.15)} 0%, ${alpha(
      theme.palette.white,
      0.15,
    )} 100%),
      ${black ? theme.palette.black : theme.palette.primary.main}
    `,
  },
  '& > :first-of-type': {
    marginTop: '-25px',
  },
}))

const CycleSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10,
  '&::before': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    width: '100%',
    height: 'calc(100% + 70px)',
    top: '-70px',
    [theme.breakpoints.down('lg')]: {
      top: '-60px',
    },
    [theme.breakpoints.down('md')]: {
      top: '-50px',
    },
    [theme.breakpoints.only('xs')]: {
      top: '-40px',
    },
    left: '0px',
    backgroundColor: theme.palette.home.cycleBackground,
    clipPath: 'polygon(0 180px, 100% 0, 100% 100%, 0% 100%)',
  },
  padding: '0 100px',
  marginTop: '150px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '100px',
    padding: '0 80px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '80px',
    padding: '0 50px',
  },
  [theme.breakpoints.only('xs')]: {
    marginTop: '50px',
    padding: '0 25px',
  },
}))

const EndSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.home.cycleBackground,
  paddingTop: '100px',
  paddingBottom: '65px',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    paddingTop: '80px',
    paddingBottom: '55px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '60px',
    paddingBottom: '45px',
  },
  [theme.breakpoints.only('xs')]: {
    paddingTop: '20px',
    paddingBottom: '25px',
  },
}))

const Cycle = styled(Stack)({
  alignItems: 'center',
  marginBottom: '15px',
  '& img:first-of-type': {
    height: '160px',
    objectPosition: 'center',
    objectFit: 'contain',
    width: '100%',
  },
})

const CycleTitleDesc = styled(Typography)({ whiteSpace: 'pre-wrap', textAlign: 'center' })
const CycleTitle = styled(Typography)<{
  type: 'deconstruction' | 'construction'
  step: '1' | '2' | '3'
}>(({ type, step, theme }) => ({
  fontSize: '1.375rem',
  fontWeight: 800,
  textAlign: 'center',
  color: theme.palette.home[`${type}Cycle`][`step${step}`],
}))
const TitleContainer1 = styled(Box)(({ theme }) => ({
  padding: '10px 20px',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '1rem',
  backgroundColor: theme.palette.home.deconstructionCycle.title,
  color: theme.palette.white,
  marginBottom: '50px !important',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '35px !important',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px !important',
  },
  [theme.breakpoints.only('xs')]: {
    marginBottom: '15px !important',
  },
}))
const TitleContainer2 = styled(Box)(({ theme }) => ({
  padding: '10px 20px',
  fontWeight: 600,
  textAlign: 'center',
  fontSize: '1rem',
  backgroundColor: theme.palette.home.constructionCycle.title,
  color: theme.palette.white,
  marginBottom: '50px !important',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '35px !important',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px !important',
  },
  [theme.breakpoints.only('xs')]: {
    marginBottom: '15px !important',
  },
}))

const LoaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.black, 0.6),
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 50,
})) as typeof Box

const Home = () => {
  useCheckRoute('Home', [Mode.front, Mode.storeFront])
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const { t } = useTranslation()
  const { goTo, getPath } = useRoute()
  const show = useSnackbar()
  const [user] = useObservable(sessionQuery.user)
  const [catalogs, setCatalogs] = useState<CatalogLight[]>([])
  const [loadingCatalogs, seLoadingCatalogs] = useState<boolean>(false)

  const platform = getPlatform(Constants.platform)
  const blackFooter = !!platform.blackFooter

  useEffect(() => {
    const getCatalogs = async () => {
      seLoadingCatalogs(true)
      try {
        const { data } = await catalogsService.getCatalogs({
          disablePaginate: true,
          public: true,
          ended: false,
        })
        setCatalogs(data)
      } catch (err: any) {
        show(err)
      }
      seLoadingCatalogs(false)
    }
    getCatalogs()
  }, [show])

  return (
    <Box>
      <MainContainer>
        <HeaderContentContainer>
          <span>
            <Typography>{t('global:pages.home.title')}</Typography>
            <Typography>{t('global:pages.home.subtitle')}</Typography>
          </span>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            marginTop="25px"
            spacing={2}
            width="100%">
            <Grid item xs={10} sm={7} md={4} lg={3} xl={2} display="flex">
              <Button
                variant="contained"
                fullWidth
                onClick={() => goTo({ route: Route.publicCatalogs })}>
                {t('global:pages.home.discoverCatalogs')}
              </Button>
            </Grid>
            <Grid item xs={10} sm={7} md={4} lg={3} xl={2}>
              <Box>
                <SearchComponent
                  fullWidth
                  placeholder={t('global:pages.home.searchMaterial')}
                  onChange={(value) => {
                    sessionService.setFilter(getPath({ route: Route.publicMaterials }), {
                      search: value,
                    })
                    sessionService.setFilter(getPath({ route: Route.publicResources }), {
                      search: value,
                    })
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      goTo({ route: Route.publicMaterials })
                    }
                  }}
                />
              </Box>
            </Grid>
            {Constants.mode !== Mode.storeFront && (
              <Grid item xs={10} sm={7} md={4} lg={3} xl={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    if (!user) {
                      show(t('global:pages.home.notLogged'), 'warning')
                    } else {
                      goTo({ route: Route.workspace }, false, { tab: '1', modal: 'add' })
                    }
                  }}>
                  {t(`global:pages.home.createCatalog`)}
                </Button>
              </Grid>
            )}
          </Grid>
        </HeaderContentContainer>
        <DownIcon black={blackFooter}>
          <KeyboardArrowDown htmlColor="white" />
        </DownIcon>
      </MainContainer>
      {platform.news && platform.news.length > 0 && (
        <Box px={onlyXs ? '20px' : '70px'} mt={onlyXs ? '40px' : '140px'}>
          <HomeTitle>{t('global:pages.home.news')}</HomeTitle>
          <News />
        </Box>
      )}
      <MapContainer>
        <HomeTitle>{t('global:pages.home.projects')}</HomeTitle>
        <Stack
          bgcolor="darkgrey"
          height="875px"
          maxHeight="100vw"
          width="100%"
          justifyContent="center"
          alignItems="center"
          position="relative">
          <MapComponent
            showPreview
            hideAutocomplete
            markers={catalogs.map((catalog) => ({
              id: catalog._id,
              secondary: isNeeds(catalog.type),
              onClick: () => {
                goTo({ route: Route.publicCatalog, catalogId: catalog._id })
              },
              preview: () => (
                <CatalogCard
                  value={catalog}
                  isPublic
                  onClick={() => goTo({ route: Route.publicCatalog, catalogId: catalog._id })}
                />
              ),
              lat: catalog.retrieval.location.position[0],
              lng: catalog.retrieval.location.position[1],
            }))}
          />
          {loadingCatalogs && (
            <LoaderContainer>
              <LoadingComponent size={50} />
            </LoaderContainer>
          )}
        </Stack>
      </MapContainer>
      {Constants.mode !== Mode.storeFront && (
        <CycleSection>
          <HomeTitle>{t('global:pages.home.cycle')}</HomeTitle>
          <Grid
            container
            flexDirection="row"
            width="100%"
            display="flex"
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="space-around"
            marginBottom="50px">
            <Grid item maxWidth="450px" display="flex" flexDirection="column" alignItems="center">
              <TitleContainer1>{t('global:pages.home.deconstruction.title')}</TitleContainer1>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle1} />
                <CycleTitle type="deconstruction" step="1">
                  {t('global:pages.home.deconstruction.step1Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.deconstruction.step1Description')}
                </CycleTitleDesc>
              </Cycle>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle2} />
                <CycleTitle type="deconstruction" step="2">
                  {t('global:pages.home.deconstruction.step2Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.deconstruction.step2Description')}
                </CycleTitleDesc>
              </Cycle>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle3} />
                <CycleTitle type="deconstruction" step="3">
                  {t('global:pages.home.deconstruction.step3Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.deconstruction.step3Description')}
                </CycleTitleDesc>
              </Cycle>
            </Grid>
            <Grid item maxWidth="450px" display="flex" flexDirection="column" alignItems="center">
              <TitleContainer2>{t('global:pages.home.construction.title')}</TitleContainer2>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle4} />
                <CycleTitle type="construction" step="1">
                  {t('global:pages.home.construction.step1Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.construction.step1Description')}
                </CycleTitleDesc>
              </Cycle>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle5} />
                <CycleTitle type="construction" step="2">
                  {t('global:pages.home.construction.step2Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.construction.step2Description')}
                </CycleTitleDesc>
              </Cycle>
              <Cycle spacing="10px">
                <img alt="step1" src={Constants.resources.cycle6} />
                <CycleTitle type="construction" step="3">
                  {t('global:pages.home.construction.step3Title')}
                </CycleTitle>
                <CycleTitleDesc>
                  {t('global:pages.home.construction.step3Description')}
                </CycleTitleDesc>
              </Cycle>
            </Grid>
          </Grid>
        </CycleSection>
      )}
      <EndSection>
        <EndButton
          black={blackFooter}
          onClick={() => {
            goTo({ route: Route.publicMaterials })
          }}>
          <Box>{t('global:pages.home.searchMaterial')}</Box>
        </EndButton>
      </EndSection>
    </Box>
  )
}

export default Home
