import { memo } from 'react'
import { Box, Button, FormHelperText, Typography } from '@mui/material'
import { ItemType, Item } from '../../../models/props.models'
import { YupUtils } from '../../../utils/yup.utils'
import Radio from '../input/Radio.input'
import TextField from '../input/Text.input'
import NumberTextField from '../input/Number.input'
import Select from '../input/Select.input'
import Address from '../input/Address.input'
import AddressRadius from '../input/AddressRadius.input'
import LinkButton from '../button/Link.button'
import Checkbox from '../input/Checkbox.input'
import Interval from '../input/Interval.input'
import AsyncSelectWithSearch from '../input/AsyncSelect.input'
import File from '../input/File.input'
import Image from '../input/Image.input'
import Documents from '../input/Documents.input'
import DateInput from '../input/Date.input'
import InfoItem from './Info.item'
import GroupItem from './Group.item'
import Search from '../input/Search.input'
import MultiSelect from '../input/MultiSelect.input'
import Array from '../input/Array.input'
import Chip from '../Chip.common'
import Tooltip from '../Tooltip.common'
import DirectoryInput from '../input/Directory.input'
import AsyncMultiSelect from '../input/AsyncMultiSelect.input'
import Autocomplete from '../input/Autocomplete.input'

const TypedItem: React.FC<Omit<Item & { children?: JSX.Element | JSX.Element[] }, 'key'>> = ({
  required,
  error,
  value,
  onChange,
  type,
  formatValue,
  children,
  custom,
  props,
  items,
}) => {
  const getValue = () => {
    const defaultVal =
      type === ItemType.multiSelect ||
      type === ItemType.autocomplete ||
      type === ItemType.array ||
      (type === ItemType.radio && (props as any)?.multiple) ||
      type === ItemType.documents ||
      type === ItemType.asyncMultiSelect
        ? []
        : ''
    return formatValue ? formatValue(value) : value ?? defaultVal
  }
  const itemProps: any = {
    ...(props ?? {}),
    onChange: onChange ?? (props as any)?.onChange,
    required,
    value: getValue(),
    children:
      type === ItemType.link && formatValue?.(value)
        ? formatValue(value)
        : (props as any)?.children,
    error:
      type === ItemType.link || type === ItemType.button || type === ItemType.info
        ? undefined
        : type === ItemType.group && error
        ? (error as Record<string, any>)?.global || YupUtils.checkIfErrors(error)
        : error,
  }

  return (
    <>
      {type === ItemType.typo && (
        <Box display="flex" alignItems="center">
          {itemProps?.icon && (
            <img alt="" height="24px" src={itemProps?.icon} style={{ marginRight: '5px' }} />
          )}
          <Typography variant="body1" {...itemProps}>
            {getValue()}
            {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
          </Typography>
        </Box>
      )}
      {type === ItemType.multiSelect && <MultiSelect size="large" {...itemProps} />}
      {type === ItemType.autocomplete && <Autocomplete {...itemProps} />}
      {type === ItemType.array && <Array items={items} {...itemProps} />}
      {type === ItemType.search && <Search {...itemProps} />}
      {type === ItemType.checkbox && <Checkbox {...itemProps} />}
      {type === ItemType.date && <DateInput {...itemProps} />}
      {type === ItemType.file && <File {...itemProps} />}
      {type === ItemType.directory && <DirectoryInput {...itemProps} />}
      {type === ItemType.address && <Address {...itemProps} />}
      {type === ItemType.addressRadius && <AddressRadius {...itemProps} />}
      {type === ItemType.select && <Select {...itemProps} />}
      {type === ItemType.text && <TextField {...itemProps} />}
      {type === ItemType.number && <NumberTextField {...itemProps} />}
      {type === ItemType.radio && <Radio {...itemProps} />}
      {type === ItemType.interval && <Interval {...itemProps} />}
      {type === ItemType.asyncSelect && <AsyncSelectWithSearch {...itemProps} />}
      {type === ItemType.asyncMultiSelect && <AsyncMultiSelect {...itemProps} />}
      {type === ItemType.image && (
        <Box width="100%" maxWidth="500px" margin="auto">
          <Image {...itemProps} />
        </Box>
      )}
      {type === ItemType.documents && <Documents {...itemProps} />}
      {type === ItemType.link && <LinkButton {...itemProps} />}
      {type === ItemType.button && (
        <Button color="primary" size="large" variant="contained" {...itemProps} />
      )}
      {type === ItemType.info && <InfoItem {...itemProps} />}
      {type === ItemType.chip && <Chip {...itemProps} />}
      {type === ItemType.custom && (custom || <></>)}
      {type === ItemType.group && <GroupItem {...itemProps}>{children}</GroupItem>}
      {type === ItemType.tooltip && <Tooltip {...itemProps} />}
    </>
  )
}
export default memo(TypedItem)
