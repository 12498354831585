import { Grid, Box, styled, Typography, Button } from '@mui/material'
import Number from '../common/input/Number.input'
import { useTranslation } from 'react-i18next'

import { useMemo, useState } from 'react'
import { Unit } from '../../models/materials.models'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface AddToCartProps {
  addToCart?: (quantity: number) => void
  minQuantity: number
  sellByQuantityOf: number
  currentQty: number
  unit: Unit
}

const AddToCart: React.FC<AddToCartProps> = ({
  addToCart,
  minQuantity,
  sellByQuantityOf,
  currentQty,
  unit,
}) => {
  const computedMinQuantity = useMemo(
    () => minQuantity || sellByQuantityOf || 1,
    [minQuantity, sellByQuantityOf],
  )
  const [quantity, setQuantity] = useState(computedMinQuantity)
  const { t } = useTranslation()

  return (
    <Box display="flex" alignItems="center" mt="10px">
      <Grid
        container
        spacing={1}
        onClick={(e: any) => {
          e.stopPropagation()
        }}>
        <Grid item xs={5}>
          <Number
            step={sellByQuantityOf}
            min={computedMinQuantity}
            max={currentQty}
            endAdornment={
              <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
            }
            showControl
            value={quantity}
            onChange={(q?: number) => setQuantity(q || 0)}
          />
        </Grid>
        <Grid item xs={7}>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            color="primary"
            disabled={quantity <= 0 || quantity > currentQty}
            onClick={addToCart?.bind(null, quantity)}>
            {t('materials:actions.addToCart.label')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddToCart
