import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import PageRequestList from '../../../components/page/RequestList.page'

const RequestReceivedList = () => {
  useCheckRoute('RequestReceivedList', [Mode.front])

  return <PageRequestList received withTabs />
}
export default RequestReceivedList
