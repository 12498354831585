import Constants from '../../constants'
import { Location } from '../../models/commons.models'

import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import i18n from '../../utils/i18n.utils'

export class GoogleService {
  private getAddressValue = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string,
    shortName = false,
  ) => {
    const composant = addressComponents.find((a) => a.types.includes(type))
    return (shortName ? composant?.short_name : composant?.long_name) ?? ''
  }

  async waitForGoogle() {
    return (await NetworkUtils.isConnected()) && !window.google?.maps?.places?.AutocompleteService
  }

  getSelectedPrediction = async (
    address: string,
  ): Promise<google.maps.places.AutocompletePrediction | undefined> => {
    try {
      await NetworkUtils.checkConnected()

      if (!window.google?.maps?.places?.AutocompleteService) {
        return undefined
      }

      const autocompleteService = new window.google.maps.places.AutocompleteService()
      const response = await autocompleteService.getPlacePredictions({
        input: address,
        language: i18n.language,
        ...(Constants.specificCountry && {
          componentRestrictions: { country: Constants.specificCountry },
        }),
      })

      return response.predictions?.[0]
    } catch (err: any) {
      logIfDev(err)
      return undefined
    }
  }

  getAddressDetails = async (placeId: string): Promise<Location | undefined> => {
    try {
      await NetworkUtils.checkConnected()

      if (!window.google?.maps?.Geocoder) {
        return undefined
      }

      const geocoder = new window.google.maps.Geocoder()
      const response = await geocoder.geocode({ placeId, language: i18n.language })
      const addressDetails = response.results[0]
      let countryCode = this.getAddressValue(addressDetails.address_components, 'country', true)

      return {
        countryCode,
        fullAddress: addressDetails.formatted_address,
        city: this.getAddressValue(addressDetails.address_components, 'locality'),
        postalCode: this.getAddressValue(addressDetails.address_components, 'postal_code'),
        position: [addressDetails.geometry.location.lng(), addressDetails.geometry.location.lat()],
      }
    } catch (err: any) {
      logIfDev(err)
      return undefined
    }
  }
}

export const googleService = new GoogleService()
