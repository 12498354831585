import { CatalogType } from '../../models/catalogs.models'

import PageCatalogList from '../../components/page/CatalogList.page'
import { FilterableRoute } from '../../models/commons.models'
const WorkspaceNeedsPage = () => {
  return (
    <PageCatalogList
      withTabs
      withGroups
      types={[CatalogType.needs]}
      filterKey={FilterableRoute.workspaceNeeds}
    />
  )
}
export default WorkspaceNeedsPage
