import { useTranslation } from 'react-i18next'
import { Request, RequestStatus, RequestType } from '../../models/requests.models'
import { Organization } from '../../models/organizations.models'
import Details from '../common/Details.common'
import Modal from '../layout/Modal.layout'
import { Box, Button, DialogActions, Grid } from '@mui/material'
import { useMemo, useState } from 'react'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import Loader from '../common/Loader.common'

interface RequestDetailsModalProps {
  request: Request
  sent?: boolean
  received?: boolean
  onClose: () => void
  hasSenderRights?: (status: Request) => boolean
  hasReceiverRights?: (status: Request) => boolean
  onUpdate?: ((status: RequestStatus) => Promise<void>) | ((status: RequestStatus) => void)
}

const RequestDetailsModal = ({
  onUpdate,
  request,
  sent,
  received,
  onClose,
  hasSenderRights,
  hasReceiverRights,
}: RequestDetailsModalProps) => {
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState(false)
  const launchUpdate = async (status: RequestStatus) => {
    setLoading(true)
    try {
      await onUpdate?.(status)
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }
  const receiverRights = useMemo(() => {
    return hasReceiverRights?.(request)
  }, [request, hasReceiverRights])
  const senderRights = useMemo(() => {
    return hasSenderRights?.(request)
  }, [request, hasSenderRights])

  return (
    <Modal
      maxWidth="sm"
      onClose={onClose}
      hideAction
      title={t(`requests:components.modalDetails.title.${request.type}`, {
        catalog: request?.catalog?.name,
        user: request.user
          ? `${request.user.firstname} ${request.user.lastname} (${request.user?.email})`
          : '',
      })}>
      <>
        <Details
          noElevation
          value={request}
          sx={{ width: '100%' }}
          items={[
            {
              label: t('requests:attributes.status'),
              key: 'status',
              formatValue: (status: RequestStatus) => t(`requests:status.${status}`),
            },

            ...(!sent && request?.sender
              ? [
                  {
                    label: t('requests:attributes.sender'),
                    key: 'sender',
                    formatValue: (sender: Organization) => sender.name,
                  },
                ]
              : []),
            ...(received
              ? []
              : [
                  {
                    label: t('requests:attributes.receiver'),
                    key: 'receiver',
                    formatValue: (receiver: Organization) =>
                      receiver?.name ?? request?.customReceiver?.organization,
                  },
                ]),
            {
              label: t('requests:attributes.customReceiver.title'),
              key: 'customReceiver',
              formatValue: (customReceiver: any) =>
                customReceiver
                  ? `${customReceiver.firstname} ${customReceiver.lastname} (${customReceiver?.email})`
                  : undefined,
            },
          ]}
        />
        {onUpdate && request.status === RequestStatus.pending && (
          <DialogActions sx={{ width: '100%' }}>
            <Grid container spacing={3} justifyContent="center">
              {request.type !== RequestType.newUser && senderRights && (
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={launchUpdate.bind(null, RequestStatus.canceled)}>
                    {t('requests:actions.canceled.label')}
                    {loading && (
                      <Box position="absolute" right="5px" top="15px">
                        <Loader />
                      </Box>
                    )}
                  </Button>
                </Grid>
              )}
              {receiverRights && (
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={launchUpdate.bind(null, RequestStatus.denied)}>
                    {t('requests:actions.denied.label')}
                    {loading && (
                      <Box position="absolute" right="5px" top="15px">
                        <Loader />
                      </Box>
                    )}
                  </Button>
                </Grid>
              )}
              {!!request.receiver && receiverRights && (
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={launchUpdate.bind(null, RequestStatus.accepted)}>
                    {t('requests:actions.accepted.label')}
                    {loading && (
                      <Box position="absolute" right="5px" top="15px">
                        <Loader />
                      </Box>
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        )}
      </>
    </Modal>
  )
}
export default RequestDetailsModal
