import { Catalog } from '../../../models/catalogs.models'
import { useOutletContext } from 'react-router-dom'
import PageChatList from '../../../components/page/ChatList.page'
import { MessageFrom } from '../../../models/chats.models'
import constants from '../../../constants'
import { Mode } from '../../../models/commons.models'

const CatalogChatTab = () => {
  const { catalog } = useOutletContext<{
    catalog: Catalog
  }>()

  return (
    <PageChatList
      catalog={catalog._id}
      from={constants.mode !== Mode.admin ? MessageFrom.seller : 'admin'}
    />
  )
}
export default CatalogChatTab
