export type Point = [number, number]

export type News = {
  type: string
  title: string
  picture: string
  shortDescription: string
  content: string
  contentImgs: [string]
  publishedAt: string
}
export enum RetrievalModality {
  depositedByYou = 'depositedByYou',
  comePickup = 'comePickup',
}

export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  CHF = 'CHF',
}

export interface Retrieval {
  fromDefault?: boolean
  location: Location
  retrievalModality?: RetrievalModality
  startDate?: Date
  endDate?: Date
}

export interface Location {
  countryCode: string
  fullAddress: string
  position: Point
  city: string
  postalCode: string
}

export interface PaginationParams {
  page?: number
  itemsPerPage?: number
  disablePaginate?: boolean
}
export interface Pagination {
  data: any[]
  total: number
  count: number
}

export const enum Mode {
  admin = 'admin',
  front = 'front',
  storeFront = 'storeFront',
  app = 'app',
}

export enum FilterableRoute {
  workspaceDeconstructions = '/workspace/deconstructions',
  workspaceConstructions = '/workspace/constructions',
  workspaceStorages = '/workspace/storages',
  workspaceNeeds = '/workspace/needs',
  orders = '/orders',
  materialsResources = '/materials/resources',
  materialsNeeds = '/materials/needs',
  materialModels = '/material-models',
  users = '/users',
  organizations = '/organizations',
  requests = '/requests',
  chats = '/chats',
}

export const enum Route {
  news = 'news',
  home = 'home',
  account = 'account',
  cgu = 'cgu',
  rgpd = 'rgpd',
  favorites = 'favorites',
  cart = 'cart',
  login = 'login',
  signup = 'signup',
  users = 'users',
  user = 'user',
  requests = 'requests',
  report = 'report',
  sentRequests = 'sentRequests',
  receivedRequests = 'receivedRequests',
  chats = 'chats',
  sentChats = 'sentChats',
  receivedChats = 'receivedChats',
  organizations = 'organizations',
  organization = 'organization',
  organizationReport = 'organizationReport',
  organizationInformations = 'organizationInformations',
  organizationCatalogs = 'organizationCatalogs',
  organizationResources = 'organizationResources',
  organizationNeeds = 'organizationNeeds',
  organizationBoughtOrders = 'organizationBoughtOrders',
  organizationSoldOrders = 'organizationSoldOrders',
  organizationUsers = 'organizationUsers',
  materialModels = 'materialModels',
  adminOrders = 'adminOrders',
  myOrders = 'myOrders',
  materials = 'materials',
  materialsNeeds = 'materialsNeeds',
  materialsResources = 'materialsResources',
  workspace = 'workspace',
  workspaceOrders = 'workspaceOrders',
  workspaceDeconstructions = 'workspaceDeconstructions',
  workspaceConstructions = 'workspaceConstructions',
  workspaceStorages = 'workspaceStorages',
  workspaceNeeds = 'workspaceNeeds',
  workspaceReport = 'workspaceReport',
  workspaceCatalog = 'workspaceCatalog',
  workspaceCatalogInformations = 'workspaceCatalogInformations',
  workspaceCatalogPlans = 'workspaceCatalogPlans',
  workspaceCatalogMaterials = 'workspaceCatalogMaterials',
  workspaceCatalogOrders = 'workspaceCatalogOrders',
  workspaceCatalogMatching = 'workspaceCatalogMatching',
  workspaceCatalogChat = 'workspaceCatalogChat',
  workspaceCatalogPlan = 'workspaceCatalogPlan',
  workspaceCatalogReport = 'workspaceCatalogReport',
  publicMaterials = 'publicMaterials',
  publicNeeds = 'publicNeeds',
  publicResources = 'publicResources',
  publicCatalogs = 'publicCatalogs',
  publicCatalogsNeeds = 'publicCatalogsNeeds',
  publicCatalogsResources = 'publicCatalogsResources',
  publicCatalog = 'publicCatalog',
  publicCatalogInformations = 'publicCatalogInformations',
  publicCatalogMaterials = 'publicCatalogMaterials',
}
