declare module '@mui/material/styles' {
  interface Palette {
    black: string
    white: string
    purple: string
    textFieldBackground: string
    menuBorder: string
    blockBorder: string
    blockLabel: string
    availableQuantity: string
    desiredQuantity: string
    tabsBorder: string
    lightBorder: string
    tableHeader: string
    searchBackground: string
    gradient1: string
    gradient2: string
    card: string
    card1: string
    card2: string
    stepper: {
      active: string
      background: string
    }
    home: {
      primary: string
      secondary: string
      title: string
      newsType: string
      cycleBackground: string
      arrow: string
      constructionCycle: {
        title: string
        step1: string
        step2: string
        step3: string
      }
      deconstructionCycle: {
        title: string
        step1: string
        step2: string
        step3: string
      }
    }
    roles: {
      SUPER_ADMIN: string
      ADMIN: string
      COMPANY: string
    }
  }
}

export const createPalette = (colors: any) => ({
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  success: {
    main: colors.success,
  },
  error: {
    main: colors.error,
  },
  info: {
    main: colors.info,
  },
  warning: {
    main: colors.warning,
  },
  text: {
    primary: colors.mainText,
    secondary: colors.secondaryText,
  },
  background: {
    default: colors.white,
  },
  //used in styled components
  black: colors.black,
  white: colors.white,
  purple: colors.purple,
  textFieldBackground: colors.textFieldBackground,
  menuBorder: colors.menuBorder,
  blockBorder: colors.blockBorder,
  newsType: colors.newsType,
  blockLabel: colors.blockLabel,
  availableQuantity: colors.availableQuantity,
  desiredQuantity: colors.desiredQuantity,
  tabsBorder: colors.tabsBorder,
  lightBorder: colors.lightBorder,
  tableHeader: colors.tableHeader,
  searchBackground: colors.searchBackground,
  gradient1: colors.gradient1,
  gradient2: colors.gradient2,
  card: colors.card,
  card1: colors.card1,
  card2: colors.card2,
  stepper: colors.stepper,
  home: colors.home,
  roles: colors.roles,
})
