import { Box, Fade, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Language as LanguageIcon } from '@mui/icons-material'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { LangEnum } from '../../constants/langs'
import constants from '../../constants'
import { sessionQuery, sessionService } from '../../store/session'
import CheckboxInput from '../common/input/Checkbox.input'
import { useObservable } from '@ngneat/react-rxjs'

const LayoutLangs: React.FC<{ color?: string }> = ({ color }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onMenuClose = () => setAnchorEl(null)

  const [useImperials] = useObservable(sessionQuery.useImperials)

  const udapteImperial = useCallback(async (useImperials?: boolean) => {
    await sessionService.setUseImperials(!!useImperials)
  }, [])
  const canUseImperials = useRef(!!sessionService.getCountryParam('canUseImperials'))

  const menuItems = useMemo(
    () =>
      Object.keys(LangEnum).map((lang: string) => (
        <MenuItem
          key={lang}
          onClick={() => {
            onMenuClose()
            // @ts-ignore
            sessionService.updateLang(LangEnum[lang])
          }}>
          {/* @ts-ignore */}
          {LangEnum[lang].toUpperCase()}
        </MenuItem>
      )),
    [],
  )

  if (constants.specificCountry) {
    return <></>
  }

  return (
    <>
      <IconButton
        sx={{ color: color }}
        onClick={(evt: React.MouseEvent<HTMLElement>) => setAnchorEl(evt.currentTarget)}>
        <LanguageIcon />
      </IconButton>
      {Boolean(anchorEl) && (
        <Menu
          open
          anchorEl={anchorEl}
          keepMounted
          onClose={onMenuClose}
          TransitionComponent={Fade}
          style={{ marginTop: '50px' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          {!canUseImperials.current ? (
            menuItems
          ) : (
            <Box m="10px">
              <CheckboxInput
                value={useImperials}
                onChange={udapteImperial}
                label={t('users:attributes.useImperials')}
              />
              <Typography mb="5px" color="primary" textAlign="start" mt="5px">
                {t('users:attributes.lang')}
              </Typography>
              {menuItems}
            </Box>
          )}
        </Menu>
      )}
    </>
  )
}

export default LayoutLangs
