import { alpha } from '@mui/material'

export const createTooltip = (colors: any) => ({
  MuiTooltip: {
    tooltip: {
      fontSize: '0.75rem',
      fontWeight: 400,
      padding: '12px',
      color: colors.black,
      backgroundColor: colors.white,
      boxShadow: `0px 2px 20px ${alpha(colors.black, 0.05)}`,
      border: `1px solid ${colors.lightBorder}`,
      borderRadius: '4px',
      width: 'auto',
      maxWidth: '500px',
      whiteSpace: 'pre-line',
    },
    tooltipPlacementTop: {
      bottom: '-8px',
    },
  },
})

export default createTooltip
