import { Mode, Route } from '../../../models/commons.models'
import PageRequestList from '../../../components/page/RequestList.page'

import TabsComponent from '../../../components/common/Tabs.common'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import constants from '../../../constants'
import { AdminHeaderTitle } from '../../../components/layout/AdminHeader.layout'
import { FilterableRoute } from '../../../models/commons.models'

const RequestList = () => {
  useCheckRoute('RequestList', [Mode.admin, Mode.front])
  const { getPath } = useRoute()
  const { t } = useTranslation()

  if (constants.mode !== Mode.admin) {
    return (
      <>
        <TabsComponent
          items={[
            {
              label: t(`global:routes.receivedRequests`),
              path: getPath({
                route: Route.receivedRequests,
              }),
            },
            {
              label: t(`global:routes.sentRequests`),
              path: getPath({
                route: Route.sentRequests,
              }),
            },
          ]}
        />
        <Outlet />
      </>
    )
  }

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.requests')} />
      <PageRequestList filterKey={FilterableRoute.requests} />
    </>
  )
}
export default RequestList
