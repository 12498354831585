import React from 'react'
import { alpha, Box, styled } from '@mui/material'

import Loader from '../common/Loader.common'

const LoaderContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.black, 0.6),
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',

  top: 0,
  width: '100%',
  zIndex: 10000,
})) as typeof Box

const LoaderOverlay: React.FC = (): JSX.Element => {
  return (
    <LoaderContainer>
      <Loader size={50} />
    </LoaderContainer>
  )
}

export default LoaderOverlay
