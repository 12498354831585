import { useOutletContext } from 'react-router-dom'
import { Organization } from '../../../models/organizations.models'
import PageMaterialList from '../../../components/page/MaterialList.page'
import { MaterialType } from '../../../models/materials.models'

const OrganizationMaterialsTab = () => {
  const { organization, useImperials } = useOutletContext<{
    organization: Organization
    useImperials: boolean
  }>()

  return (
    <PageMaterialList
      withTabs
      canUpdate
      organization={organization._id}
      useImperials={useImperials}
      type={MaterialType.need}
    />
  )
}
export default OrganizationMaterialsTab
