import { AxiosResponse } from 'axios'
import { axiosApi } from './base.api'

import { ReccursiveCategory } from '../models/categories.models'

export namespace CategoriesApi {
  export const getList = async (): Promise<ReccursiveCategory[]> => {
    const response: AxiosResponse<ReccursiveCategory[]> = await axiosApi.get(`/categories`)
    return response.data
  }
}
