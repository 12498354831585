import { Document, Image, Page, StyleSheet, Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { User } from '../../models/users.models'
import { getPlatform } from '../../models/platforms.models'

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 65,
    paddingHorizontal: 35,
    paddingTop: 35,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    paddingHorizontal: 65,
    paddingTop: 75,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 40,
    paddingTop: 75,
    textAlign: 'left',
  },
  row: {
    paddingBottom: 5,
  },
  rowHead: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left',
  },
  semiBold: {
    fontFamily: 'Helvetica-Bold',
  },
  text: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    textAlign: 'left',
  },
  bottomTitle: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 170,
    paddingTop: 40,
  },
})

interface VisitCertificateProps {
  user: User
}

const VisitCertificateComponent = (props: VisitCertificateProps) => {
  const { user } = props
  const { t } = useTranslation()
  const platform = getPlatform(user.organization.platform)

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>{t('users:components.templateCertificate.title')}</Text>
        <Text style={styles.subtitle}>
          {t('users:components.templateCertificate.subtitle', { platform: platform.name })}
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>{t('users:components.templateCertificate.gender')}</Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>{t('users:components.templateCertificate.lastName')}</Text>
          <Text style={styles.text}>{` ${user?.lastname}`}</Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>{t('users:components.templateCertificate.firstName')}</Text>
          <Text style={styles.text}>{` ${user?.firstname}`}</Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>
            {t('users:components.templateCertificate.professions')}
          </Text>
          <Text style={styles.text}>
            {`${user?.professions
              ?.map((profression) => t(`users:professions.${profression}`))
              .join(' / ')}` || ''}
          </Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>
            {t('users:components.templateCertificate.organization')}
          </Text>
          <Text style={styles.text}>{`${user?.organization.name || ''}`}</Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>{t('users:components.templateCertificate.siret')}</Text>
          <Text style={styles.text}>{`${user?.organization.siret || ''}`}</Text>
        </Text>
        <Text style={styles.row}>
          <Text style={styles.rowHead}>{t('users:components.templateCertificate.email')}</Text>
          <Text style={styles.text}>{`${user?.email || ''}`}</Text>
        </Text>
        <Text style={styles.bottomTitle}>
          <Text>{t('users:components.templateCertificate.bottomTitlePart1')}</Text>
          <Text style={styles.semiBold}>
            {t('global:format.dateAndHour', { date: new Date() })}
          </Text>
          <Text>{t('users:components.templateCertificate.bottomTitlePart2')}</Text>
        </Text>
        <Image style={{ width: '500px' }} src={platform.certificateLogo ?? platform.logo}></Image>
      </Page>
    </Document>
  )
}

export default VisitCertificateComponent
