import FormSignup from '../components/session/FormSignup.session'
import useRoute from '../hooks/useRoute.hooks'
import useCheckRoute from '../hooks/useCheckRoute.hooks'
import { Route, Mode } from '../models/commons.models'
import { useTranslation } from 'react-i18next'

import { AdminHeaderTitle } from '../components/layout/AdminHeader.layout'

const Signup = () => {
  useCheckRoute('Signup', [Mode.admin, Mode.app])
  const { goTo } = useRoute()
  const { t } = useTranslation()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.signup')} />
      <FormSignup
        onSuccess={() => {
          goTo({ route: Route.login })
        }}
      />
    </>
  )
}
export default Signup
