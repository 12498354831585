import { useEffect } from 'react'
import Constants from '../constants'
import { logIfDev } from '../utils/commons.utils'
import useRoute from './useRoute.hooks'
import { Route, Mode } from '../models/commons.models'

const useCheckRoute = (page: string, availableFor: Mode[]) => {
  const { goTo } = useRoute()

  useEffect(() => {
    if (availableFor.indexOf(Constants.mode) === -1) {
      goTo({
        route:
          Constants.mode === Mode.front || Constants.mode === Mode.storeFront
            ? Route.home
            : Route.workspace,
      })
      logIfDev(new Error(`page ${page} is not accessible for mode ${Constants.mode}`))
    }
  }, [goTo, page, availableFor])
}

export default useCheckRoute
