import { alpha } from '@mui/material'
export const createDialog = (colors: any) => ({
  MuiDialog: {
    root: {
      backgroundColor: alpha(colors.black, 0.6),
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: `${colors.primary} !important`,
      },
    },
  },
  MuiDialogActions: {
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      '& .MuiButton-text': {
        fontSize: '1rem !important',
        fontWeight: 600,
      },
      '&[aria-label=column]': {
        flexDirection: 'column',
      },
    },
    spacing: {
      '& > :not(:first-of-type)': {
        margin: '0 0 0 20px !important',
      },
      '&[aria-label=column] > :not(:first-of-type)': {
        margin: '5px 0 0 0 !important',
      },
    },
  },
})
