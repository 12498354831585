import FormLogin from '../components/session/FormLogin.session'
import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import { Mode, Route } from '../models/commons.models'
import { AdminHeaderTitle } from '../components/layout/AdminHeader.layout'
import { useTranslation } from 'react-i18next'

const Login = () => {
  useCheckRoute('Login', [Mode.admin, Mode.app])
  const { goTo } = useRoute()
  const { t } = useTranslation()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.login')} />
      <FormLogin
        onSuccess={() => {
          goTo({ route: Route.workspace })
        }}
      />
    </>
  )
}
export default Login
