import { Button, styled, alpha } from '@mui/material'

const OutlinedButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: '22px',
  color: theme.palette.black,
  fontSize: '0.875rem',
  fontWeight: 600,
  height: '45px',
  padding: '0 20px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.black, 0.15),
  },
}))
export default OutlinedButton
