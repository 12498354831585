import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { Mode } from '../../../models/commons.models'
import { MaterialType } from '../../../models/materials.models'
import PageMaterialList from '../../../components/page/MaterialList.page'
import { useOutletContext } from 'react-router-dom'

const PublicMaterialResourceList = () => {
  useCheckRoute('PublicMaterialList', [Mode.front, Mode.storeFront])

  const { useImperials } = useOutletContext<{
    useImperials: boolean
  }>()

  return <PageMaterialList useImperials={useImperials} isPublic type={MaterialType.resource} />
}
export default PublicMaterialResourceList
