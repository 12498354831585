export const createChip = (colors: any) => ({
  MuiChip: {
    root: {
      borderRadius: '4px',
      color: colors.white,
      fontSize: '0.75rem',
      fontWeight: 400,
      height: '25px',
      padding: '0 5px',
      '&.MuiChip-colorDefault': {
        backgroundColor: colors.black,
      },
    },
  },
})
