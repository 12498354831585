import { useState, useRef, useEffect, useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Switch, Grid, Button, Box, Typography } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'

import useModal from '../../../hooks/useModal.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'

import { Route } from '../../../models/commons.models'
import {
  ManageCatalog,
  CatalogStatus,
  Catalog,
  NetworkStatus,
  CatalogIcon,
  isResources,
  CatalogType,
} from '../../../models/catalogs.models'
import { ItemType } from '../../../models/props.models'
import { User } from '../../../models/users.models'
import {
  Request,
  RequestStatus,
  hasSenderRights,
  hasReceiverRights,
} from '../../../models/requests.models'

import { sessionService } from '../../../store/session'
import { requestsService } from '../../../store/requests'
import { catalogsService } from '../../../store/catalogs'
import Double from '../../../components/layout/Double.layout'
import InformationCatalog from '../../../components/catalog/Information.catalog'
import InformationDocumentCatalog from '../../../components/catalog/InformationDocument.catalog'
import DetailsComponent from '../../../components/common/Details.common'
import ModalFormCatalog from '../../../components/catalog/ModalForm.catalog'
import LoaderOverlay from '../../../components/layout/LoaderOverlay.layout'
import Modal from '../../../components/layout/Modal.layout'
import ModalFormRequest from '../../../components/request/ModalForm.request'
import RequestDetailsModal from '../../../components/request/ModalDetails.request'
import Tooltip from '../../../components/common/Tooltip.common'
import DateInput from '../../../components/common/input/Date.input'

const CatalogInformationsTab: React.FC = () => {
  const { goTo } = useRoute()
  const { user, request, isSync, catalog, canUpdate, checkSync, isAdmin, isLocal, useImperials } =
    useOutletContext<{
      catalog: Catalog
      request?: Request
      user?: User
      canUpdate: boolean
      useImperials: boolean
      isSync: boolean
      isAdmin: boolean
      isLocal: boolean
      checkSync: (callback: () => Promise<any>) => Promise<void>
    }>()
  const [loading, setLoading] = useState(false)
  const show = useSnackbar()
  const { t } = useTranslation()
  const [modal, setModal] = useModal<
    'edit' | 'import' | 'export' | 'sendToValidation' | 'deleteLocal' | 'delete' | 'transfer'
  >()

  const showPublic = useMemo(() => {
    return sessionService.showPublic(catalog.type)
  }, [catalog.type])

  const catalogIcon = useMemo(() => (isAdmin ? sessionService.catalogIcon() : undefined), [isAdmin])
  const canUpdateVisibily = useMemo(
    () => catalog.status === CatalogStatus.accepted && showPublic,
    [showPublic, catalog.status],
  )

  const dateRequired = useMemo(
    () =>
      modal === 'sendToValidation' &&
      catalog.type === CatalogType.deconstruction &&
      (!catalog.retrieval.endDate || !catalog.retrieval.startDate),
    [modal, catalog],
  )
  const [retrievalDate, setRetrievalDate] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: catalog.retrieval?.startDate,
    endDate: catalog.retrieval?.endDate,
  })
  const errorStartDate = !retrievalDate.startDate
    ? t('errors:required')
    : retrievalDate.startDate &&
      retrievalDate.endDate &&
      new Date(retrievalDate.endDate).getTime() < new Date(retrievalDate.startDate).getTime()
    ? t('errors:startAfterEnd')
    : undefined
  const errorEndDate = !retrievalDate.endDate
    ? t('errors:required')
    : retrievalDate.startDate &&
      retrievalDate.endDate &&
      new Date(retrievalDate.endDate).getTime() < new Date(retrievalDate.startDate).getTime()
    ? t('errors:startAfterEnd')
    : undefined

  const [transferRequest, setTransferRequest] = useState<Request | undefined>(request)
  useEffect(() => {
    setTransferRequest(request)
  }, [request])
  const [transferModal, setTransferModal] = useState<RequestStatus | undefined>()

  // close modal when the id of the catalog was updated during export
  const checkExportedModal = useRef(() => {
    if (modal === 'export') {
      setModal('')
    }
  })
  useEffect(() => {
    checkExportedModal.current = () => {
      if (modal === 'export') {
        setModal('')
      }
    }
  }, [modal, setModal])
  useEffect(() => {
    checkExportedModal.current?.()
  }, [catalog._id])

  const updateVisibility = async (checked: boolean) => {
    setLoading(true)
    try {
      await checkSync(async () => {
        await catalogsService.updateStateCatalog(catalog._id, { visible: checked })
      })
      show(t('catalogs:actions.update.success'))
    } catch (error: any) {
      show(error)
    }
    setLoading(false)
  }
  const updateCatalogIcon = async (catalogIcon: CatalogIcon) => {
    setLoading(true)
    try {
      await checkSync(async () => {
        await catalogsService.updateCatalog(catalog._id, { catalogIcon })
      })
      show(t('catalogs:actions.update.success'))
    } catch (error: any) {
      show(error)
    }
    setLoading(false)
  }
  const update = async (updated: Partial<ManageCatalog>) => {
    setLoading(true)
    try {
      await catalogsService.updateCatalog(catalog._id, updated)
      show(t('catalogs:actions.update.success'))
    } catch (error: any) {
      show(error)
    }
    setLoading(false)
  }

  const updateStatus = async (status: CatalogStatus) => {
    setLoading(true)
    try {
      await catalogsService.updateStateCatalog(catalog._id, { status })
      if (status === CatalogStatus.draft) {
        show(t('catalogs:actions.reject.success'))
      } else if (status === CatalogStatus.accepted) {
        show(t('catalogs:actions.accept.success'))
      }
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  const buttons = [
    ...(isResources(catalog.type) && isLocal && catalog.networkStatus !== NetworkStatus.local
      ? [
          {
            label: t('catalogs:actions.import.label'),
            color: 'primary',
            onClick: setModal.bind(null, 'import'),
          },
        ]
      : []),
    //  && canUpdate : it should be not possible to have a catalog !sync (with modification) with status === 'pending'
    //    however to handle error case we permit to export it
    ...(isResources(catalog.type) && isLocal && !isSync
      ? [
          {
            label: t('catalogs:actions.export.label'),
            color: 'primary',
            onClick: setModal.bind(null, 'export'),
          },
        ]
      : []),

    ...(isResources(catalog.type) && isLocal && catalog.networkStatus !== NetworkStatus.local
      ? [
          {
            label: t('catalogs:actions.deleteLocal.label'),
            color: 'error',
            onClick: setModal.bind(null, 'deleteLocal'),
          },
        ]
      : []),
    ...(catalog.status !== CatalogStatus.pending
      ? [
          {
            label: t('catalogs:actions.delete.label'),
            color: 'error',
            onClick: setModal.bind(null, 'delete'),
          },
        ]
      : []),
    ...(catalog.status === CatalogStatus.draft && canUpdate
      ? [
          {
            label: t('catalogs:actions.sendToValidation.label'),
            color: 'success',
            tooltip: t('catalogs:actions.sendToValidation.tooltip'),
            onClick: setModal.bind(null, 'sendToValidation'),
          },
        ]
      : []),
    ...(isAdmin && catalog.status === CatalogStatus.pending
      ? [
          {
            label: t('catalogs:actions.reject.label'),
            color: 'error',
            onClick: updateStatus.bind(null, CatalogStatus.draft),
          },
          {
            label: t('catalogs:actions.accept.label'),
            color: 'success',
            onClick: updateStatus.bind(null, CatalogStatus.accepted),
          },
        ]
      : []),
  ]
  return (
    <>
      {loading && <LoaderOverlay />}
      <Grid container spacing={3} justifyContent="center" sx={{ padding: '15px 15px 0 15px' }}>
        {buttons.map((button, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Button
              fullWidth
              variant="contained"
              color={button.color as any}
              onClick={button.onClick}>
              {button.label}

              {button.tooltip && (
                <Tooltip title={button.tooltip}>
                  <InfoIcon sx={{ marginTop: '3px', marginLeft: '10px' }} />
                </Tooltip>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Double
        first={
          <>
            {(canUpdateVisibily || catalogIcon) && (
              <>
                <DetailsComponent
                  value={catalog}
                  items={[
                    ...(canUpdateVisibily
                      ? [
                          {
                            label: t('catalogs:pages.informations.visibleForUsers'),
                            type: ItemType.custom,
                            key: 'visibleForUsers',
                            custom: (
                              <Switch
                                onChange={(_, checked) => updateVisibility(checked)}
                                disabled={!canUpdate}
                                checked={catalog.visible}
                              />
                            ),
                          },
                        ]
                      : []),
                    ...(catalogIcon
                      ? [
                          {
                            label: t('catalogs:attributes.catalogIcon'),
                            type: ItemType.custom,
                            key: 'catalogIcon',
                            custom: (
                              <Switch
                                onChange={(_, checked) =>
                                  updateCatalogIcon(checked ? catalogIcon : CatalogIcon.none)
                                }
                                disabled={!canUpdate}
                                checked={catalog.catalogIcon === catalogIcon}
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
              </>
            )}
            <InformationCatalog
              title={t('catalogs:pages.informations.informations')}
              actions={canUpdate ? { onClick: () => setModal('edit') } : undefined}
              catalog={catalog}
              isAdmin={isAdmin}
              useImperials={useImperials}
              additionals={
                canUpdate
                  ? [
                      {
                        label: t('catalogs:attributes.transfer'),
                        type: ItemType.link,
                        key: 'transfer',
                        props: {
                          sx: { marginLeft: 'auto' },
                          children: transferRequest
                            ? t('catalogs:actions.transfer.onGoing')
                            : t('catalogs:actions.transfer.label'),
                          onClick: setModal.bind(null, 'transfer'),
                        },
                      },
                    ]
                  : []
              }
            />
          </>
        }
        second={
          <InformationDocumentCatalog catalog={catalog} canUpdate={canUpdate} onUpdate={update} />
        }
      />
      {modal === 'edit' && (
        <ModalFormCatalog
          hideDocument
          useImperials={useImperials}
          isAdmin={isAdmin}
          title={t(`catalogs:actions.update.label`)}
          submitLabel={t(`global:actions.save`)}
          catalog={catalog}
          onClose={() => setModal('')}
          onSubmit={catalogsService.updateCatalog.bind(null, catalog._id)}
          onSuccess={() => {
            show(t('catalogs:actions.update.success'))
          }}
        />
      )}
      {modal === 'transfer' && transferRequest && (
        <RequestDetailsModal
          request={transferRequest}
          sent
          hasSenderRights={hasSenderRights.bind(null, user)}
          hasReceiverRights={hasReceiverRights.bind(null, user)}
          onUpdate={(status: RequestStatus) => setTransferModal(status)}
          onClose={() => setModal('')}
        />
      )}
      {modal === 'transfer' && !transferRequest && (
        <ModalFormRequest
          title={t(`catalogs:actions.transfer.label`)}
          submitLabel={t(`global:actions.send`)}
          catalog={catalog._id}
          organization={catalog.organization._id}
          onClose={() => setModal('')}
          onSubmit={requestsService.createRequest}
          onSuccess={(request: Request) => {
            show(t('catalogs:actions.transfer.success'))
            setTransferRequest(request)
          }}
        />
      )}
      {(modal === 'import' ||
        modal === 'export' ||
        modal === 'sendToValidation' ||
        modal === 'deleteLocal' ||
        modal === 'delete') && (
        <Modal
          description={t(`catalogs:actions.${modal}.description`)}
          title={t(`catalogs:actions.${modal}.label`)}
          keepOpen
          onSuccess={(data) => {
            show(t(`catalogs:actions.${modal}.success`))

            if (modal === 'export' && data?._id !== catalog._id) {
              goTo({ route: Route.workspaceCatalog, catalogId: data?._id })
              setLoading(false)
            } else if (modal === 'delete' || modal === 'deleteLocal') {
              goTo({ route: Route.workspace })
            } else {
              setLoading(false)
              setModal('')
            }
          }}
          onClose={() => setModal('')}
          onConfirm={async () => {
            //  loading is handle by modal but use it here not to initDetail when the project is being deleted
            setLoading(true)
            try {
              switch (modal) {
                case 'delete':
                  return await catalogsService.deleteCatalog(catalog._id)
                case 'import':
                  return await catalogsService.importCatalog(catalog._id)
                case 'export':
                  return await catalogsService.exportCatalog(catalog._id)
                case 'sendToValidation':
                  return await checkSync(async () => {
                    if (dateRequired) {
                      await catalogsService.updateCatalog(catalog._id, {
                        retrieval: {
                          ...catalog.retrieval,
                          ...retrievalDate,
                        },
                      })
                    }
                    await catalogsService.updateStateCatalog(catalog._id, {
                      status: CatalogStatus.pending,
                    })
                  })
                case 'deleteLocal':
                  return await catalogsService.deleteLocalCatalog(catalog._id)
                default:
              }
            } catch (err: any) {
              show(err)
              setLoading(false)
              throw err
            }
          }}
          disabled={dateRequired && (!!errorStartDate || !!errorEndDate)}>
          <>
            {dateRequired && (
              <Box>
                <Typography fontSize="0.875rem" textAlign="center">
                  {t('catalogs:actions.sendToValidation.dateRequired')}
                </Typography>
                <Box display="flex" flexDirection="column">
                  <DateInput
                    value={retrievalDate.startDate}
                    onChange={(value: Date) =>
                      setRetrievalDate((retrievalDate) => ({
                        ...retrievalDate,
                        startDate: value,
                      }))
                    }
                    label={t('catalogs:attributes.deconstructionStartDate')}
                    error={errorStartDate}
                  />
                  <DateInput
                    value={retrievalDate.endDate}
                    onChange={(value: Date) =>
                      setRetrievalDate((retrievalDate) => ({
                        ...retrievalDate,
                        endDate: value,
                      }))
                    }
                    label={t('catalogs:attributes.deconstructionEndDate')}
                    error={errorEndDate}
                  />
                </Box>
              </Box>
            )}
          </>
        </Modal>
      )}
      {transferModal && transferRequest && (
        <Modal
          description={t(`requests:actions.${transferModal}.description.${transferRequest.type}`)}
          title={t(`requests:actions.${transferModal}.label`)}
          onSuccess={() => {
            show(t(`requests:actions.${transferModal}.success`))
            setTransferRequest(undefined)
            setModal('')
          }}
          onClose={() => setTransferModal(undefined)}
          onConfirm={async () => {
            await requestsService.updateRequest(transferRequest._id, { status: transferModal })
          }}
        />
      )}
    </>
  )
}
export default CatalogInformationsTab
