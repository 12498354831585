import { Box, BoxProps, styled } from '@mui/material'

const StyledBox = styled(Box)<{ spacing?: number | string }>((props: any) => ({
  '& > *:not(:last-child)': {
    ...(props.flexDirection === 'row'
      ? { marginRight: isNaN(props.spacing) ? props.spacing : props.spacing * 8 + 'px' }
      : props.flexDirection === 'row-reverse'
      ? { marginLeft: isNaN(props.spacing) ? props.spacing : props.spacing * 8 + 'px' }
      : props.flexDirection === 'column'
      ? { marginBottom: isNaN(props.spacing) ? props.spacing : props.spacing * 8 + 'px' }
      : props.flexDirection === 'column-reverse'
      ? { marginTop: isNaN(props.spacing) ? props.spacing : props.spacing * 8 + 'px' }
      : {}),
  },
}))

export interface StackProps extends BoxProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  spacing?: number | string
}

export const Stack = (props: StackProps): JSX.Element => {
  const { direction, spacing, ...rest } = props

  return (
    <StyledBox {...rest} display="flex" spacing={spacing} flexDirection={direction || 'column'} />
  )
}

export default Stack
