import { createStore, withProps } from '@ngneat/elf'
import { CatalogLight, Catalog } from '../../models/catalogs.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const catalogsStore = createStore(
  { name: 'catalogs' },
  withProps<{
    list: CatalogLight[]
    detail?: Catalog
    isPublic?: boolean
  }>({
    list: [],
    detail: undefined,
    isPublic: undefined,
  }),
  withRequestsStatus<'init' | 'initDetail'>(),
)
