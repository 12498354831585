import { styled, Typography, Box, Chip } from '@mui/material'
import { ChipProps } from '../../models/props.models'
const StyledChip = styled(Box)<{ color: string }>(({ color, theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  borderRadius: '4px',
  display: 'flex',
  height: 25,
  padding: '0 6px',
  width: 'fit-content',
  '& div:first-of-type': {
    backgroundColor: color,
    borderRadius: '50%',
    height: '8px',
    marginRight: '4px',
    width: '8px',
  },
  '& .MuiTypography-root': { color: color, fontSize: '0.75rem', fontWeight: 500 },
}))

const ChipComponent: React.FC<ChipProps> = ({ color, label, full, sx }) => {
  if (full) {
    return <Chip sx={{ backgroundColor: `${color} !important`, ...sx }} label={label} />
  }
  return (
    <StyledChip color={color} sx={sx}>
      <Box />
      <Typography>{label}</Typography>
    </StyledChip>
  )
}
export default ChipComponent
