import { useOutletContext } from 'react-router-dom'
import { Organization } from '../../../models/organizations.models'
import PageReport from '../../../components/page/Report.page'

const OrganizationReportTab = () => {
  const { organization } = useOutletContext<{
    organization: Organization
  }>()
  return <PageReport organization={organization._id} />
}
export default OrganizationReportTab
