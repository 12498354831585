import { AxiosResponse } from 'axios'
import { axiosApi } from './base.api'
import { Report, ReportQueryParams, ReportResult } from '../models/reports.model'

export namespace ReportsApi {
  export const getList = async (): Promise<Report[]> => {
    const response: AxiosResponse<Report[]> = await axiosApi.get('/reports')
    return response.data
  }
  export const create = async (report: Report): Promise<Report> => {
    const response: AxiosResponse<Report> = await axiosApi.post(`/reports`, report)
    return response.data
  }
  export const update = async (
    reportId: string,
    updatedReport: Partial<Report>,
  ): Promise<Report> => {
    const response: AxiosResponse<Report> = await axiosApi.patch(
      `/reports/${reportId}`,
      updatedReport,
    )
    return response.data
  }
  export const deleteById = async (reportId: string): Promise<void> => {
    await axiosApi.delete(`/reports/${reportId}`)
  }
  export const computeReport = async (reportQuery?: ReportQueryParams): Promise<ReportResult> => {
    const response: AxiosResponse<ReportResult> = await axiosApi.get(`/reports/compute`, {
      params: reportQuery,
    })
    return response.data
  }
  export const exportXlS = async (reportQuery?: ReportQueryParams): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/reports/xlsx`, {
      responseType: 'blob',
      params: reportQuery,
    })
    return response.data
  }
}
