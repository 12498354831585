import { useEffect, useState } from 'react'
import { useGesture as reactUseGesture } from '@use-gesture/react'
import useDoubleClick from './useDoubleClick.hooks'
import useKey from './useKey.hooks'
import { Point } from '../models/commons.models'
import Constants from '../constants'

type useGestureParams = {
  ref: React.MutableRefObject<HTMLElement | null>
  capture?: boolean
  doubleClickLatency?: number
  pinchBound?: { min: number; max: number }
  onSingleClick?: (position: Point, target: HTMLElement | null) => void
  onDoubleClick?: (position: Point, target: HTMLElement | null) => void
  onDrag?: (delta: Point, position: Point, target: HTMLElement | null, isTouch: boolean) => void
  onPinch?: (scale: number, position: Point) => void
  onDragEnd?: (position: Point) => void
  onKey?: (key: string, ctrl: boolean) => void
}

const useGesture = ({
  ref,
  doubleClickLatency = Constants.ui.doubleClickLatency,
  pinchBound = Constants.ui.pinchBound,
  capture = false,
  onSingleClick,
  onDoubleClick,
  onDrag,
  onDragEnd,
  onPinch,
  onKey,
}: useGestureParams): void => {
  const [mouvement, setMouvement] = useState('')

  //fix mouseUp touchUp outside... ==> always send pointerUp (so onDragEnd & onPinchEnd are called when needed)
  useEffect(() => {
    function handleEndOutsideTarget(event: Event) {
      if (ref.current && !(event as any).callFromWindow) {
        event.stopPropagation()
        event.preventDefault()
        const newEvent = new PointerEvent(event.type, event) as any
        newEvent.callFromWindow = true
        setTimeout(() => {
          ref.current?.dispatchEvent(newEvent)
        }, 100)
      }
    }

    window.addEventListener('pointerup', handleEndOutsideTarget)
    return () => {
      window.removeEventListener('pointerup', handleEndOutsideTarget)
    }
  }, [ref])

  // permit drag outside the ref
  useEffect(() => {
    const handleMoveOutsideTarget = (event: Event) => {
      if (
        ref.current &&
        mouvement &&
        event.target &&
        !ref.current.contains(event.target as Node) &&
        !(event as any).callFromWindow
      ) {
        event.stopPropagation()
        event.preventDefault()
        const newEvent = new PointerEvent(event.type, event) as any
        newEvent.callFromWindow = true
        ref.current?.dispatchEvent(newEvent)
      }
    }

    window.addEventListener('pointermove', handleMoveOutsideTarget)
    return () => {
      window.removeEventListener('pointermove', handleMoveOutsideTarget)
    }
  }, [ref, mouvement])

  reactUseGesture(
    {
      onDrag: ({ delta, xy, event }) => {
        if (onDrag && mouvement !== 'pinching') {
          setMouvement('dragging')
          onDrag(
            delta,
            xy,
            event.target as HTMLElement,
            (event as PointerEvent).pointerType !== 'mouse',
          )
        }

        event.stopPropagation()
        event.preventDefault()
      },
      onPinch: ({ offset, origin, event }) => {
        if (onPinch && mouvement !== 'dragging') {
          onPinch(offset[0], origin)
          setMouvement('pinching')
        }

        event.stopPropagation()
        event.preventDefault()
      },
      onDragEnd: ({ xy, event }) => {
        if (onDragEnd) {
          onDragEnd(xy)
        }
        if (onDrag) {
          setTimeout(() => {
            setMouvement('')
          }, 200)
        }

        event.stopPropagation()
        event.preventDefault()
      },
      onPinchEnd: ({ event }) => {
        if (onPinch) {
          setTimeout(() => {
            setMouvement('')
          }, 200)
        }

        event.stopPropagation()
        event.preventDefault()
      },
    },
    {
      target: ref,
      eventOptions: { passive: false },
      drag: {
        threshold: 10,
      },
      pinch: {
        scaleBounds: pinchBound,
      },
    },
  )

  useKey({ onKey })

  useDoubleClick({
    ref,
    doubleClickLatency,
    capture,
    onSingleClick: !mouvement ? onSingleClick : undefined,
    onDoubleClick: !mouvement ? onDoubleClick : undefined,
  })
}

export default useGesture
