import { useEffect } from 'react'

type useKeyParams = {
  onKey?: (key: string, ctrl: boolean) => void
}

const useKey = ({ onKey }: useKeyParams): void => {
  useEffect(() => {
    function handleKey(event: KeyboardEvent) {
      if ((event.target as any)?.tagName.toUpperCase() === 'INPUT') {
        return
      }
      if (onKey) onKey(event.key, event.ctrlKey)
    }

    window.addEventListener('keyup', handleKey)
    return () => window.removeEventListener('keyup', handleKey)
  }, [onKey])
}

export default useKey
