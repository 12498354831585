import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { Organization } from '../../../models/organizations.models'
import { Route } from '../../../models/commons.models'
import { ItemType } from '../../../models/props.models'

import useSnackbar from '../../../hooks/useSnackbar.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import useModal from '../../../hooks/useModal.hooks'

import { Box } from '@mui/material'
import InformationOrganization from '../../../components/organization/Informations.organizations'
import Modal from '../../../components/layout/Modal.layout'
import ModalFormOrganization from '../../../components/organization/ModalForm.organization'
import { organizationsService } from '../../../store/organizations'

const OrganizationInformationsTab = () => {
  const { organization, setOrganization } = useOutletContext<{
    organization: Organization
    setOrganization: React.Dispatch<React.SetStateAction<Organization>>
  }>()
  const { t } = useTranslation()
  const [modal, setModal] = useModal<'edit' | 'delete'>()
  const show = useSnackbar()
  const { goTo } = useRoute()

  return (
    <>
      <Box maxWidth="800px" padding="15px 30px">
        <InformationOrganization
          title={t('global:routes.organizationInformations')}
          organization={organization}
          actions={{ onClick: () => setModal('edit') }}
          additionals={[
            {
              label: t('organizations:attributes.createdAt'),
              key: 'createdAt',
              formatValue: (createdAt: string) =>
                createdAt
                  ? t('global:format.date', {
                      date: new Date(createdAt),
                    })
                  : '-',
            },
            {
              type: ItemType.button,
              key: 'delete',
              props: {
                children: t('global:actions.delete'),
                onClick: () => setModal('delete'),
              },
            },
          ]}
        />
      </Box>
      {modal === 'delete' && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={organizationsService.deleteOrganization.bind(null, organization._id)}
          keepOpen
          onSuccess={() => {
            show(t('organizations:actions.delete.success'))
            goTo({ route: Route.organizations })
          }}
          title={t('organizations:actions.delete.label')}
          description={t('organizations:actions.delete.description')}
        />
      )}
      {modal === 'edit' && (
        <ModalFormOrganization
          organization={organization}
          title={t('organizations:actions.update.label')}
          onSubmit={organizationsService.updateOrganization.bind(null, organization._id)}
          onClose={() => setModal('')}
          onSuccess={(updatedOrganization) => {
            setOrganization((organization: Organization) => ({
              ...organization,
              ...updatedOrganization,
            }))
            show(t('organizations:actions.update.success'))
          }}
        />
      )}
    </>
  )
}
export default OrganizationInformationsTab
