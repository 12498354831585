import { categoriesStore } from './categories.store'
import { selectIsRequestPending } from '@ngneat/elf-requests'
import { select } from '@ngneat/elf'

export class CategoriesQuery {
  store = categoriesStore
  list = this.store.pipe(select((state) => state.list))
  loading = this.store.pipe(selectIsRequestPending('init'))

  primaryCategories = this.store.pipe(select((state) => state.list.flatMap((c) => c.category)))
  secondaryCategories = this.store.pipe(
    select((state) => state.list.flatMap((c) => c.subCategories?.map((c2) => c2.category) ?? [])),
  )

  tertiaryCategories = this.store.pipe(
    select((state) =>
      state.list
        .flatMap((c) => c.subCategories?.flatMap((c2) => c2.subCategories ?? []) ?? [])
        .map((c3) => c3.category),
    ),
  )
}

export const categoriesQuery = new CategoriesQuery()
