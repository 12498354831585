import { useCallback, memo } from 'react'
import { ItemType, FilterItem, FormItem, BaseFormItem } from '../../../models/props.models'
import { Grid, Box } from '@mui/material'
import TypedItem from './Typed.item'
import { ObjectUtils } from '../../../utils/commons.utils'

const Item = ({
  id,
  value,
  error,
  item,
  updateValue,
  children,
}: {
  id?: string
  value: any
  error?: any
  item: BaseFormItem | FilterItem
  updateValue: (key: string) => (inputValue: any) => any
  children?: JSX.Element | JSX.Element[]
}) => {
  if (item.hideItem) {
    return <></>
  }

  return (
    <Grid
      id={id}
      item
      {...(item.grid || { xs: 12 })}
      display="flex"
      alignItems={
        item.alignItems ?? item.type === ItemType.group
          ? 'flex-start'
          : item.type === ItemType.typo
          ? 'center'
          : 'flex-end'
      }>
      <Box width="100%">
        <TypedItem
          {...item}
          error={error}
          value={value}
          onChange={item?.onChange ? item?.onChange : updateValue(item.key)}>
          {children}
        </TypedItem>
      </Box>
    </Grid>
  )
}
const MemoItem = memo(Item)

type ArrayItemProps = {
  value: Record<string, any>
  errors?: Record<string, any>
  items: FormItem[] | FilterItem[]
  setValue: React.Dispatch<React.SetStateAction<any>>
}

const ArrayItem: React.FC<ArrayItemProps> = ({ value, items, errors, setValue }) => {
  const updateValue = useCallback(
    (key: string) => (inputValue: any) => {
      setValue((value: any) => {
        return ObjectUtils.setKeyValue(value, key, inputValue)
      })
    },
    [setValue],
  )
  return (
    <>
      {items.map((item) =>
        typeof item === 'function' || item?.type === ItemType.group ? (
          (() => {
            const computedItem = (typeof item === 'function' ? item(value) : item) as BaseFormItem
            return (
              <Item
                id={computedItem.summary}
                key={computedItem.key}
                item={computedItem}
                error={errors ? errors[computedItem.key] : undefined}
                updateValue={updateValue}
                value={ObjectUtils.getKeyValue(value, computedItem.key)}>
                {(computedItem.type as unknown as ItemType) === ItemType.group ? (
                  <ArrayItem
                    value={value}
                    items={(computedItem?.items as FormItem[] | FilterItem[]) ?? []}
                    errors={errors ? errors[computedItem.key] : undefined}
                    setValue={setValue}
                  />
                ) : (
                  <></>
                )}
              </Item>
            )
          })()
        ) : (
          <MemoItem
            id={(item as BaseFormItem).summary}
            key={item.key}
            item={item}
            error={errors ? errors[item.key] : undefined}
            updateValue={updateValue}
            value={ObjectUtils.getKeyValue(value, item.key)}
          />
        ),
      )}
    </>
  )
}
export default memo(ArrayItem)
