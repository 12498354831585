import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import { PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'
import {
  Profession,
  UserStatus,
  UserType,
  ApiUser,
  UsersPagination,
  User,
} from '../models/users.models'
export namespace UsersApi {
  export interface GetListParams extends PaginationParams {
    platform?: PlatformReference
    search?: string
    organization?: string
    status?: UserStatus[]
    types?: UserType[]
    professions?: Profession[]
  }
  export const getList = async (params?: GetListParams): Promise<UsersPagination> => {
    const response: AxiosResponse<UsersPagination> = await axiosApi.get(`/users`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        status: params?.status?.length ? params.status : undefined,
        types: params?.types?.length ? params.types : undefined,
        professions: params?.professions?.length ? params.professions : undefined,
      },
    })
    return response.data
  }
  export const getCsv = async (params?: GetListParams): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/users/csv`, {
      responseType: 'blob',
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        status: params?.status?.length ? params.status : undefined,
        types: params?.types?.length ? params.types : undefined,
        professions: params?.professions?.length ? params.professions : undefined,
      },
    })
    return response.data
  }
  export const getById = async (userId: string): Promise<User> => {
    const response: AxiosResponse<User> = await axiosApi.get(`/users/${userId}`)
    return response.data
  }
  export const update = async (userId: string, updatedUser: Partial<ApiUser>): Promise<User> => {
    const response: AxiosResponse<User> = await axiosApi.patch(`/users/${userId}`, updatedUser)
    return response.data
  }
  export const deleteById = async (userId: string): Promise<void> => {
    await axiosApi.delete(`/users/${userId}`)
  }
}
