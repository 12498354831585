import { UsersApi } from '../../api/users.api'
import { FileUtils } from '../../utils/files.utils'
import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import { UsersPagination, User } from '../../models/users.models'
import { Mode } from '../../models/commons.models'
import { SelectOption } from '../../models/props.models'
import { sessionService } from '../session'
import Constants from '../../constants'

export class UsersService {
  getUsers = async (filters: UsersApi.GetListParams): Promise<UsersPagination> => {
    await NetworkUtils.checkConnected()

    return await UsersApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  getCsv = async (filters: UsersApi.GetListParams): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await UsersApi.getCsv({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
    await FileUtils.downloadFile(`utilisateur.csv`, data)
  }

  getUserOptions = async (search?: string, organization?: string): Promise<SelectOption[]> => {
    try {
      const users = await this.getUsers({
        organization,
        search,
      })

      return users.data.map((e) => ({
        label: `${e.lastname} ${e.firstname}`,
        value: e._id,
        data: e,
      }))
    } catch (err: any) {
      logIfDev(err)
      return []
    }
  }

  getUserById = async (userId: string): Promise<User> => {
    await NetworkUtils.checkConnected()
    return await UsersApi.getById(userId)
  }
  updateUser = async (
    userId: string,
    updatedUser: Partial<User> & { deniedFor?: string },
  ): Promise<User> => {
    await NetworkUtils.checkConnected()
    return await UsersApi.update(userId, {
      ...updatedUser,
      organization: updatedUser.organization?._id,
    })
  }
  deleteUser = async (userId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    return await UsersApi.deleteById(userId)
  }
}

export const usersService = new UsersService()
