import { useOutletContext } from 'react-router-dom'
import PageOrderList from '../../components/page/OrderList.page'

const WorkspaceOrderTab = () => {
  const { useImperials } = useOutletContext<{
    useImperials: boolean
  }>()

  return <PageOrderList withTabs withGroups isCatalogOwner useImperials={useImperials} />
}
export default WorkspaceOrderTab
