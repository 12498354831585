import { useOutletContext } from 'react-router-dom'
import { Organization } from '../../../models/organizations.models'
import PageCatalogList from '../../../components/page/CatalogList.page'
import { CatalogType } from '../../../models/catalogs.models'

const OrganizationCatalogsTab = () => {
  const { organization } = useOutletContext<{
    organization: Organization
  }>()
  return (
    <PageCatalogList
      withTabs
      organization={organization._id}
      types={[
        CatalogType.construction,
        CatalogType.deconstruction,
        CatalogType.needs,
        CatalogType.storage,
      ]}
    />
  )
}
export default OrganizationCatalogsTab
